/* ===================================================== */
/* Pre Login */

.prelogin-bg {
    background: linear-gradient(#131314, #42454A, #131314);
}

.prelogin-header-row {
    height: 50px;
}

.prelogin-content-row {
    height: calc(100% - 110px);
}

.prelogin-footer-row {
    height: 60px;
}

.prelogin-header2{
    display: flex;
    flex-direction: row;
    align-items: flex-end;  
    justify-content: space-between;
    height: 100%;
}

.pl-home-logo {       
    height: 90%;
}

.prelogin-content2{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.pl-content-parent2 {
    width: 90%;
    height: 90%;
    box-shadow: 0 0 15px #333;
    padding: 1px;
}

.pl-content-holder2 {
    
    background: white; 
   width: 100%;
   height: calc(100% - 0px);
   display: flex;
    flex-direction: row;
}

.pl-main-content2 {
   
}

.pl-weblinks2 {
    width: 100%;
    height: 70px;
    background: black;
    display: flex;
    flex-direction: row;
}

.pl-wl-left {
    width: 40%;
    height: 100%;
    padding: 5px;
}
    
.pl-wl-right {
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: row;
}

.moblinks-holder {
    display: flex;
    flex-direction: row;
}

.pl-num-input {
    margin-left: 10px;
    width: 300PX;
    height: 28PX;
    background: rgba(128, 128, 128, 0.459);
    color: #fff;
    border: 0px;
    padding: 5px; 
}

.pl-vdivider2 {
    width: 1px;
    background-color: rgba(128, 128, 128, 0.459);
    height: 70%;
    margin-top: 10px;
}

    

.prelogin-footer2{
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: #ccc;
    width:100%;
}

.pl-poster2 {
    width: 45%;
    height: 100%;
}

.login_mainholder2 {
    width: 55%;
    height: 100%;
    overflow-y: auto;
}
.login_mainholder2 .form-control:focus {
    color: #000;
}

.prelogin-header{
    display: flex;
    flex-direction: row;
    align-items:center;  
    justify-content: space-between;
    height: 80px;
}

.prelogin-header-left{
    display: flex;
    flex-direction: row;
}

.prelogin-logo-holder{
   width:120px; 
   cursor: pointer;
}

.prelogin-vdivider {
    width: 1px;
    background-color: rgba(128, 128, 128, 0.459);
    height: 70%;
}

.pl-header-icon{
   font-size: 12px;
   color: #1EBF8A;
    margin-right: 3px;
}
.pl-header-icon2{
    font-size: 14px;
    color:#fff;
     margin: 10px;
 }
.pl-small-text{
   font-size:12px;
   color:#ccc;
}
.pl-hyper-small-text{
    font-size: 0.9em;
    color:#ccc;
 }
.pl-large-text{
   font-size:14px;
   color:#1EBF8A;
}

.pl-header-item {
    padding-left: 20px;
    padding-right: 20px;
}

.pl-header-item.btnholder {
    
    float: right;
}


.pl-header-button{
   float: right;
   height: 31px;
   margin: auto;
   background:#1EBF8A;
   font-size: 14px;
   border-color: #1EBF8A;
   padding-left: 10px;
   padding-right: 10px;
   border: none;
}

.pl-content{
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100% - 160px);
}

.pl-content-child{
    height: auto;
    width: 1000px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);    
}



.pl-data-content{
    height: 420px;
    background: #fff;
    margin: auto;
}

.pl-data-content-small {
    height: 360px;
    background: #fff;
    margin: auto;
}


.pl-poster{    
    height: 100%
}

.web-links{
   display: flex;
   flex-direction: row;
   height: 80px;
   margin: 2px auto;
   border: 1px #000;
   background: #000;
}

.num-input{
    margin: 30px 5px 10px 10px;
    width: 300PX;
    height: 28PX;
    background: rgba(128, 128, 128, 0.459);
    color: #fff;
    border: 0px;
    padding: 10px; 
}

.prelogin-footer {    
    display: flex;
    flex-direction: row;
    height: auto;
    font-size: 0.9em;
}

.pl-footer-item.left {    
    height: auto;
    align-self: flex-end;
}

.pl-footer-item.right {  
    border-left: 1px solid #aaa;
    align-self: flex-end;
}

.pl-footer-item-content {
    width: calc(100%- 2px);
}
.pl-back-btn{
    float: left;
    width: 65px;
    background: transparent;
    border: 0;
    color: #bbb4b4;
    display: flex;
    align-items: baseline;
    padding-left: 0;
}
.pl-main-container{
    display: flex;
    flex-direction: column;
}

.pwd-guidelines > li {
    margin-left: 30px;
}

.pwd-guidelines > h5 {
    text-align: center;
}

.modal-dialog.plterms {
    width : 700px;
    height: 70% !important;
    top:2%;
    overflow-y: hidden !important;
}

.modal-body.plterms {
    height: calc(100% - 60px);
    margin-top: 5px;
}

.enter-otp {
    text-align: left;
    margin-bottom: 5px;
    font-weight: bold;
    color: #3c3838;
}

/* ===================================================== */
/* Create Account */
.create-account-radio .ideas-radio-label {
    font-size: 12px;
    text-align: left;
    padding-left: 50px;
}

.create-header {
    color: #948e8e;
    font-size: 14px;
    text-align: left;
    margin-bottom: 20px;
}

.aadhar-tnc {
    border: 1px solid #dacdcd;
    height: 70px;
    margin-top: 45px;
    overflow-y: auto;
    color: #868080;
    text-align: left;
    margin-bottom: 10px;    
}

.aadhar-check {
    float: right;
    font-size: 10px;
}

.aadhar-check > a {
    color: #868080 !important;
}

.custom-check input.create-account-checkbox:checked::before,
.custom-check input.create-account-checkbox:not(:checked):before {
    background: #fff !important;
}

.custom-check input.create-account-checkbox:checked::after,
.custom-check input.create-account-checkbox:not(:checked):after {
    border: 1px solid rgb(146, 147, 148);
}

.aadhar-checkbox {
    color: #868080;
    font-weight: normal !important;
}

.create-account-link > a {
    color: #1EBF8A !important;
}

.create-account-dropdown {
    margin-left: 0;
    padding-left: 0;
}

.create-account-dropdown .dropdown{
    padding: 7px !important;
    background: #fff;
}

.create-account-dropdown #drop-down {
    color: #555 !important;
    font-size: 14px !important;    
}

/* ===================================================== */

/*========= OnBoarding ===================*/
.board-head{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.board-content{
    background: #fff;
    height: calc(100% - 100px);
    width: calc(100% - 40px);
    margin: 20px;
}
.board-page{
    margin: 20px;
    height: calc(100% - 120px);
    justify-content: center;
    display: flex;
}
.board-collapse{
    width: 70%;
    padding: 10px;
    color: #000;
    display: flex;
    flex-direction: column;
}
.board-clp-panel{
    background: #efeaeb;
    height: 40px;
    padding: 10px;
    font-size: 14px;
}
.board-clp-content{
    padding: 15px;
    height: auto;
}
.board-txtip{
    border: 0px;
    border-bottom: 1px solid #ccc;
    background: transparent;
    height: 30px;
}
.board-dropdown{
    height: 30px;
    border: 0px;
    border-bottom: 1px solid #ccc;
    background: transparent;
}
.board-date{
    margin-top: 10px;
    width: 100%;
    border-bottom: 1px solid #ccc;
}
.connecting-line{
    height: 2px;
    background: #1EBF8A;
    position: relative;
    width: 100px;
    margin: 0 auto;
    left: 0;
    right: 0;
    z-index: 1;
}
.board-radio{
    margin-top: 10px;
    margin-left: -20px;
}
.board-btn{
    background: transparent;
    border: 0;
    width: 100px;
    height: 30px;
    font-size: 16px;
}
.board-btn:hover{
    color: #1EBF8A;  
    border: 1px solid #1EBF8A !important;
}
.board-btn:active{
    background: #1EBF8A;
    color: #fff !important;      
}
.board-arrow{
    margin: 0 7px;
    font-size: 14px;
}
.board-check input[type="checkbox"] + div:before{
    border: 1px solid #000;
    background: #fff;
    width: 20px;
    opacity: 0;
}
.board-check input[type="checkbox"]:checked{
   color: #1EBF8A;
   font-weight: 10;
}
.cir-tab-head{
    display: flex;
    flex-direction: column;
    justify-content: space-around; 
    width: 120px;
    height: auto;
}
.cir-tab-btn{
    width: 20px;
    height: 20px;
    border-radius: 20px;
    border: 2px solid #c5bebe;
    margin: 4px 0px;
}
.cir-tab-btn-active{  
    padding: 12px;    
    width: 20px;
    height: 20px;
    border-radius: 20px;
    border: 2px solid #1EBF8A;
    margin: 0px;
}
.cir-tab-btn-done{
    margin: 10px;
    width: 30px;
    height: 30px;
    border-radius: 20px;
    border: transparent;
    background: #1EBF8A;
}
/*----------------cisTerminal---------------*/
.cisTerminal{
    font-size: 30px;
}
.cisTerminal .bottom-diveder{
    border-bottom: 1px solid #3a3a3a;
    height: 1px;
    margin-bottom: 10px;
    padding: 0px;
}
.cisTerminal .table-background{
 background: #1EBF8A;
 color: #fff;
 margin-bottom: 2px;
 padding: 2px;
}
.cisTerminal .data-total{
    bottom: 8px;
    width: calc(100% - 22px);
}
/*------------------background disable--------------*/
.background-disable::before{
    content: '';
    background: #262a3005;
    width: 366%;
    height: 241%;
    position: absolute;
    z-index: -1;
    top: -130px;
    left: -162px;
}