/* ===================================================== */
/* Reports */
.reports-head{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.reports-header {
    color:#aaa;
    font-size: 11px;
    /* font-weight: lighter; */
    padding: 7px;
    margin-bottom: 5px;  
    
}
.reports-header-wom {
    color:#aaa;
    font-size: 11px;
    /* font-weight: lighter; */
}
.rp-colp-div{
        margin: 10px 0px;
        font-size: 12px;
}
.rp-colp-net{
        margin-top: 5px;
        display: flex;
        align-items: center;
        font-size: 25px;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 10px;
        padding-left: 10px;
        text-align: center;
}
.reports-tab-content{
    height: calc(100% - 50px);
    overflow-y: hidden;
    overflow-x: hidden;
}

.roports-verticalsep{ 
    border-right: 1x solid rgb(165, 84, 84);
}

.reports-row {
    color:#fff;
    font-size: 12px;
    padding-top: 7px;
    padding-bottom: 7px;
    padding: 7px;
}
.reports-equity-row{
    color:#fff;
    font-size: 14px;
    margin-left: 10px;
}
.reports-equity-header {
    color:#aaa; 
    font-size: 11px;
    /* font-weight: lighter;   */
    margin-left: 10px;
    }

.reports-carry-pad{
    border-bottom: 1px solid #3a3a3a;
    padding: 10px 0px !important;
}

.reports-intra-pad{
    padding: 10px 0px !important;
}

.reports-equity-sep{
    border-bottom: 1px solid #3a3a3a
}
.reports-column-center{
    padding: 5px!important;
    text-align: center;
}
.reports-column{
    padding: 5px!important;
}
.reports-po-expanded-row {
    color:#fff;
    font-size: 14px;
    padding-top: 2px;
    padding-bottom: 2px;
}

.reports-po-charges {
    color:#fff;
    font-size: 14px;
    margin-left: 15px;
}

.report-po-conditions {
    color:#aaa;
    font-size: 10px;
    margin-top: 5px;
}
 .reports-hdivider{
    border-bottom: 1px solid #3a3a3a;
    height: 1px;
    margin-right: -7px;
    margin-left: -7px;
    margin-bottom: 0px;    
    padding:0px;

 }
.cu-reports-row {
    color:#fff;
    font-size: 12px;
}

.cu-reports-row-grey {
    color:#fff;
    font-size: 12px;
    padding-top: 10px;
    background: rgb(48, 50, 58)
}
.reports-hot-news-accordion{
    background-color: #42454A;
    border-radius: 0 !important;
    padding-left: 0px !important;
    margin-bottom: 10px;
}
/* ===================================================== */
/* Demat Holdings */
.demat-holdings-content {
    background-color: rgb(33, 38, 44);
    padding: 0px !important;
    height:100%;
}

.demat-holdings-rows {
    height: calc(100% - 40px);
    overflow-y: auto;
}

/* ===================================================== */

/*Client Ledger */
.cld-box-content{
    height: auto;
    margin: 10px;
    border: 1px solid #565252;
    display:flex;
    justify-content: space-between;
    padding: 10px;
}
.cld-btn{
        background: transparent;
        color: #1EBF8A;       
        border: 1px solid #1EBF8A;
        margin-left: 40px;
        margin-top: 15px;
}
.cld-dw-btn{
    background: rgb(126, 121, 121);
    color: #fff;   
    border: 1px solid #1EBF8A;
    margin-left: 40px;
    margin-top: 15px;
}
.cld-txt-content{
    background: rgb(76, 75, 75);
    height: 40px;
    font-size: 1.5rem !important;
    padding: 10px;
}
.cld-header{
    color: #eee;
    font-size: 10px;
    /* font-weight: lighter; */
    margin: 10px 0px;
    color:rgba(255, 255, 255, 0.336);
}

.cld-margin-tab-content {
    background-color: rgb(33, 38, 44);
    padding: 0px !important;
    height:calc(100% - 40px);
    overflow-y: hidden;
}

.cld-table{
    overflow-y: scroll;
    height: 325px;
}
/*=========Contract Note========*/
.cntn-content{
    overflow-y: auto;
    height: calc(100% - 100px);
    overflow-x: hidden;
}

.cntn-control-header{
    border: 1px solid #565252;
}
.cntn-column-sep{
    border-left: 1px solid #565252;
}
.cntn-btn{
    background: transparent;
    color: #1EBF8A;
    width: 175;
    min-height: 28px;
    border: 1px solid #1EBF8A;
    margin-top: 15px;
}
.cntn-btn.download{
    background: rgb(126, 121, 121);
    color: #fff;
}

.cntn-download-btn{
    background: rgb(126, 121, 121);
    color: #fff;
    width: 150px;
    height: 25px;
    border: 1px solid #1EBF8A;
    margin-left: 40px;  
}

.cntn-download-btn .fa-eye{
    font-size: 17px;
}
.cntn-download-btn  .fa-envelope{
    font-size: 17px;
}
.cntn-row{
    padding: 10px 10px 10px 10px;
}
.cntn-label{
    padding: 10px 10px 10px 10px;
}

.cntn-exc{
    padding: 15px 0px 0px 20px;
}

.cntn-exc .btn{
    background: #262930 !important;
    border: 1px solid #EEEEEE;
    padding: 4px 5px !important;
}

.cntn-box-content{
    min-height: 70px;
    margin: 10px;
    border: 1px solid #565252;
    display:flex;
    justify-content: space-between;
    padding: 10px;
}
/*----ScripDateWise Transaction----*/
.sd-header{
    color: #eee;
    font-size: 14px;
    /* font-weight: lighter; */
    margin: 10px 0px;
}
.sd-rws{
    color: #eee;
    font-size: 14px;
    /* font-weight: lighter; */
    margin: 10px 0px;
    text-align: center;    
}
.sd-rw-ele{
    box-shadow:  0px 2px 2px 2px #050A11;
    color: #eee;
    font-size: 12px;
    /* font-weight: lighter; */
    margin: 10px 0px;
    text-align: center;
}

/*------Client Master.......*/
.cm-header{    
    border: 1px solid #565252;
}
.cm-btn{
    background: transparent;
    color: #1EBF8A;   
    border: 1px solid #1EBF8A;
    margin-left: 40px;
    margin-top: 15px;
}
/*---STT---*/
.stt-header{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height:calc(100% - 70px);
}
/*-----Prepaid Brokerage----*/
.reports-pb-column{
    padding: 5px!important;
    font-size: 14px;
    color: #fff;
}
.reports-pb-column-center{
    text-align: center;
}
.reports-pb-hdivider{
    border-bottom: 1px solid #3a3a3a;
    height: 1px;
    margin-right: -7px;
    margin-left: -7px;
    margin-bottom: 0px;    
    padding:0px;
    margin-top: 10px;
 }

 /* ------Capital Gain --------*/

 .reports-capital-content{
    height: calc(100% - 10px);
    position: relative;
    overflow-y: hidden;
    overflow-x: hidden;
}

 .capital-header{
     display: flex;
     align-items: center;
     margin-left: 20px;
     margin-bottom: 10px;
 }

 .capital-header .drop-parent{
     margin-left: 20px;
 }

 .capital-header .dropdown{
    background: #42454A; 
    padding: 2px 10px !important;
    min-width: 200px;
}

.capital-header .cpt-gn-btn{
    padding: 5px 5px;
    height: auto;
    margin-left: 20px;
    background: #262930;
}

.captital-footer{
    position: absolute;
    bottom: 0;
    font-size: 11px;
    color: #AAAAAA;
    margin: 10px 20px;
}

.backoffice-message{
    font-family: 'Roboto';
    font-size: 18px;
    text-align: center;
}

.reports-note {
    color: #fff;
    font-family: Roboto!important;
    font-size: 11px;
    font-weight: 400;
    padding: 10px;
}