/*==================================*/
/* Edit watchlist dialog*/
.edit-dialog-check{
    font-weight: normal;
    padding-bottom: 10px !important;
}

.edit-dialog-check .custom-check-label{
    font-size: 13px !important;
    padding-left: 8px !important;
}

.edit-dialog-check input:before{
    background: #42454A !important;
}

.edit-watch-btn-parent{
    display: flex;
    justify-content: center;
    padding-bottom: 15px;
}

.edit-watch-btn-parent .btn-parent .btn{
    padding: 2px 0px;
}

.edit-check-parent {
    border: 1px solid #AAAAAA;
    padding: 10px 10px 0px!important;
}

.edit-custom-padding{
    padding-left: 30px !important;
}

.rename-closebtn{
    cursor: pointer;
    height: 10px;
    margin-right: 10px;  
}

.edit-right-icon {
    position: absolute;
    right: 0px;
    top: 32%;
    margin: 0px 5px 0px 10px;
    pointer-events: auto;
    color: #1ebf8a;
    z-index: 80;
    cursor: pointer;
    font-style: normal;
  }

/*==================================*/
/* filter icon drop down */
#watchlist-filter-drdown.btn,
#watchlist-download-drdown.btn{
    padding: 0px !important;
    color: #AAAAAA;
}

.watchlist_small_widget .widget-title{
    padding-top: 15px !important;
    padding-bottom: 5px !important;
}

/*==================================*/
/* sort */
.fa-sort {
    cursor: pointer;
    margin-right: 5px;
}

/*==================================*/
/* delete watch symbol check box */
.watch-delete-check {
    position: absolute;
    margin-top: 2px;
}

.watch-delete-check>input{
    padding-top: 5px !important;
}
.watch-delete-check input:before {
    background: #131314 !important;
    width: 12px !important;
    height: 12px !important;
    padding-top: 5px !important;
}

.watch-delete-check input:after {
    width: 12px !important;
    height: 12px !important;
    font-size: 10px !important;
    padding: 2px 1px !important;
}


.watchlist-row {
    color:#fff;
    font-size: 14px;
}

/* New Watchlist view */

.watchlist_small_widget2 {
    height: calc(100%);   
    overflow-x: hidden; 
    overflow-y: hidden;
}

.watchlist-cust-header {
    overflow-x: hidden;
    margin: 0px;
    padding: 0px;
    margin-bottom: -24px !important;
    margin-top: -2px;
    padding-bottom: 18px;
    /* margin-right: 10px; */
    text-transform: uppercase;
}

.watchlist-left-container {
    height: calc(100% - 5px);
    overflow-y: auto;
    /* tohide scrollbar */
    margin: 0px;
    padding: 0px;
    /* padding-bottom: 5px !important; */
    /* for ie it should be 18 */
    /* margin-right: -18px !important;  */
    padding-right: -18px !important;
}
.watchlist-left-container::-webkit-scrollbar {
    display: none;
}

.dynamic-columns2 {
    background-color: #131314;
    overflow: auto;
    border-left: 3px solid #050a11;
    height: 100%; 
    margin: 0px;
    padding: 0px;
}

@-moz-document url-prefix() {
    .dynamic-columns2 {
        height: 100%;
    }
}

.watchlist-row2 {    
    position: relative;
    min-width: 100%;
    height: 30px !important;
    border: 4px solid #131314;
    margin-left: -3px;
}   

/* .watchlist-row2.odd {
    background-color: #3a3a3a8a; 
}

.watchlist-row2.even {
    background-color: #131314; 
} */



.watchlist-row2:nth-child(odd) {
    background-color: rgba(58,58,58, 138);
}  

.watchlist-row2:nth-child(even) {
    background-color: #131314; 
}


/* ============================ */

.wl-txt {
    padding-top: 2px !important;
}

.wl-ltp {
    margin-top: 0px;
    height: 22px;
    padding-top: 2px !important;
    border-left: 1px solid #131314;
    border-right: 1px solid #131314;
}

.wl-btn {
    width:100%;    
    color: #111111;
    font-size: 12px !important;
    background-color: #aaaaaa;
    border: 0px;
    padding:2px 0px;    
    margin-right:10px; 
}

.wl-btn:disabled{
    opacity: 0.3;
}
.wl-btn.chart {
    padding: 0px;
    background-color: transparent;
}

.watch_chartimg {
    padding: 0px;
    width: 100%;
    cursor: pointer;
    height: 21px;
}

.generic_chartimg {
    padding: 0px;
    cursor: pointer;
    width: 100%;
    height: 27px;
}

.watch_chartimg2 {
    padding: 0px;
    width: 100%;
    cursor: pointer;
    height: 27px;
}

.watch_chartimg3 {
    width:100%;    
    color: #111111;
    font-size: 12px !important;
    margin-right:10px; 
    background-color: transparent;
    height: 27px;
    border: 1px solid rgb(91, 94, 99);
    padding-top: 1px;
    padding-bottom: 1px;
}


.wl-btn.buy {
    background-color: rgb(22, 109, 190);;
    color: #eeeeee;
}

.wl-btn.sell {
    background-color: #E5283F;
    color: #eeeeee;
}

.watchlist-header {
    color:#aaa;
    font-size: 11px;
    /* font-weight: lighter; */
}

.watch-filter-parent{
    overflow: hidden;
}

.watch-filter-parent.show{
    overflow: visible;
}

.watchlist-filter-holder {

}

.watchlist-filter-holder2 {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
}

.watch-filter-holder.hidden {
    display: none;
}

.watch-filter-col {
    padding-left: 5px !important;
    padding-right: 5px !important;
}

.watch-input-filter{
    box-sizing: border-box !important;
    color: #EEEEEE;
    padding-right: 2px !important; 
    padding-left: 2px !important;   
    background: transparent;
    border-radius: 0;
    outline: none;
    border: none;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    border-bottom: 1px solid rgb(197, 194, 194) !important;
    outline-color: transparent;
    outline-style: none;
    font-size: 11px;
    text-align: center;
    width: 100%;
}

.watch-input-filter:focus{
    outline: 0 none;
    border-color: #EEEEEE !important;
  }
  
  .watch-input-filter::placeholder { 
    color: rgba(197, 194, 194, 0.5); 
    text-align: center;
  } 
  
  .watchlist-row-exc{
      padding: 0px 10px 0px 0px;
  }

  .watchlist-research{
      color: #1EBF8A;
      padding-right: 5px;
      visibility: visible;
      opacity: 1;
      width: 30px;
      margin-top: -4px;
      margin-right: -8px;
      margin-left: -10px;
      display: inline-block;
  }

  .watchlist-research.hide{
    /* visibility: hidden;
    opacity: 0; */
    /* display: inline-block !important; */
    display: none;
  }

  .watchlist-custom-col-h {
    display: inline-block;
    width: 120px;
    text-align: center;
}

  .watchlist-custom-col {
      display: inline-block;
      width: 120px;
      text-align: center;
  }

  
  .watchlist-custom-row {
      width: auto;
  }
 
  .watchlist-custom-row2 {
    width: auto;
}

.watchlist-header-margin{
    margin-right: -7px !important;
}

.dragicon {
    position: absolute;
    height: 10px;
    margin-top: 3px;
}