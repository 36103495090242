.payment-bg {
  background: #fff;
  min-height: 100%;
}
.payment-bg .header-logo {
  height: 60px;
}
.payment-bg .status-message {
  margin-top: 100px;
  text-align: center;
  color: #000;
  font-size: 18px;
}
.payment-bg .loader-payments {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  width: 100%;
  height: 100%;
}
.payment-bg .loader-payments img {
  width: 60px;
}
.demat-heading {
  width: 100%;
  background-color: #28a745;
  padding-left: 20px;
  height: 40px;
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 15px;
}
.payment-form {
  width: 80%;
  display: flex;
  flex-direction: column;
  padding: 10px;
}
.payment-form.container {
  @media (min-width: 1200px) {
    max-width: 720px;
  }
}
.payment-form .details-error {
  color: red;
  text-align: center;
  font-size: 14px;
  margin-top: 10px;
}
.payment-form .error-panel{
  color: red;
  text-align: center;
  font-size: 14px;
  padding: 5px;
}
.demat-number-details {
  display: flex;
  align-items: center;
  padding: 10px 0px;
  font-size: 14px;
  color: #000;
  font-weight: 500;
}
.demat-number-details .demat-text {
  white-space: nowrap;
  line-height: 38px;
  padding-left: 0;
}
.demat-number-details .payment_formcontrol_details {
  pointer-events: none;
}
.demat-number {
  display: flex;
  align-items: center;
  padding: 10px 0px;
  font-size: 14px;
  color: #000;
  font-weight: 500;
}
.demat-number .demat-text {
  white-space: nowrap;
  line-height: 38px;
}
.demat-number .number-field {
  display: flex;
  flex-direction: column;
}
.demat-number .number-field .error-text {
  color: red;
  margin-top: 5px;
}
.demat-number .number-field .captcha-text{
  margin-top: 20px;
}
.demat-number .number-field .captcha-text .captcha-refresh{
  margin-left: 8px;
  border: none;
  background: #cfcfcf;
  border-radius: 4px;
}
.demat-number .payment_formcontrol {
  padding: 10px 0;
  height: 38px;
  border-radius: 4px;
  background: #fcfcfc;
  text-indent: 12px;
}
.demat-number .payment_formcontrol:focus {
  color: #000;
}
.button-panel {
  display: flex;
  justify-content: center;
}
.payment-button {
  border: none;
  color: #fff;
  font-size: 16px;
  height: 38px;
  border-radius: 0px;
  background-color: #28a745;
  padding: 0 30px;
  margin-top: 20px;
}
.payment-button:focus {
  background-color: #28a745;
}
.payment-notes {
  color: #000;
  margin: 20px 0px;
}
.payment-notes ul {
  font-size: 14px;
  font-weight: 500;
}
.payment-notes ul li {
  font-size: 12px;
  font-weight: 300px;
}
.payment-welcome {
  color: #000;
  font-size: 15px;
  padding: 20px;
}
.payment-notes-details {
  font-size: 15px;
  color: #000;
}
@media only screen and (max-width: 600px) {
  .payment-form {
    width: 100%;
  }
  .demat-number {
    justify-content: space-between;
    flex-direction: column;
  }
  .demat-number .payment_formcontrol {
    width: 100%;
  }
  .demat-number-details {
    justify-content: space-between;
  }
  .demat-number-details .payment_formcontrol {
    width: 100%;
  }
}
