.mk-btn-holder{
    display: flex;
    margin: 0px;
    padding: 0px;
}
.mk-btn{
    width: 100%;
    color: #111111;
    font-size: 12px !important;
    background-color: #aaaaaa;
    border: 0px;
    padding: 5px 0px;
    margin-right: 10px;
}
.mk-btn.chart{
    background-color: transparent;
    height: 27px;
    border: 1px solid rgb(91, 94, 99);
    padding: 3px;
}
.mk-btn.watch{
    background-color: transparent;
    color: #eeeeee;
    font-size: 14px !important;
    /* padding: 3px 0px !important; */
    height: 27px;
    border: 1px solid rgb(91, 94, 99);
    padding: 3px;
    padding-bottom: 8px;
}
.mk-content {
    height: calc(100% - 50px);
    overflow-y: auto; 
    margin-top: 10px;
    overflow-x: hidden;
}

.mk-content-scr {
    height: calc(100% - 35px);
    overflow-y: hidden; 
}

.mk-content.scrolly {
    overflow-y: scroll;
}

.mk-parent-holder{
    border:1px solid #3a3a3a;
    background: #131314;
    padding: 10px;
}

.mk-whl-header {
    color:#aaa;
    font-size: 11px;
    /* font-weight: lighter; */
    margin-bottom: 5px;
    margin-right: 2px;
}

.mk-wlh-cell {
    padding-top: 5px;
}

.mk-grey-small{
    font-size: 11px;
    color: #aaa;
    /* font-family: "Roboto-Light" */
}

/* ===================================================== */
/* Overview */

.overview-sort {
    display: flex;
    margin-left: 80px !important;
}

.overview-sort .span-label{
    white-space: nowrap;
    display: inline-flex;
    align-items: center;
}

.overview-sort .dropdown{
    margin-left: 10px !important;
    width: 120px;
    background: #42454A;
    padding: 2px 5px !important;
}

.mk-chart{
    height: 27px;
}

.grades-big{
    height: calc(100% - 10px);
    overflow-y: auto;
}

/* ===================================================== */
/* heatmap */

.hm-row {
    padding: 5px;
    margin-top: 10px;
    margin-bottom: 20px;
}

.hm-drop-down {
    float: left;
    margin-left: 10px;
    min-width: 132px;
    /* padding-right: 4px;
    padding-left: 4px; */
    background: #42454A;
}

.heatmap-dropdown .dropdown{
    padding: 0px 8px !important;
}

.heatmap-dropdown .dropdown-menu{
    min-width: 132px;
}

.hm-index-div {
    display: flex;
    height: 30px;
    margin-top: 5px;
}

.hm-h-col {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0px;
    font-size: 14px;
}

.hm-col {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
}

.hm-green-5 {
    background:#253f0E;
}

.hm-green-4 {
    background:#2c5211;
}

.hm-green-3 {
    background:#4f7b23;
}

.hm-green-2 {
    background:#71a038;
}

.hm-green-1 {
    background:#88bf45;
}

.hm-gray {
    background: #42454A;
}

.hm-red-1 {
    background: #ff917c;
}

.hm-red-2 {
    background: #ff6051;
}

.hm-red-3 {
    background: #ff473a;
}

.hm-red-4 {
    background: #d12820;
}

.hm-red-5 {
    background: #ac1008;
}

.hm-base-holder {
    height: calc(100% - 140px);
}

.hm-base {
    height: 100%;
    overflow-y: auto;
}


.heatmap-box {
    width: 9%;
    float: left;
    margin: 1px;
    border-radius: 0px;
    cursor: pointer;
}

.hm-box-content {
    padding: 8px;
}

.hm-popover {    
    width: 300px;
    min-height: 100px;
    border-radius: 0px;
    border: 1px solid #aaa;
    background: #131314;
}

.hm-popover .arrow {
    display: none;
}
/* ===================================================== */
/* Sectors */

.secs-base {
    border: 1px solid #3a3a3a;
    height: calc(100% - 30px);
    margin-top: 15px;
    margin-left: 10px;
    margin-right: 10px;
}

.secs-h-row {
    height: 38px;   
    display: flex;
}

.secs-h-1 {
    width: 30%;
    text-align: center;
    padding-top: 10px;
    background: #3a3a3a;
    border-right: 1px solid #3a3a3a;
}
.fnosecs-h-1 {
    width: 20%;
    background: rgba(66, 69, 74, 0.74);
    border-right: 1px solid #3a3a3a;
}
.secs-h-2 {
    width: 35%;
    text-align: center;
    padding-top: 10px;
    background: #3a3a3a;
    border-right: 1px solid #3a3a3a;
}

.secs-h-3 {
    width: 35%;
    text-align: center;
    padding-top: 10px;
    background: #3a3a3a;
}
.secs-h-4 {
    width: 20%;
    text-align: center;
    padding-top: 10px;
    background: #3a3a3a;
}
.secs-data-row {
    height: calc(100% - 38px);   
    overflow-y: auto;
    display: flex;
}

.secs-data-1 {
    height: 100%;
    background: rgba(66, 69, 74, 0.74);
    width: 30%;
    border-right: 1px solid #3a3a3a;
}
.fnosecs-data-1 {
    height: 100%;
    background: rgba(66, 69, 74, 0.74);
    width: 20%;
    border-right: 1px solid #3a3a3a;
}
.secs-data-2 {
    height: 100%;
    width: 35%;
    border-right: 1px solid #3a3a3a;
}
.fnosecs-data-2 {
    height: 100%;
    width: 20%;
    border-right: 1px solid #3a3a3a;
}
.secs-data-3 {
    height: 100%;
    width: 35%;
}
.secs-data-4 {
    height: 100%;
    width: 20%;
}
.secs-item-row {
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    height: 33px;
}


/* ===================================================== */
/* Percent bar */

.percentbar {
    height: 20px;
    float: left;
}

.percentbar-anim {
    -webkit-transition: width 1s ease-in-out;
    -moz-transition: width 1s ease-in-out;
    -o-transition: width 1s ease-in-out;
    transition: width 1s ease-in-out;
}


.sector-per-row{
    display: flex;
    flex-direction: row;
}

.sector-per-row.reverse{
    flex-direction: row-reverse !important;
}

.sector-per-rowchild{
    flex: 1;
    display: flex;
    position: relative;
}

.sector-per-rowchild.sector-justify{
    justify-content: flex-end;
}

/* ===================================================== */
/* Advance decline */

.advdec-tab-content {
    background-color: #262A30;
    height: calc(100% - 30px);
    overflow-y: hidden;
}

.advdeccontent {
    height: calc(100% - 60px);
    margin-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    overflow-y: auto;
}

.advwidget-holder-col {
    padding:6px;
}

.advdecwidget-holder {
    border:1px solid #3a3a3a;
    height: 350px; 
    width: 100%;  
}

.advdec-content-scroller {
    overflow-y : auto;
    height: calc(100% - 42px);
    padding-top: 10px;
}

.advdec-percentbar {
    height: 12px;
    float: left;
    margin-top: 1px;
}

/* ===================================================== */
/* FII DII */

.fiidiicontent {
    height: calc(100% - 20px);
    margin-top: 15px;
    padding-left: 5px;
    padding-right: 5px;
    overflow-y: auto;
}

.fiidiiwidget-holder {
    border:1px solid #3a3a3a;
    height: 390px; 
    width: 100%;  
}

.fiidii-square {
    height: 12px;
    width: 12px;
    float: left;
}

.fiidii-notations {
    width: 100%;
    margin-top: 10px;
}

/* ===================================================== */
/* indices */

.indices-list-holder {
    height: calc(100% - 30px);
    overflow-y: auto;
}

.indices-card {
    width: 100%;
    height: 150px;
    border: 1px solid #3a3a3a;
    margin: 10px 0px;
}

.ic-row {
    padding: 6px;
}

.ic-ltp {
    font-size: 20px;
    /* font-weight: lighter; */
}

.ic-ch {
    font-size: 16px;
}

.ic-chp {
    font-size: 14px;
}

.ic-dh {
    font-size: 12px;
}

.ic-dh-key {
    color: #aaa;
}

.ic-back-btn {
    float: left;
    width: 180px;    
    background: transparent;
    border: 0;
    color: #bbb4b4;
    display: flex;
    align-items: baseline;
    padding-left: 0;
}


/* ===================================================== */
/* Intraday deals */
.intradaydealscontent {
    height: calc(100% - 60px);
    overflow-y: auto;
}

.id-header {
    color:#aaa;
    font-size: 11px;
    /* font-weight: lighter; */
    padding-top: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #3a3a3a;
}

.id-content{
    height: calc(100% - 40px);
    overflow-y: auto;
}

.id-row {
    font-size: 14px;
    border-bottom: 1px solid #3a3a3a;
}

.id-col {
    padding-top: 10px;
    padding-bottom: 10px;
}

.id-hl-col {
    padding-top: 10px;
    padding-bottom: 10px;
    background: #42454A;
    height: auto;
}

/* ===================================================== */
/* Market Status Summary */
.summary-small-widget {
    height: 270px;
    overflow: hidden;
}

.summary-allholders {
    border: 1px solid #3a3a3a;    
    background: #262A30;
}

.summary-componentholder {
    min-width: 340px;
    margin: 10px;
}

.summary-title {
    margin-left: 10px;
}

.summary-widget-title {
    background: #42454A;
}

.summary-symname {
    font-size: 14px;
    padding: 10px;
}    

.summary-bar {
    height: 20px;
    margin-top: 10px;
}

.summary-green {
    background: #1EBF8A;
}

.summary-orange {
    background: #D43D3F;
}   

.summary-blue {
    background: rgb(22, 109, 190);
}

.summary-violet {
    background: #5E66D4;
}

.summary-yellow {
    background: #6B8101;
}

.summary-red {
    background: #E5263C;
}

.summary-magenta {
    background: #B835BE;
}

.summary-data {
    margin-top: 12px;
}

.mkt-sts-smry-content-scroller {
    height: calc(100% - 40px);
}

.summary-content-scroller {
    overflow-y : auto;
    height: 100%;
}


/* ===================================================== */


/*============================================================== EQUITY OVERVIEW ================================================================*/
.mkt-sts-overview-row {
    color:#fff;
    font-size: 14px;
    margin-bottom: 5px;
    margin-right: 4px;
}

/*------------------ Buzzing Stocks --------------------*/
.buz-base-content{
    height: 40%;
}
.flex{
    display: flex;
}
.buz-icon{
    margin-right: 7px;
    color:rgb(80, 80, 83);
}
.buz-small-ft{
    font-size: 12px;
}
.buz-green{
    color: #1eb89a;
}
.buz-big-ft{
    font-size: 14px;
    color: #fff
}

.buz-big-md {
    font-size: 13px;
    color: #fff;
    word-wrap: break-word;
}

.buzz-bullet {
    color: #fff;
    list-style: none;
    margin-left: 17px;
    position: relative;
}

.buzz-bullet.green:before {
    color: #1eb89a;
    content: "\2022";
    font-size: 22px;
    margin-left: -15px;
    margin-right: 7px;
}

.buzz-bullet.red:before {
    color: #E5283F;
    content: "\2022";
    font-size: 22px;
    margin-left: -15px;
    margin-right: 7px;
}

.buzz-stocks-panel {
    font-size: 14px;
    color: #eee;
}

.buzz-stocks-panel:hover {
    color: #eee;
}

.buzz-stocks-panel:focus {
    text-decoration: none;
    color: #eee !important;
}

/*------------------ Calendar --------------------*/
.cldr-dropdown{
    background: #42454A;
    padding: 0px 5px;
    margin-left:7px;
    height: 28px;
    width:135px;
}
.cldr-head{
    display:flex;
    flex-wrap: nowrap;
    font-size: 10px;
    color: #93959a;
}
.cldr-dull{
    background: #3b3e4c;
}
.cld-row-txt{
    text-align: center;
    padding-top: 10px;
}
.cld-res-row{
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
}
.cld-content{
    overflow: scroll;
    height: calc(100% - 120px);
}
.cld-search .search-input {
padding: 2px !important;
box-sizing: border-box !important;
background: transparent;
border-radius: 0;
outline: none;
border: none;
-webkit-box-shadow: none !important;
box-shadow: none !important;
border-bottom: 1px solid rgb(197, 194, 194) !important;
outline-color: transparent;
outline-style: none;
margin: 0px !important;
font-size: 11px !important;
}

/*============================================================== FNO OVERVIEW ================================================================*/
.fno-overview-row {
    padding: 10px;
    color: #fff;
    font-size: 14px;
}

.fno-overview-row:nth-child(even) {
    background-color: #262A30; 
}

.fno-overview-row:nth-child(odd) {
    background-color: #131314; 
}

/*------------ Screeners ----------*/
.scr-ovr-row{
    color: #fff;
    font-size: 10px;
    /* font-weight: lighter; */
}
.scr-pad-col{
    padding: 8px 8px;
}

.scr-pad-col.msg{
    padding: 8px 8px;
}

.scr-pad-col2 {
    padding: 6px 4px;
}


.scr-ovr-header{
    color:#aaa;
    font-size: 11px;
    /* font-weight: lighter; */
    line-height: 20px;
}
.scr-dull-row{
    background: rgba(38, 42, 48, 0.2);
}

.fno-unusual-datarow{
    font-size: 14px;
    color:#fff;
}

/*============================================================== CURRENCY OVERVIEW ================================================================*/
.cur-overview-dropdown-header {
    background: #262A30;
    height: 35px;
    padding-top: 5px;
}

.cur-overview-dropdown {
    float: left;
    padding-right: 4px;
    padding-left: 4px;
    background: #42454A;
    min-width: 110px;
}

.curExpDrop .dropdown-menu{
    max-height: 120px;
    overflow-y: auto;
    overflow-x: hidden;
}

/*------------------Commodity---------------------------*/
.mk-commodity-altrow{
    color: #fff;
    font-size: 14px;
    margin-bottom: 5px;
}
.mk-commodity-altrow:nth-child(odd) {
    background-color: rgba(58,58,58, 138);
}
.mk-commodity-altrow-cell{
    margin-top: 5px;
}
.mk-commodity-btn-holder{
    display: flex;
    margin: 5px;
    padding: 0px;
}

.fno-unsual-row{
    padding: 8px 0px;
    margin-bottom: 1px;
    /* background: #262928; */
}

.fno-unusual-col{
    padding-top:8px;
    padding-bottom: 8px; 
}

.fno-unusual-col.alternate{
    background: rgba(38, 42, 48, 0.2);
}

.fno-unusual-headerrow{
    display: flex;
    flex-wrap: wrap;
}

.sum-dropdown .dropdown{
    padding: 0px 8px !important;
}

.StepProgress-item,
.StepProgress{
    position: relative;
}

.StepProgress::before{
    display: inline-block;
    content: '';
    position: absolute;
    top: 25px;
    left: 16.25%;
    width: 10px;
    height: 100%;
    border-left: 1px solid #3A3B3D;
}

.StepProgress-item::before {
    display: inline-block;
    content: '';
    position: absolute;
    top: 25px;
    left: 16.3%;
    width: 10px;
    height: 100%;
    border-left: 1px solid #3A3B3D;
}
