/* ===================================================== */
/* My Details  */

.comingsoon {
    height: 300px;
    font-size: 10em;
    display: flex;
    align-items: center;    
    justify-content: center;
}

.profile-tab-content {     
    background-color: #262A30;
    height: calc(100% - 30px);
    overflow-y: auto;
}

.profile-content {
    background-color: #262A30;
    height: 100%;
    padding-left: 30px;
}

.profile-content.scroll {
    overflow-y: auto;
}

.profile-tab-content.noscroll {
    overflow-y: hidden !important;
}

.profile-bottom-underline {
    border-bottom: 2px solid #35363A;
    min-height: 20px;    
}

.profile-info-icon {
    width: 13px;
    margin-left: 8px;
    cursor: pointer;
}

.profile-footer {
       
}

.pf-head2 {
    display: flex;
    flex-direction: column;
    height: calc(100% - 30px);
    justify-content: space-between;
}

.profile-pan-info {
    padding: 25px;
    font-size: 11px;
}

.profile-bank-tabs {
    margin-top: 20px;
    padding-left: 15px;
}

.profile-branch-link > a {
    color: #1EBF8A;
    text-decoration: underline;
    cursor: pointer;
}

.profile-header {
    color:rgba(255, 255, 255, 0.336);    
    font-size: 11px !important;   
    margin-top: 25px;    
}

.dpholdings-header {
    /* color:rgba(255, 255, 255, 0.336);     */
    color: #aaa;
    font-size: 11px !important; 
    padding-top: 10px;
}
.dpholdings-total{
    font-size: 14px !important; 
    padding-top: 20px;
    color: #fff;
    font-family:'Roboto' !important;
}
.profile-equity-row {
    color: #fff;
    font-size: 14px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.profile-equity-row-key {
    /* color:rgba(255, 255, 255, 0.336); */
    color: #aaa;
}

.profile-edit {
    padding: 0;
}

.profile-popover {
    background-color:#42454A !important;
    min-width: 150px;
    padding: 0px;
    border-radius: 0;
}

.demat-back-btn {
    background: transparent;
    font-size: 16px;
    border: none;
    margin-left: 10px;
    color: rgba(255, 255, 255, 0.336);
    margin-bottom: 10px;
}

.spoa-text {
    margin-top: 30px;
    margin-bottom: 40px;
}

/* ===================================================== */
/* Fund Transfer */
.funds-row {
    margin-top: 20px;
    margin-bottom: 30px;
}

.funds-header {
    margin: 20px 0 30px 20px !important;
}

.funds-title {
    font-size: 14px;
    color: #aaaaaa
}

.fund-padding{
    padding: 7px 0px 0px;
}

.funds-arrow {
    color: #fff;
    margin-top: 8px;
    margin-left: 0 !important;
    padding-left: 0 !important;
    font-size: 30px !important;
}

.funds-note {
    border-left: 1px solid #3a3a3a;
    font-size: 14px;
    margin-top: 20px;
    min-height: 450px;
    padding-left: 30px;
}

.funds-note > ul {
    padding-left: 0 !important;    
}

.funds-instruction {
    margin: 20px 0 20px 20px !important;
}

.funds-banks {
    margin-top: 5px;
}

.funds-banks .dropdown{
    padding: 7px !important;
    background: #42454A;
}

.funds-banks .btn-group{
    background: #42454A;
    padding: 3px !important;
}

.funds-banks .search-input {
    border: none !important;
}

.funds-banks .dropdown .dropdown-menu{
    margin: 0px;
    max-height: 150px !important;
    overflow-y: auto;
}

.add-fund:focus{
    background: #1ebf8a;
}

.withdrawal-bal {
    margin-top: 70px;
}

.bal-amt {
    margin-top: 10px;
    color: #eeeeee;
}

.withdraw-tnc{    
    margin-top: 30px;
    margin-left: 20px;
    font-size: 14px;
}

.withdraw-tnc > a {
    color: #1EBF8A !important;
    text-decoration: underline !important;
    cursor: pointer;
}

.transfer-log-row {
    color:#fff;
    font-size: 14px;
    margin-bottom: 1px;
    background-color: #3a3b40;
    padding: 15px;    
}

.transfer-log-accordion {
    border-radius: 0 !important;
    background: #262A30 !important;
    height: 45px;
}

.transfer-button {
    background: #E5283F;
    margin-top: 6px;
    
}

.fund-error-show{
    display: block;
    opacity: 1;
    margin: 10px 0px 0px 35px;
    font-size: 14px;
}

.fund-error-hide{
    display: none;
    opacity: 0;
}

.funds-row.pay-in{
    display: flex;
}

.pay-in-msg{
    align-self: flex-end;
    padding-bottom: 10px;
    font-size: 12px;
}
/* ===================================================== */
/* Settings */

.user-settings {  
    font-size: 14px !important;
}

.settings-content > div > a {
    color: #1EBF8A !important;
    cursor: pointer;
}

.settings-menu {
    background: #42454A;
    padding: 20px;
}

.settings-content {
    padding: 20px;
}

.settings-header {
    color: #1ebf8a !important;
    border-bottom: 1px solid #e5e5e5 !important;
}

.settings-header-cp {
    color: #04704c !important;
    border-bottom: 1px solid #e5e5e5 !important;
    font-weight: normal !important;
}

.settings-cp-dialog {
    min-width: 400px;
}

/* ===================================================== */

/*---DP HOLDINGS-----*/
.dp-tab-content{
      background-color: #262A30;
      height: calc(100% - 40px);
      overflow-y: hidden;
}
.dp-net{
        height: 40px;
        margin: 10px 0px 0px 10px;
        display: flex;
        align-items: center;
}
.dp-sm{
    font-size: 12px;
    color: #1ebf8a;
}
.dp-md{
    font-size: 14px;
    color:#fff;
    margin-right: 5px;
}
.dp-bg{
    font-size: 18px;
    color:#fff;
}
.dp-scr{
    margin-top: 15px;
     margin-bottom: 15px;
     overflow-y:scroll;
     overflow-x:  hidden;
     height:calc(100% - 55px);
}

#profile-popover.popover.right>.arrow:after{
    border-right-color: #42454A;
}
  
#profile-popover.popover.right>.arrow {
    border-right-color: #42454A;
}

#profile-popover.popover.bottom>.arrow:after{
    border-bottom-color: #42454A;
}

#profile-popover.popover.bottom>.arrow {
    border-top-color: #42454A;
}

.profile-activations{
    padding-top: 5px;
    background-color: #262A30;
    display: flex;
    justify-content: space-around;
}

.profile-activations .buttons{
    display: inline-block;
    padding-right: 5px;
    align-content: stretch;
    width: 100%;
}

.profile-buttons{
    background-color: #AAB0AD;
    background: rgba(0,0,0,0.2);
    border: 1px solid #fff;
    color: #EEEEEE;
    height: 23px;
    font-size: 12px;
    display: block;
    width: 100%;
}

.profile-buttons:focus,.mtf-buttons:focus {
    outline: 0 none;
    border: 2px solid #1EBF8A !important;
}

.profile-tab-text{
    width: 50%;
    padding-right: 15px;
}

.mtf-modal{
    width: 40%;
    height: auto;
    min-height: 420px;
    min-width: 420px;
    top: 30%;
    font-size: 12px;
    background: rgb(43, 44, 49);
    border: 1px solid #3a3a3a;
    -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)
}

.mtf-header{
    background: #222427;
    height: 65px;
    margin-top: 10px;
    font-family:'Roboto' !important;
    font-weight: 400;
    text-align: center;
    padding-top: 20px;
    color: rgb(243, 243, 243);
}

.mtf-header span {
    font-size: 20px;
}

.mtf-close{
    float: right;
    background: #222427;
    font-size: 2rem;
    font-weight: 400;
    line-height: 1;
    color: rgb(243, 243, 243);
    border: none;
    padding-right: 20px;
}

.mtf-footer{
    position: absolute;
    width: 100%;
    bottom: 15px
}

.mtf-body{
    text-align: center;
    padding: 20px;
}

.mtf-footer .buttons{
    display: flex;
    justify-content: space-around;
}

.mtf-buttons{
    background-color: #AAB0AD;
    background: rgba(0,0,0,0.2);
    border: 1px solid #fff;
    color: #EEEEEE;
    height: 40px;
    font-size: 12px;
    display: block;
    width: 40%;
}
.mtf-message{
    min-height: 160px;
}

.mtf-message .mtf-activated{
    color: #fff;
    padding-top: 10px;
}

.mtf-message,.mtf-question{
    text-align: center;
    margin-bottom: 10px;
}

.mtf-message > .body-message{
    font-family: 'Roboto' !important;
    font-size: 14px;
    color: #1EBF8A !important;
}

.mtf-question-data{
    padding: 20px;
}

.mtf-terms{
    color: #1EBF8A !important;
    cursor: pointer;
}

.mtf-image{
    height:100px;    
}

.mtf-image-result{
    height:125px; 
}

.mtf-agree{
    padding-top: 10px;
}

.mtf-buttons.accepted{
    background-color: #1EBF8A !important;
}


.risk-modal {
    max-width: 60%;
    transform: translate(-50%,-50%) !important;
    left: 30%;
    top: 40%;
    height: auto;
    font-size: 16px;
}
.risk-footer{
    width: 100%;
    bottom: 15px;
}
.risk-footer .buttons{
    display: flex;
    justify-content: space-around;
    padding: 20px;
}
