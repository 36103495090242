
/* ===================================================== */
/* symbol search */

.search-parent{
    display: flex;
    align-items: flex-end;
    margin: 0px;
    display: -ms-flexbox;
    padding: 5px !important;
    outline: none;
    border-bottom: 1px solid #42454A !important;
 }

.pageheader-component.search-parent.dropdown.show{
    outline: none;
    border-bottom: 1px solid #1EBF8A !important;
 }

 .fa-show{
    visibility: visible;
}

.fa-hide{
    visibility: hidden;
}

.search {
    position: relative;
    width: 100%;
    margin-left: 5px;
}

.search-spinner-i {
    display: block;
    margin: auto;
    margin-top: 20%;
    pointer-events: none;
    color: #F5F5F5;
    animation: fa-spin 600ms infinite linear;
    text-align: center
}

  @keyframes fa-spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }


.search-result-parent{
    background: #42454A;
    position: absolute;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -o-transition: 0.5s;
    transition: opacity 1s;
    left: 0;
    top: 103%;
    padding: 10px 0px 5px 0px !important;
    outline: none;
    min-height: 300px;
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 1001;
}

.search-exp-span{
    float: right;
    font-size: 12px;
    padding-left: 2px;
    margin-top: 10px;
}

.search-ft-opt{
   display: flex;
   align-items: center;
   flex-wrap: wrap;
   margin-bottom: 10px !important;
}

.search-exp-stk-parent{
    display: flex;
    flex-direction: row;
}

.search-exp-stk-parent div{
    margin-right: 5px !important;
}

.search-exp-stk-parent div:last-child{
    margin-right: 0px !important;
}

.radio-inline{
    margin-left : -10px;
}

#search-result-radio.ideas [type="radio"]:checked ~ .ideas-radio:before,
#search-result-radio.ideas [type="radio"]:not(:checked) ~ .ideas-radio:before {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    border: 1px solid #EEEEEE;
    border-radius: 100%;
}

.search-ft-opt div .radio-inline+.radio-inline{
    margin-left: 0px;
}

.search-ft-opt div label:first-child{
    padding-left: 2px !important;
}

.search-radio-margin{
    margin: 0 !important;
    padding: 0 !important;
    margin-top: 10px !important;
}
.search-radio-parent{
    display: inline-block;
    cursor: pointer;
}

.search-result{
    padding-left: 10px !important;
    /* margin-top: 20px !important; */
    padding-right: 10px !important;
}

.search-result-row {
    color:#EEEEEE;
    font-size: 12px;
    cursor: pointer;
    padding: 10px 2px !important;
    /* border: 1px solid transparent; */
    border-bottom: 1px solid #5f5f5f;
    margin-top: 1px !important;
}

.search-result-row:focus{
    outline: none !important;
}

.search-ft-dropdown .search-drdn-row{
    border: 1px solid transparent;
}

.search-result-row.search-select, .search-ft-dropdown .search-select{
    border: 1px solid #1EBF8A;
    outline: none;
}

.search-tab-parent{
    background: #5B6066;
}

.search-tab-parent>ul:first-child{
    padding-left: 10px;
}

.search-tab-parent>ul:last-child{
    padding-right: 10px;
}

.search-tab-parent>ul>li{
    margin-right: 0 !important;
}

.search-tab-parent>.tab-bar > .active > a:after{
    width: 100% !important;
}

.search-btn-bar-parent{
    margin-top: 10px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    font-size: 12px;
}

.search-ft-dropdown{
    overflow-y: auto;
    border: 1px solid #EEEEEE;
    max-height: 150px;
    overflow-x: hidden;
}

#option-drpdwn-id span{
    padding: 10px 10px 0px 10px !important;
}
#option-drpdwn-id>.dropdown-menu{
    min-height: 175px;
}
.search-ft-dropdown.dropdown-menu{
    width: 100%;
    background: #5B6066;
    border-radius: 0px;
    min-width: 0px;
    top:92% !important;
    transform: none !important;
}
.search-ft-dropdown.dropdown-menu>li>a{
    color:#EEEEEE;
    background: #5B6066;
}

.search-ft-dropdown>li>a{
    display: flex !important;
    align-items: center !important;
    padding-left: 5px;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.search-ft-dropdown>li>a>span{
    display: block;
    padding-left: 10px;
}

.search-ft-dropdown-row{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px !important;
    border: 1px solid #EEEEEE ;
    min-height: 32px;
    background: #5B6066;
}

.search-ft-dropdown-divider{
    background: #636363 !important;
    margin: 0px 5px!important;
}

.search-result-btn-holder {
    display: flex;
    align-items: center
}

.search-result-btn-holder>div{
    padding-left: 5px;
}
.search-result-btn {
    width:100%;    
    color: #EEEEEE;
    font-size: 12px !important;
    border: 0px !important;
    border-radius: 0px !important;
    padding:5px 0px;   
}

.search-result-watch{
    width:100%;    
    color: rgb(41, 41, 41);
    font-size: 12px !important;
    background-color: #AAAAAA;
    border: 0px;
    padding:5px 0px;  
    border-radius: 0px; 
}



.search-empty-screen{
    position: relative;
    margin: auto !important;
    height: 30%;
    margin-top: 5% !important;
    text-align: center;
}
.search-empty-img{
    width: 40%;
    height: 150px;
    display: block;
    margin: auto;
    margin-top: 10px;
}

.search-result-quote-span{
    font-size: 12px;
    font-family: 'roboto-light'
}

.search-result-quote-span.grayout{
   color: #AAAAAA;
   padding-right: 5px;
}

.symbol-watchlist.small{
    height: auto;
    top: 20%;
}

.search-research{
    position: absolute;
    right: -13px;
    top: -7px;
    color: #1EBF8A;
    padding-right: 5px;
    visibility: visible;
    opacity: 1;
    width: 50px;
}

.search-research.hide{
  visibility: hidden;
  opacity: 0;
}

.search-chg-pad{
    padding: 0px 10px 0px 0px !important;
}
/* ===================================================== */
/* indices quote */

.indeces-parent{
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 4px;
    border-bottom: 1px solid transparent;
    font-size: 14px; 
    line-height: 14px;
    cursor: pointer; 
    outline: none;
}

.indeces-row{
    display: flex;
    align-items: center;
}

.dropdown-display{
    display: none !important;
}

.indeces-parent.dropdown.show{
    border: none;
    outline: none;
    border-bottom: thin solid #1EBF8A;
}

.indeces-parent .indeces-caret{
    font-size: 20px !important;
    transform: rotate( 0deg );            
    -moz-transform:    rotate( 0deg );            
    -ms-transform:     rotate( 0deg );            
    -o-transform:      rotate( 0deg );            
    -webkit-transform: rotate( 0deg );            
     transition:  transform 300ms ease;
    -moz-transition:    -moz-transform 300ms ease;
    -ms-transition:     -ms-transform 300ms ease;
    -o-transition:      -o-transform 300ms ease;
    -webkit-transition: -webkit-transform 300ms ease;
}

.indeces-parent.dropdown.show .indeces-caret{
    transform: rotate( 180deg );            
    -moz-transform:    rotate( 180deg );            
    -ms-transform:     rotate( 180deg );            
    -o-transform:      rotate( 180deg );            
    -webkit-transform: rotate( 180deg );            
     transition:  transform 300ms ease;
    -moz-transition:    -moz-transform 300ms ease;
    -ms-transition:     -ms-transform 300ms ease;
    -o-transition:      -o-transform 300ms ease;
    -webkit-transition: -webkit-transform 300ms ease;
}

.indeces-dropdown{
    overflow: hidden;
    z-index: 1001;
}

.indeces-dropdown>li{
    margin-top: 5px !important;
}

.indeces-dropdown-content{
    max-height: 300px;
    float: left;
    overflow-y: auto;
    width: 100%;
    padding-top: 5px !important;
}

.indeces-dropdown.dropdown-menu>div>li{
    margin-right: 2px;
}
.indeces-dropdown.dropdown-menu>div>li>a{
    color:#EEEEEE;
    background: #383B42;
    text-decoration: none;
}

.indeces-dropdown.dropdown-menu>div>li>a:hover{
    background: #42464F;
}

.indeces-dropdown>div>li>a{
    display: flex !important;
    align-items: center !important;
    padding-left: 10px;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}

.indeces-dropdown>div>li>a>span{
    display: block;
    padding-left: 10px;
}

.indeces-lmt{
    padding-top: 5px;
}

#indeces-search-input{
    padding-left: 5px !important;
    padding-right: 5px !important;
    padding-top: 5px !important;
 }

 #indeces-search-input>i{
     margin-right: 10px !important;
     top: 40%;
 }

#indeces-search-input>input{
    border: 1px solid rgb(197, 194, 194);
}

#indeces-search-input .search-right-icon{
    top: 8px;
}

#indices-modal-parent{
    position: absolute;
    width: 100%;
}

.indices-modal{
    width: 100%;
    padding: 3px 5px;
    background: transparent;
    font-size: 12px !important;
    color: white;
}

.indices-modal .modal-body{
    min-height: auto !important;
}

.indices-modal>.modal-content{
    border-radius: 2px !important;
}

.indices-modal>.modal-content .modal-footer{
    text-align: center;
    height: 34px;
    padding: 5px;
}

.indices-modal>.modal-content .modal-footer .btn{
    padding: 3px 20px;
    background: #1EBF8A;
    color: #EEEEEE;
    border: none;
    font-size: 12px !important;
}

/* ===================================================== */
/* user options */

.useroption-parent{
    display: flex;
    align-items: center;
    margin: 0px !important;
    box-shadow: none !important;
    justify-content: center;
}

.usropt-drdown{
    padding-left: 10px !important;
    padding-right: 10px !important;
    display: flex;
    align-items: center;
    height: 100%;
    margin: 0px !important;
    font-size: 14px !important;
    border-bottom: 1px solid transparent;
}

.useroption-parent .dropdown:hover .dropdown-menu{
    display: block !important;
    opacity: 1;
}

.useroption-parent>.usropt-drdown.show{
    outline: none;
    border-bottom: 1px solid #1EBF8A !important;
}

.usropt-caret{
    position: absolute;
    top: 10%;
    right: 0;
    font-size: 20px;
    margin-right: 10px;
    transform: rotate( 0deg );            
    -moz-transform:    rotate( 0deg );            
    -ms-transform:     rotate( 0deg );            
    -o-transform:      rotate( 0deg );            
    -webkit-transform: rotate( 0deg );            
     transition:  transform 300ms ease;
    -moz-transition:    -moz-transform 300ms ease;
    -ms-transition:     -ms-transform 300ms ease;
    -o-transition:      -o-transform 300ms ease;
    -webkit-transition: -webkit-transform 300ms ease;
}

.dropdown.show .usropt-caret{
    transform: rotate( 180deg );            
    -moz-transform:    rotate( 180deg );            
    -ms-transform:     rotate( 180deg );            
    -o-transform:      rotate( 180deg );            
    -webkit-transform: rotate( 180deg );            
     transition:  transform 300ms ease;
    -moz-transition:    -moz-transform 300ms ease;
    -ms-transition:     -ms-transform 300ms ease;
    -o-transition:      -o-transform 300ms ease;
    -webkit-transition: -webkit-transform 300ms ease;
}

.useroption-header{
    font-family: "Roboto-Light";
    font-size: 12px;
    text-align: center;
}

.useroption-header .user-img{
    width: 100%;
}

.useroption-header .user-id{
    display: block;
    padding-top: 5px;
    white-space: normal;
}

/* ===================================================== */

/* cart */

.cal-cart-parent{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

/*=========================================================*/
/* Calculator */

.calc-drdown-parent{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0px !important;
    border-bottom: 1px solid transparent !important;   
}

.calc-drdown-parent:hover .dropdown-menu{
    display: block !important;
    opacity: 1;
}

.calc-drdown-parent.show{
    outline: none;
    border-bottom: 1px solid #1EBF8A !important;
}

#calc-drdown{
    text-align: center;
    color: #1EBF8A;
    width: 100%;
    box-shadow: none;
    margin: 0px !important;
    padding: 0px !important;
}

#calc-drdown:active,#calc-drdown:focus{
    outline: none;
}


/*=========================================================*/
/* Notification */

.notify-parent{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    
}

.badge-notify{
    min-width: 0px !important;
    background:#E5283F;
    position:relative;
    top: -17px;
    left: 5px;
    width: 8px;
    height: 8px !important;
    padding: 0px !important;
    margin: 0px !important;
   }
/*my css portfolio tracker page css */
.no-border{
    border-right: 0px !important;
}
.portfolio-tracker{
    text-align: center;
}
.portfolio-tracker .header-text1{
    border-right:1px solid #3a3a3a;
    padding-top: 12px !important;
    font-family: 'Roboto' !important;
    font-size: 14px !important;
    color: white;
}
.widget-title{
    padding: 20px 20px 3rem !important;
    color:#fff !important;
    font-family: 'Roboto' !important;
}
.portfolio-tracker .portfolio-header-title{
    color: #aaa;
    font-size: 11px;
}
.portfolio-tracker .portfolio-header-value{
    color:#fff;
    font-size:20px;
}
.portfolio-tracker .right-button{
    padding-top:15px;
}
.portfolio-tracker .right-button .btn-footer{
    background-color: #4b4e536e;
}
.btn-footer-left{
    width:125px !important;
}
.light-Button .btn-footer{
    background-color: #4b4e5354 !important;
    padding:0px 15px;
    margin:10px 10px;
}
.margin-bottom{
    margin-bottom: 1.5rem;
}
.table-data {
    padding: 5px 5px !important;
    overflow-y: inherit !important;     
    BORDER-BOTTOM: 1px solid #3a3a3a;
    color:#ffffff;
}
.portfolio-row{
    font-size: 14px;
}

/* .table-data :last-child{
    padding: 5px 5px !important;
    overflow-y: inherit !important;     
    border: none;
} */

/*------dialouge css-----*/
.modal-dialog.appdialogmodal.tradehistory-data .modal-content{
    background: #272b31 !important;
}
.modal-dialog.appdialogmodal.tradehistory-data .modal-body{
    padding-left:0px;
    padding-right: 0px;
}
.data-total.data-total1{
    bottom: 46px!important;
    background: #24272c !important;
    font-size: 12px;
    color: #727375;
    width: 100%;
    left: 0px;
    position: absolute;
}
.data-total.data-total1 span{
    color: #fff;
}
.last-row{
    padding: 30px !important;
}
#tradehistory{
    height:245px;
    overflow: scroll;
}
/* .shadow-trade
{
    -webkit-box-shadow: -8px 9px 47px -2px rgba(0,0,0,0.75);
    -moz-box-shadow: -8px 9px 47px -2px rgba(0,0,0,0.75);
    box-shadow: -8px 9px 47px -2px rgba(0,0,0,0.75);
} */
.history-close-button .close{
    position: absolute;
    right: 18px;
}
.last-traded-price p{
    font-size: 12px;
}
/*------end dialouge css----*/
.data-total{
    background: #131313 !important;
    padding: 5px !important;
    border: 1px solid #3a3a3a;
    position: absolute; 
    bottom: 115px;     
    width: calc(100% - 32px);
}
.bottomchange .data-total{
    width: calc(100% - 20px);
}
.bottom-note{
    position: absolute;
    bottom: 65px;
    width: calc(100% - 32px);
    font-size: 11px;
    padding: 10px;
}
.bottom-note-trade{
    font-size: 11px;
    padding: 5px;
    color: #d9d9d9;
}

.bottomchange .bottom-note{
    bottom: 5px;
}
.data-total-sector{
    background: #131313 !important;
    padding: 5px !important;
    border-bottom: 1px solid #3a3a3a;
}
.sector-banking{
    padding: 0px;
}
.sector-banking .heading{
    color: #1EBF8A;
    /* margin:0px 0px 24px 0px; */
    margin-left: 10px;
    font-size: 12px;
    text-align: left;
    font-weight: normal;
    margin-bottom: 0px;
}
.sector-banking .heading span{
    border:1px solid #4b4e536e; 
    padding: 6px 5px;
    text-transform: uppercase;
    font-size: 11px;
}
.valign{
    padding-top: 5px;
}
.shiftDown{
    margin-top: 15px !important;
    height: calc(100% - 94px) !important;
}

.dynamicheight{
    height: calc(100% - 50px);
    background-color: #262A30 !important;
}


.dynamicheightscroll{
    height: calc(100% - 115px);
    overflow: auto;
    background-color: #262A30 !important;
}

.portfoliotabs .tabbar-content-holder{
    border: 0px !important;
    padding: 0px !important;
    overflow:hidden !important;
}
.portfoliotabs .widget-title{
    display: none;
}
.portfoliotabs .hdivider{
    display: none;
}
.portfoliotabs .tabbar-content-body{
    height: auto;
}
.portfoliotabs .tab-bar li{
    margin-right: 0px;
    margin-top: 8px;
}
.portfoliotabs .tab-bar.highlight-above > .active > a{
    border-top: 0px !important;
}
.portfoliotabs .highlight-above{
    float: right;
    text-transform: uppercase;
}
.DropdownPortfolio .fa-angle-down{
    transform:rotate(90deg) !important;
}
.DropdownPortfolio .fa-angle-down:before{
    content: "\f141" !important;
}
.DropdownPortfolio .gainIndicesDrop .dropdown-menu{
    left:-72px !important;
}
/*------------trade-history---------*/
.trade-history .modal-header{
    text-align: center;
    background: #232528;
}
.trade-history .modal-header h5{
    font-size: 12px;
}
.trade-history .modal-dialog{
    max-width: 650px !important;
}
.small-heading{
    color: #AAAAAA !important;
    font-size: 10px !important;
    margin:0px!important;
}
.sector-data{
    line-height: 35px;
    /* border-bottom:1px solid #4b4e53; */
}
.shiftDown.bottomchange{
    height: calc(100% - 108px)!important
}
.bottomchange .data-total{
    bottom: 50px;
}
.portfoliotabs .script-sector-button{
    position: absolute;
    background: transparent;
    top: 8px;
    left: 72px;
    padding: 0px 20px;
    z-index: 12;
    height: 27px;
}
/* .portfoliotabs .script-sector-button:focus{
    background-color: #262A30 !important;
    color: #1EBF8A;
} */
#sectorButton{
    left: 146px !important;
}


.portfoliotracker-tablecontent{
     overflow-y: scroll !important; 
     /* height: 260px;  */
     /* height:calc(100% - 150px); */
     
} 

.tabnomargin
{
    margin: 0px 0 0 0!important;
    padding:0px!important;
}

.header-row{
    
    background: #131313 !important;
    padding: 8px 5px !important;
}
.widget-title.portfit-heading{
    padding: 5px 10px !important; 
}


/*--------------------------mytradingplan------------------*/
.mytradingplan{
    /* top:96px; */
    z-index: 1;
    text-align: center;
    font-size: 14px;
    overflow-y: auto;
    height: 90%;
}

.mytradingplan.fullPageTradingPlan
{
    height: 100%;
}
.mytradingplan.trade-history .modal-dialog{
    max-width: 735px !important;
    
}
.mytradingplan.trade-history .modal-body{
    max-height: 410px !important;
    overflow: scroll;
}
.mytradingplan .Planeheading{
    font-size: 20px;
    /* font-weight: bold; */
}
.mytradingplan .trading-planoption{
    padding-left: 0px;
    /* text-align: left; */
}
.mytradingplan .trading-planoption li{
    display: inline-block;
    padding-right: 50px;
    font-size: 12px;
    position: relative;
    padding-left: 15px;
    text-align: left;
}
.optIn-class{
    background: #4b4e53;
    padding: 12px;
    border-radius: 8px;
}
.mytradingplan .trading-planoption li::before{
    content: '';
    position: absolute;
    background: #1EBF8A;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    left: 0;
    top: 3px;
}
.trade-overlay{
    background-color: #0000006e;
}
.trading-plans{
    text-align: left;
    margin: .5rem 0px;
}
.trading-plans p{
    margin-bottom: 0px;
}
.plans-border{
    border-radius: 50% 50% 50% 50% / 12% 12% 12% 12%;
    width:0px;
    height: 64px;
    border:8px solid #dee2e6;
}
/* .plans-padding{
    padding: 10px 15px;
} */
.term-condition-text span{
    color: #1EBF8A;
    cursor: pointer;
}
.trading-lineH{
    line-height: 32px;
}
.continue-button{
    margin-top: 0px;
    margin-bottom: 20px;
    background: #1B9771;
    border: 0px;
    border-radius: 25px;
    padding: 10px 90px;
    text-transform: uppercase;
}
.continue-button:focus, .continue-button:hover{
    outline: 0 none;
    /* border: 1px solid #1EBF8A; */
    background: #1B9771;
    border-radius: 25px;
}
/*-equity-plans-*/
.equity-plans .plans-box{
    padding: 0px;
}
.equity-plans .plans-box .trading-planoption li{
    position: relative;
    padding-right: 12px;
}
/* .equity-plans .plans-box .trading-planoption li::before{
  content: '';
  position: absolute;
} */
.Equity-plandata{
    padding-right: 0px;
    margin: 10px 0px;
}
.Equity-plandata .plans-padding{
    padding: 5px;
}

.plans-padding input[type=radio] + label, .plans-padding input[type=checkbox] + label {
    /* display: block; */
    margin: 0.2em;
    cursor: pointer;
    padding: 0.2em;
  }
  
.plans-padding input[type=radio], .plans-padding input[type=checkbox] {
    display: none;
  }
  
.plans-padding input[type=radio] + label:before, .plans-padding input[type=checkbox] + label:before {
    content: "\2714";
    border: 2px solid #4b4e53;
    border-radius: 0.2em;
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    padding-left: 0.2em;
    padding-bottom: 0.3em;
    margin-right: 0.2em;
    vertical-align: bottom;
    color: transparent;
    transition: .2s;
  }
  
.plans-padding input[type=radio] + label:active:before, .plans-padding input[type=checkbox] + label:active:before {
    transform: scale(0);
  }
  
.plans-padding input[type=radio]:checked + label:before, .plans-padding input[type=checkbox]:checked + label:before {
    background-color: #1EBF8A;
    border-color: #1EBF8A;
    color: #fff;
  }
  /*---total charges----*/
  .total-plancharges .hdivider{
    border-bottom: 1px solid #9a9898;
    margin-bottom: 0px;
  }
  /*------review-confirm-----*/
.review-confirm{
    position: absolute;
    /* top: 100px; */
    top:125px;
    width: 99.1%;
    height: 74%;
    /* background: #4b4e53b8; */
    background: rgba(40, 44, 50, 0.65);
    left: 5px;
}

.fullPageTradingPlan .review-confirm {
    position: absolute;
    top: 0px;
    height: 100%;
    background: rgba(40, 44, 50, 0.65);
    left: 5px;
}
.review-confirm .review-heading{
    font-size: 20px;
  }
.review-confirm .review-optuions{
    font-size: 14px;
    text-align: center;
  }
.review-confirm .review-optuions li{
    display: inline-block;
    padding: 20px 60px;
    position: relative;
}
.review-confirm .review-optuions li:nth-child(1)::after{
    content: '';
    height: 61px;
    width: 2px;
    background: #5a5a5a;
    top: 20px;
    position: absolute;
    right: 8px;
}
.review-text{
    position: absolute;
    bottom: 0px;
    width: 100%;
    background: #272b31;
    padding: 5px 0px;
}
.unlimitiedTrading {
    padding-right: 0px !important;
    width: 24%;
}
.unlimitiedTrading img{
    width: 25px;
    float: left;
    margin-right: 20px;
}
.unlimitiedTrading::before{
    content:'';
    background: transparent !important;
}
.unsubClose{
    text-align: right;
    width: 100%;
    padding-right: 63px;
    font-size: 20px;
}

.button-back-confirm span{
    /* color: #1EBF8A;
    cursor: pointer;
    text-decoration: underline;
    font-size: 16px;
    text-transform: uppercase; */
    color: #fff;
    cursor: pointer;
    /* text-decoration: underline; */
    font-size: 12px;
    text-transform: uppercase;
    background: #1EBF8A;
    padding: 10px;
    border-radius: 50px;
    margin-left: 34px;
    text-align: center;
}
.confirm-message li{
    display: inline-block;
    padding: 6px 14px;
    position: relative;
    padding-left: 15px;
}
.confirm-message li::before{
    content: '';
    position: absolute;
    background: #1EBF8A;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    left: 0;
    top: 12px;
}
.confirm-options{
    font-size: 14px;
    text-align: center;
}
.auto-renwal-plan{
    padding-top: 15px;
}
.font-size{
    font-size: 18px;
    font-weight: bold;
}
.plans-border.colorChange{
border:8px solid #fca908
}

.fa-check-square{
    color: #1EBF8A;
    font-size: 20px;
}

.fa-check-circle{
    color: #1EBF8A;
    font-size: 28px;
}
.fa-times-circle-o{
    color: #E5283F;
    font-size: 28px;
}
.info-trading-popup{
    position: absolute;
    width: 18%;
    left: 56%;
    background: #2f2f2f;
    top: -46%;
    padding: 9px 9px 4px;
    border-radius: 10px;
    font-size: 12px;
    display: none;
}
.disable-button{
    margin-bottom: 20px;
    background: #b5b5b5;
    border: 0px;
    border-radius: 25px;
    padding: 10px 90px;
    text-transform: uppercase;
    color: #000;
    cursor: pointer;
}
.info-circel{
    display: inline-block;
    cursor: pointer;
    font-size: 18px;
}
.tradingclosebutton{
    color:#1EBF8A;
    cursor:pointer;
}

.totalChargeMsg{
    background: #272b31;
    padding: 10px;
}

.fundTransferNote{
    text-decoration: underline;
    font-weight: bold    ;
    line-height: 40px;

}
.funds-note1{
padding-left: 10px !important
}

.settingsTogglebutton .toggle-switch .toggle-button.active{
    background:#1EBF8A;
}
.settingsTogglebutton .toggle-switch .toggle-button{
    padding:0px 3px;
    font-size: 10px
}

.lessPadding .dropdown>.dropdown-menu>li>a{
    padding: 7px 7px 7px 3px;
}
.fitHeight{
    max-height: 350px !important
}

.termsCondWithDraw{
    position: absolute;
    width: 50%;
    margin: auto;
    left: 50%;
    margin-left: -25%;
    top:25%;
    height:50%;
}

.termsCondPropsBased{
    margin-left: 0px !important;
    margin-right: 0px !important;  
    width: auto !important;
}

.ipoRow{
    border: 1px solid #3a3a3a;
    padding: 5px;
    margin: 10px 0px 0px 0px;
    width: 100%;
    color: #fff;
    cursor: pointer;
    white-space: pre;
}

.bidButton{
    background: #1B9771;
    border: 0px;
    border-radius: 25px;
    text-transform: uppercase;
    font-weight: bold;
    padding: 2px 25px;
}

.bidButton:focus{
    box-shadow: 0 0 5px rgba(81, 203, 238, 1);
}

.browser-width .fixwidth{
    max-width:25%;
}

.zeroPadding{
    padding: 0px;
}
.addMargin{
    margin:10px;
}

.browser-width1 .fixwidth:nth-child(even){
    text-align: right!important
}

.browser-width1 .dontShow{
    max-width:16.666667%;
}

.browser-width .setColor{
    color:#AAAAAA;
}

.browser-width1 .setColor{
    color:#AAAAAA;
}

.browser-width .inputNumberBackground{
    background: #131314;
    border: 1px solid #131314;
    border-bottom: 1px solid #fff;
    text-align: center;
}

.inputNumberBackground:focus {
    border: none !important;
    border-bottom: 1px solid #42454A !important;
} 
.ipoScroll{
    overflow: auto;
    height: calc(100% - 50px);
}

.upiId{
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background: #42454A;
    border: 1px solid #495057;
    border-radius: 0rem;
}

.colTitle{
    display: inline-block;
    vertical-align: middle;
    line-height: 45px;
    font-weight: bold;
    font-size: 14px;
}

.ipo-header{
    color: #fff;
    font-size: 12px;
    font-weight: bold;
}

.ipo-row{
    margin: 10px 0px;
    cursor: pointer;
}

.ipo-order-height{    
    height: calc(100% - 85px);

}

.ipo-row strong{
    font-size:12px;
    text-transform: uppercase;
    color:#fff;
}

.ipo-accordion{
    border-radius: 0!important;
    background: #262a30!important;
    height: 80px;
}

.marginTop{
    margin-top: 8px
}
.middleAlign{
    display: inline-block;
    vertical-align: middle;
    line-height: 45px;
}
.bottom-note-new{
    position: absolute;
    bottom: 65px;
    background: #262a30;
    width: calc(100% - 32px);
    font-size: 11px;
    padding: 0px 10px;
}
.warning-pnl{
position: absolute
}
/* 
.ipo-row .row .col-2{
    font-size:11px;
} */