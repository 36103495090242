.news-type{
    font-size: 12px;
    font-family: 'Roboto-Light';
}

.news-type.small{
    margin-left: 0px !important;
}
.news-type.small:not(:last-child){
    margin-right: 30px;
}

.news-type{
    margin-left: 35px;
}
.news-type:before{
    content: '';
    position: absolute;
    width: 6px;
    height: 6px;
    opacity: 1 !important;
    background: #EEEEEE;
    border-radius: 100%;
    outline: none;
    margin-left: -12px;
    margin-top: 7px;
}

.news-type.red:before{
    background: #E5283F;
}

.news-type.green:before{
    background: #1EBF8A;
}

.corporatenews-date {
    margin-left: 30px;
}