/* bootstrap toggle icon disbaled */
.dropdown-toggle::after {
  display: none;
}
/* ==== */

/* carousal indicators round style */
.carousel-indicators li {
  border-radius: 10px;
  max-width: 10px;
  height: 10px;
}

html {
  height: 100%;
  /* min-width: 1200px; */
}

.browser-width {
  min-width: 1200px;
}
.atom-width {
  min-width: 100% !important;
}

/* @media screen and (max-width: 1200px)
{
  #root{
    width: 1200px !important;
    max-width: none !important;
  }
  .container{
    width: 1200px !important;
    max-width: none !important;
  }

  #pageHeader{
    width: 1200px;
  }

  .navbar.navbar-custom{
    width: 1200px;
  }
} */

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto" !important;
  color: #d9d9d9;
  background: #050a11;
  width: 100%;
  /* min-width: 1200px; */
  height: 100%;
  font-size: 12px;
  /* overflow: hidden; */
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-face-color: #09ad7e;
  scrollbar-arrow-color: #202020;
  scrollbar-track-color: transparent;
  scrollbar-shadow-color: #09ad7e;
  scrollbar-highlight-color: transparent;
  scrollbar-3dlight-color: #808080;
  scrollbar-darkshadow-color: #202020;

  -moz-user-select: none;
  -khtml-user-select: none;
  user-select: none;
}
.row {
  margin-left: 0px;
  margin-right: 0px;
}
.table tbody + tbody {
  border-top: none;
}

.table thead th {
  border-bottom: 1px solid #3a3a3a;
}
.table td,
.table th {
  border-top: 1px solid #3a3a3a;
}
.fade:not(.show) {
  opacity: inherit;
}
.navbar {
  padding: 0;
}

.customRow {
  /* display: flex; */
  width: 100%;
}
.allcontentholder {
  height: 100%;
}

.appcontent {
  height: calc(100% - 150px);
  overflow-x: hidden;
  overflow-y: auto;
  /* padding: 15px 15px; */
  padding: 5px;
  padding-bottom: 15px;
}

.fillheight {
  height: 100%;
}

.fillwidth {
  width: 100%;
}

/* ===================================================== */
/* font awsome */

.fa-times-thin:before {
  content: "\00d7";
}

.fa-2x {
  font-size: 1em;
  color: #eeeeee;
  margin-left: 5px;
}

/* ===================================================== */
/* scrollbar */

#test1:before {
  content: "";
  border-left: 10px;
}

/* ===================================================== */
/* progress */

.progress_block_background {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1050;
}

.progress_block_background.lessopaque {
  background: rgba(0, 0, 0, 0.2) !important;
}

.error-holder {
  text-align: center;
  color: rgb(224, 210, 210);
  font-size: 16px;
  font-weight: lighter;
  padding-top: 5px;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
}

.subh-loader {
  position: relative;
  width: 40px;
  height: 40px;
}

.circular-progress {
  position: relative;
  width: 40px;
  height: 40px;
  animation: rotate 2s linear infinite;
}

.circular-progress.tiny {
  width: 15px !important;
  height: 15px !important;
}

.circular-progress_path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  stroke-linecap: round;
  stroke: #09ad7e;
}

/* ===================================================== */
/* toggle switch */

.toggle-button {
  font-size: 14px;
  color: #62666f;
  background: #eee;
  border: 0px;
  padding: 0px 6px;
  box-shadow: none;
  outline: none;
}

.toggle-button.active {
  color: #eee;
  background: #176dbe;
  padding: 0px 6px;
}

.toggle-border {
  overflow: hidden;
  border-radius: 4px;
}

.allcenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.nopadding {
  padding: 0 !important;
  margin: 0 !important;
}

/* ===================================================== */
/* dropdown */

.dropdown {
  margin: 0px !important;
}

.dropdown > .btn {
  padding-right: 0px !important;
}

.drop-down {
  background: transparent !important;
  border: 0px;
  color: #eeeeee;
  border-bottom: none;
  border-radius: 0px;
  padding-left: 0px;
  width: 100%;
  text-align: left;
  box-shadow: none !important;
  line-height: 14px !important;
}

.dropdown-grayout {
  color: rgba(170, 170, 170, 0.37);
}

.dropdown > .dropdown-menu {
  background: #383b42;
  border-radius: 0px;
  min-width: 0px;
  padding: 0px;
  /* max-height: 300px;
  overflow-y: auto;
  overflow-x : */
}
/* 
ul.dropdown-menu.calc {
  width: 150px;
} */

.dropdown-submenu-li:last-child {
  border-bottom: none;
}

.dropdown-menu {
  color: #eeeeee !important;
}

.dropdown-submenu-li {
  padding: 8px;
}
.dropdown > .dropdown-menu > li > a {
  color: #eeeeee;
  background: #383b42;
  padding-left: 10px;
  cursor: pointer;
  font-size: 13px;
  padding: 7px;
}

/* Change hover a ti li */
.dropdown > .dropdown-menu > li:hover {
  background: #42464f;
}

#drop-down {
  outline: none !important;
  color: #eeeeee;
  font-size: 12px;
}

.dropdown .arrow-icon {
  position: relative;
  float: right;
  margin-left: 15px;
  font-size: 18px;
  line-height: 14px;
  pointer-events: none;
  color: #eeeeee;
  transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  transition: transform 300ms ease;
  -moz-transition: -moz-transform 300ms ease;
  -ms-transition: -ms-transform 300ms ease;
  -o-transition: -o-transform 300ms ease;
  -webkit-transition: -webkit-transform 300ms ease;
}

.dropdown.show .arrow-icon {
  transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transition: transform 300ms ease;
  -moz-transition: -moz-transform 300ms ease;
  -ms-transition: -ms-transform 300ms ease;
  -o-transition: -o-transform 300ms ease;
  -webkit-transition: -webkit-transform 300ms ease;
}

.common-dropdown-divider {
  background: #636363 !important;
  margin: 0px !important;
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu > .dropdown-menu {
  top: -2px !important;
  left: 100%;
  border-radius: 0px !important;
  background: #42454a;
  padding: 0px !important;
  outline: none !important;
  border: none !important;
  margin-left: 1.7%;
}

.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}

.dropdown-subMenu {
  color: #eeeeee;
  background-color: transparent;
  /* width: 187px;  */
}

.dropdown-submenu > .dropdown-menu > li > a {
  color: #eeeeee;
  /* padding: 40px; */
  background-color: transparent;
}

/* .dropdown-submenu>.dropdown-menu>li>a:hover, */
.dropdown-submenu > .dropdown-menu > li > a:focus {
  /* background:#565A63;  */
  color: #eeeeee;
  cursor: pointer;
}

.dropdown-submenu > a:after {
  display: block;
  content: " ";
  float: right;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-left-color: #ccc;
  margin-top: 5px;
  margin-right: 0px;
}

.dropdown-submenu:hover > a:after {
  border-left-color: #fff;
}

.dropdown-submenu.pull-left {
  float: none;
}

.dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
  -webkit-border-radius: 6px 0 6px 6px;
  -moz-border-radius: 6px 0 6px 6px;
  border-radius: 6px 0 6px 6px;
}
/* ===================================================== */
/*custom popover */

.popover {
  z-index: 100 !important;
}

.custom-popover {
  background: #42454a;
  word-wrap: break-word;
}

.popup-green {
  border-radius: 0px;
  border: 1px solid #1ebf8a;
  background: #42454a;
}

.popup-green.bottom > .arrow:after {
  border-bottom-color: #2c323a;
}

.popup-green.top > .arrow:after {
  border-top-color: #2c323a;
}

.popup-green.left > .arrow:after {
  border-left-color: #2c323a;
}

.popup-green.right > .arrow:after {
  border-right-color: #2c323a;
}

.popup-green.bottom > .arrow {
  border-bottom-color: #1ebf8a;
}

.popup-green.top > .arrow {
  border-top-color: #1ebf8a;
}

.popup-green.left > .arrow {
  border-left-color: #1ebf8a;
}

.popup-green.right > .arrow {
  border-right-color: #1ebf8a;
}

.status-green {
  color: #1ebf8a;
  font-weight: bold;
}

.description-header {
  margin: 0 0 6px 0;
  display: flex;
  justify-content: space-between;
}

.description-clock {
  margin-right: 10px;
}

.description-text {
  margin-top: 15px;
}

/* ===================================================== */
/*tab bar */

.tab-bar {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: transparent;
  border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  list-style: none !important;
}

.tab-bar.justify {
  justify-content: space-between;
  border-bottom: 2px solid #3a3a3a;
}

.tab-bar.equal {
  width: 100%;
  border-bottom: 2px solid #3a3a3a;
}

.tab-bar.equal li {
  width: 20%;
}

.tab-bar.equal li a {
  display: inline-block;
  color: #eeeeee;
}

.tab-bar.equal li:nth-child(5) {
  text-align: right;
}

.tab-bar::before,
.tab-bar::after {
  content: none;
}

.tab-bar li {
  margin-right: 40px;
}

.tab-bar li a {
  padding: 7px 0px 7px 0px;
  color: #eeeeee;
  background-color: transparent !important;
  line-height: 13px;
  font-size: 12px;
  cursor: pointer;
}

.tab-bar.highlight-above li a {
  padding-left: 20px;
  padding-right: 20px;
}

.tab-bar > .active > a {
  font-size: 12px;
  font-family: "Roboto-Medium";
}

.tab-bar.highlight-below > li.active > a {
  /* text-decoration: none;
  position: absolute;
  content: '';
  border-radius:0px;  
  
  bottom: 0px;
  transform: translateX(-50%);
  left: 50%; */
  border-bottom: 2px solid #1ebf8a !important;
  bottom: 0px;
}

.tab-bar.highlight-above > .active > a {
  border-top: 2px solid #1ebf8a !important;
  background-color: #262a30 !important;
  color: #1ebf8a;
}

/* ===================================================== */
/* button Bar */
.btn-parent {
  display: flex !important;
  flex-wrap: wrap;
  border: 1px solid #eeeeee;
  padding: 1px !important;
}

.btn-btnbar {
  flex: 1;
  background: transparent;
  border: 1px solid rgb(133, 133, 133);
  border-radius: none;
  font-size: 12px;
  box-shadow: none !important;
  border-radius: 0px;
  color: #eeeeee;
}

.btn-btnbar.btn-default.active {
  outline: none !important;
  background: transparent;
  color: #eeeeee !important;
  border: 1px solid #1ebf8a !important;
  border-radius: 0px;
  font-family: "Roboto-Medium";
}

.btn-btnbar.btn-default,
.btn-btnbar.btn-default:hover {
  border: 1px solid transparent;
  background: transparent;
  color: #eeeeee;
  outline: none;
  padding: 2px 0px;
}

.btn-btnbar.btn.btn-default.active {
  outline: none;
  background-color: rgb(19, 23, 48);
}

/* ===================================================== */
/* Dialog */

.appdialogmodal {
  /* z-index: 1040 !important; */
  top: 20% !important;
  font-size: 12px;
  height: auto !important;
}

.dialog-show {
  display: block;
  opacity: 1;
}

.dialog-show > .modal-dialog > .modal-content {
  background: #42454a !important;
}

.dialog-hide {
  display: none;
}

/* ===================================================== */
/* popups */

.disabledbackground {
  background-color: rgba(0, 0, 0, 0.678);
}

.disabledbackground:before {
}

.modal-dialog {
  background-color: #42454a;
  border-radius: 0 !important;
  overflow-y: initial !important;
  z-index: 99 !important;
}

.modal-dialog.progress {
  background: #000;
}

.modal-dialog.small {
  width: 400px;
  height: 200px;
  top: 20%;
}

.modal-dialog.medium {
  width: 700px;
  height: calc(90% - 90px) !important;
  top: 10%;
  overflow-y: auto;
}

.modal-dialog.small-medium {
  width: 500px;
  min-height: 400px;
  top: 10%;
  overflow-y: auto;
}

.modal-dialog.big {
  width: 1050px;
  height: calc(90% - 90px) !important;
  height: auto;
  top: 10%;
}

.modal-dialog.agri {
  width: 700px;
  height: calc(90% - 90px) !important;
  top: 5%;
  border: 1px solid #3a3a3a;
}

.modal-body-agri {
  height: calc(100% - 110px);
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0px;
}

.modal-body {
  max-height: 600px !important;
  overflow-y: auto;
  overflow-x: hidden;
}

.modal-body-content {
  text-align: center;
  margin-top: 25px;
  color: #fff;
  font-size: 14px;
}

.modal-body.edit {
  height: calc(100% - 110px) !important;
  overflow-y: auto;
}

.modal-dialog.big.delete {
  width: 900px;
  height: auto !important;
  max-height: 400px;
  height: auto;
  top: 20%;
  max-width: 100%;
}

.modal-body.delete {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-body .deletewatchlist {
  position: absolute;
}

.modal-body-textbox {
  background-color: transparent;
  border: none;
  margin-left: 10%;
  margin-top: 18px;
  width: 80%;
  height: 30px;
  border-radius: 0 !important;
  border-bottom: 2px solid rgb(192, 186, 186);
  text-align: center;
  font-size: 14px;
}

.modal-footer {
  border-top: 1px solid rgb(139, 134, 134);
}

.appdialogmodal .modal-footer {
  border-top: 1px solid rgb(80, 80, 80);
}

.modal-header {
  border-bottom: 1px solid rgb(80, 80, 80);
  font-weight: lighter !important;
  font-size: 14px;
  padding: 10px 10px 5px;
  vertical-align: bottom;
  display: block;
}
.modal-footer {
  border-top: 1px solid rgb(80, 80, 80);
  font-weight: lighter !important;
  font-size: 14px;
  /* padding: 10px 10px 5px; */
  /* vertical-align: bottom; */
  height: 50px;
}

/* ::placeholder{
  font-size: 14px;
  text-align: center;
  color:white;
  opacity: 1;
}
:-ms-input-placeholder{
  font-size: 14px;
  text-align: center;
  color:white;
}
::-ms-input-placeholder{
  font-size: 14px;
  text-align: center;
  color:white;
} */
.modal-header-textinput {
  background: transparent !important;
  border: none;
  color: white;
  border-radius: 0 !important;
  border-bottom: 2px solid rgb(192, 186, 186);
}

.modal-header .editwatchlistnameholder {
  width: 100%;
  border-radius: 0 !important;
  border-bottom: 2px solid rgb(192, 186, 186);
}

.closebtn {
  position: relative;
  float: right;
  cursor: pointer;
  width: 10px;
  height: 10px;
  margin-top: 8px;
}

.modal-footer .note {
  float: left;
}

.modal-footer .footerbtn {
  background-color: #737478 !important;
  background: rgba(0, 0, 0, 0.2);
  border: none;
  color: #eeeeee;
  font-size: 14px;
  width: 85%;
  padding: 5px;
}

.modal-footer .commonbtn {
  background-color: #737478 !important;
  background: rgba(0, 0, 0, 0.2);
  border: none;
  color: #eeeeee;
  font-size: 14px;
  padding: 7px 5px;
  min-width: 85px;
  border-radius: 0px;
  margin-left: 20px !important;
  text-transform: uppercase;
}

.modal-footer .footerbtn:active,
.modal-footer .footerbtn:focus {
  outline: 0 none;
  border: 2px solid #1ebf8a !important;
}

.editwatchlist-header {
  float: right;
  position: relative;
}

/* ===================================================== */
/* Search Input */
.form-control:focus {
  background-color: transparent;
  color: #ffffff;
}
.search-input {
  font-size: 14px;
  box-sizing: border-box !important;
  color: #eeeeee;
  padding-right: 20px !important;
  padding-left: 5px !important;
  background: transparent;
  border-radius: 0;
  outline: none;
  border: none;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  border-bottom: 1px solid rgb(197, 194, 194) !important;
  outline-color: transparent;
  outline-style: none;
  margin: 0px !important;
}

input[type="search"]:focus {
  outline: 0 none;
  border-color: #eeeeee !important;
}

input[type="search"]:hover {
  background: none;
}

.search-input::placeholder {
  color: rgb(197, 194, 194);
  text-align: left;
}

.search-input::-ms-clear,
.input-plus-minus .input-number::-ms-clear {
  display: none;
}

/* input[placeholder] { text-overflow: ellipsis; } */

.search-right-icon {
  position: absolute;
  right: 0px;
  top: 0;
  margin: 0px 5px 0px 10px;
  pointer-events: auto;
  color: #1ebf8a;
  z-index: 80;
  cursor: pointer;
  font-style: normal;
  width: 30px;
}

.search-right-icon.close-icon {
  top: 10px;
  right: 5px;
  width: 14px;
}

/* ===================================================== */
/* scroll bar */

::-webkit-scrollbar {
  width: 3px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: none;
  border-radius: 3px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1ebf8a;
  border-radius: 3px;
}
::-moz-scrollbar-thumb {
  background: #1ebf8a;
  border-radius: 3px;
}

/* ===================================================== */
.sell-btn {
  background-color: #e5283f;
}

.buy-btn {
  background-color: rgb(22, 109, 190);
}

.btn-blue {
  border: none;
  border-radius: 0;
  font-size: 11px;
  background: #1ebf8a;
  color: #eeeeee;
  outline: none;
  padding: 5px 10px;
}

/* ===================================================== */
/* Check box */

.custom-check .custom-check-label {
  font-size: 14px;
  padding-left: 15px;
  padding-top: 3px;
}

.custom-check input[type="checkbox"]:checked ~ .custom-check-label {
  color: #eeeeee;
}

.custom-check input.custom-checkbox:checked::before,
.custom-check input.custom-checkbox:not(:checked):before {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  border: 1px solid rgb(146, 147, 148);
  cursor: pointer;
  opacity: 1 !important;
  background: #383b42;
  outline: none;
}

.custom-check input.custom-checkbox:checked:after,
.custom-check input.custom-checkbox:not(:checked):after {
  font-family: FontAwesome;
  content: "\f00c";
  position: absolute;
  width: 15px;
  height: 15px;
  font-size: 12px;
  padding: 3px 2px;
  line-height: 0.8;
  color: #09ad7e;
  transition: all 0.2s;
  background: #eeeeee;
  cursor: pointer;
  outline: none;
}

.custom-check input.custom-checkbox:not(:checked):after {
  opacity: 0;
  transform: scale(0);
}
.custom-check input.custom-checkbox:checked:after {
  opacity: 1;
  transform: scale(1);
}

.custom-check [type="checkbox"]:focus,
.custom-check [type="checkbox"] {
  margin: 0px !important;
  border: none;
  outline: none;
}

.custom-checkbox:disabled {
  opacity: 0.3;
}

.custom-check-label.disabled {
  opacity: 0.3;
}

/* ===================================================== */
/* Custom Popover */

.popover-parent .popover {
  background: #42454a;
  border-radius: 0px;
}

.popover-parent .arrow:after {
  border-bottom-color: #42454a !important;
}

/* ===================================================== */
/* Input Plus Minus */

.input-plus-minus {
  border-radius: 0px;
  border: 1px solid #1ebf8a;
  background: #292a2e;
  box-shadow: none;
}

.input-plus-minus-error {
  border: 1px solid #e5283f;
}

.input-plus-minus input {
  border: none;
  outline: none;
  box-shadow: none !important;
  background: transparent;
  text-align: center;
  color: #eeeeee;
}

.input-plus-minus .input-group-btn .btn {
  background: #42454a !important;
  border-radius: 0px;
  border: solid #42454a;
  outline: none;
}

.input-plus-minus .input-group-btn .btn .minus {
  color: #e5283f;
}

.input-plus-minus .input-group-btn .btn .plus {
  color: #1ebf8a;
}

body .dialog-show body {
  -webkit-filter: blur(4px);
  -moz-filter: blur(4px);
  -o-filter: blur(4px);
  -ms-filter: blur(4px);
  filter: blur(4px);

  filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius='4');
}

/* ===================================================== */
/* Datepicker */
#popover-date {
  max-width: 450px !important;
  background: #2c323a;
  padding: 0px !important;
  z-index: 2000 !important;
}

#popover-date .popover-content {
  padding: 0px !important;
  background: #2c323a;
}

.custom-datepicker .datepicker-switch:hover {
  background: transparent;
}

.custom-datepicker .datepicker table tr td span {
  background: transparent;
  color: #eeeeee;
}

.custom-datepicker .datepicker-inline {
  width: auto !important;
  font-family: "Roboto";
  padding: 0px;
}
.custom-datepicker table thead tr th {
  border: none;
  border-radius: 0px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.custom-datepicker table thead tr th:first-child {
  padding-left: 6px;
}

.custom-datepicker table thead tr th:last-child {
  padding-right: 6px;
}

.custom-datepicker table thead tr:nth-child(2) {
  background: #232428;
  padding: 20px !important;
}

.custom-datepicker table tr td.today {
  border-radius: 40px;
}

.datepicker table tr td:hover {
  border-radius: 40px !important;
  color: #262a30;
}

.custom-datepicker table tr td.today.active,
.datepicker table tr td.active {
  background: #09ad7e !important;
  border-radius: 40px;
}

.custom-datepicker .datepicker-days table td {
  width: 40px !important;
  height: 40px;
}

.date-input-default {
  box-sizing: border-box !important;
  color: #eeeeee;
  padding-right: 20px !important;
  padding-left: 5px !important;
  background: transparent;
  border-radius: 0;
  outline: none;
  border: none;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  border-bottom: 1px solid rgb(197, 194, 194) !important;
  outline-color: transparent;
  outline-style: none;
  margin: 0px !important;
}
.date-input-visible {
  display: none !important;
}

.date-icon-default {
  width: auto;
  padding: 0px;
  background: transparent;
  color: #aaaaaa;
  border: none;
  padding-left: 20px;
}
.date-holder-parent {
  display: flex;
  align-items: center;
}
.date-holder {
  color: #eee;
  background: #42454a;
  text-align: center;
  padding: 3px 5px;
  margin: 0px 5px 0px 5px;
}

.date-footer {
  text-align: right;
  padding: 10px 10px 10px 0;
}

.date-btn {
  background: transparent;
  padding-right: 15px;
  padding-left: 15px;
  padding-top: 7px;
  padding-bottom: 7px;
  outline: none;
  color: #09ad7e;
  font-family: "Roboto-Medium";
  box-shadow: none;
  border: none;
}

.date-btn.cancel {
  color: #eeeeee;
}

/* ===================================================== */
/* InputPlusMinus */

.input-number {
}

.input-number.grey {
  background-color: #42454a;
  border-radius: 0;
  margin-top: 5px;
  border: none;
  color: #eee;
}

.input-number:disabled {
  background-color: transparent;
}

/* ===================================================== */
/* TABBAR BASE WIDGET */

.tabbar-content-holder {
  border: 1px solid #3a3a3a;
  padding: 0px 10px 10px;
  background: #131314;
  overflow-y: hidden;
}

.tabbar-content-body {
  height: calc(100% - 38px);
  overflow-y: hidden;
  overflow-x: hidden;
  padding: 15px 0px 0px 0px;
}

.tab-content-parent {
  /* background-color: rgb(33, 38, 44); */
  padding: 0px !important;
  height: calc(100% - 40px);
}

.tabbar-expand {
  float: right !important;
  margin-left: 10px;
}

.float-right {
  float: right;
}

.symbol-link {
}

.symbol-link:hover {
  text-decoration: underline;
  cursor: pointer;
}

.span-wrap {
  word-wrap: break-word;
}

.cursor-pointer {
  cursor: pointer;
}

/* Add this attribute to the element that needs a tooltip */
[data-tooltip] {
  position: relative;
  z-index: 2;

  white-space: pre-wrap;
  line-height: 10px;
  font-size: 12px;
}

/* Hide the tooltip content by default */
[data-tooltip]:before,
[data-tooltip]:after {
  visibility: hidden;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  pointer-events: none;
}

/* Position tooltip above the element */
[data-tooltip]:before {
  position: absolute;
  bottom: 150%;
  left: 50%;
  margin-bottom: 5px;
  margin-left: -80px;
  padding: 7px;
  width: 160px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background-color: #000;
  background-color: hsla(0, 0%, 20%, 0.9);
  color: #fff;
  content: attr(data-tooltip);
  text-align: center;
  font-size: 14px;
  line-height: 200%;
}

/* Triangle hack to make tooltip look like a speech bubble */
[data-tooltip]:after {
  position: absolute;
  bottom: 150%;
  left: 50%;
  margin-left: -5px;
  width: 0;
  border-top: 5px solid #000;
  border-top: 5px solid hsla(0, 0%, 20%, 0.9);
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  content: " ";
  font-size: 0;
  line-height: 0;
}

/* Show tooltip content on hover */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  visibility: visible;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

.sd-tooltip {
  font-style: normal !important;
  cursor: default !important;
}

/* Bottom */
.tooltip-bottom:before,
.tooltip-bottom:after {
  top: 100%;
  bottom: auto;
  left: 50%;
}

.tooltip-bottom:before {
  margin-top: 0px;
  margin-bottom: 0;
  border-top-color: transparent;
  border-bottom-color: #000;
  border-bottom-color: hsla(0, 0%, 20%, 0.9);
}

.tooltip-bottom:hover:before,
.tooltip-bottom:hover:after,
.tooltip-bottom:focus:before,
.tooltip-bottom:focus:after {
  -webkit-transform: translateY(12px);
  -moz-transform: translateY(12px);
  transform: translateY(12px);
}

.tooltip-inner {
  white-space: pre-wrap;
}

.toupper {
  text-transform: uppercase;
}

/*---Header Row---*/

.header-row {
  color: #aaa;
  font-size: 11px;
}
/*body::-moz-scrollbar {
  width: 12px;
  }
  body::-moz-scrollbar-track {
  -moz-box-shadow: inset 0 0 6px #fff;
  background:#000;
  }
  body::-moz-scrollbar-thumb {
  border-radius: 10px;
  -moz-box-shadow: inset 0 0 6px #fff;
  background:#7A252B;
  }
  body::-moz-scrollbar-thumb {
    background: #1EBF8A;
    border-radius: 3px;
}*/

a {
  cursor: pointer;
}

/*------------responsive login page-------------------*/
.browser-width1 {
  min-width: 1200px;
}

@media (max-width: 600px) {
  .browser-width1 {
    min-width: 100%;
    overflow: scroll;
  }
  .login-responsive .pl-poster2 {
    display: none;
  }
  .login-responsive .login_mainholder2 {
    width: 100%;
  }
  .login-responsive .prelogin-footer2 {
    height: auto;
    display: block;
    background: #202022;
  }
  .login-responsive .prelogin-header2 {
    display: block;
  }
  .login-responsive .prelogin-content-row {
    margin-top: 70px;
  }
  .login-responsive .prelogin-header2 {
    display: block;
  }
  .login-responsive .prelogin-header-left {
    display: block;
  }
  .login-responsive .prelogin-logo-holder {
    float: left;
    width: 50%;
  }
  .login-responsive .prelogin-logo-holder img {
    width: 115px;
  }
  .login-responsive .pl-header-item.btnholder {
    float: left;
    margin-left: 45px;
  }
  .upi_footer {
    display: block !important;
  }
}

/* find upi id */
.findupi_id {
  padding: 20px;
  background: #37bf8a;
  color: #fff;
  text-align: left;
}
.findupi_id .header_upi p {
  margin-bottom: 0px;
  font-size: 16px;
}
.findupi_id .header_upi .fa {
  padding-right: 10px;
}
.upi_Steps {
  display: flex;
  height: 7rem;
  /* padding-top: 40px; */
}
.steps_circel {
  margin-right: 3rem;
  position: relative;
  z-index: 1;
}
.first_steps_dot {
  display: block;
  width: 0.7rem;
  height: 0.7rem;
  border-radius: 50%;
  background: #37bf8a;
  color: #000;
  line-height: 0.7rem;
  font-weight: bold;
  -webkit-box-shadow: 0px 2px 9px -2px #000;
  -moz-box-shadow: 0px 2px 9px -2px #000;
  box-shadow: 0px 2px 9px -2px #000;
}
.steps_dot {
  display: block;
  width: 2.2rem;
  height: 2.2rem;
  border-radius: 50%;
  background: #fff;
  color: #000;
  line-height: 2.2rem;
  font-weight: bold;
  text-align: center;
  -webkit-box-shadow: 0px 2px 9px -2px #000;
  -moz-box-shadow: 0px 2px 9px -2px #000;
  box-shadow: 0px 2px 9px -2px #000;
}
.steps_line {
  display: block;
  margin: 0 auto;
  width: 2px;
  height: 5rem;
  background: #cdcdcd;
}
.upi_text {
  padding: 0px;
  width: 100%;
}
.upi_text p {
  text-align: left;
  color: #000;
  font-size: 14px;
  font-weight: bold;
  padding: 0px;
  line-height: 36px;
}
.upi_footer {
  background: #222;
  /* padding: 10px; */
  display: none;
}
.steps_dot .fa {
  font-size: 22px;
  color: #37bf8a;
  line-height: 36px;
}
.steps_dot2 {
  position: absolute;
  top: -6px;
  background: #eaeaea;
  z-index: -1;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  left: -7px;
  border: 3px solid transparent;
  background-size: 100% 100%, 50% 50%, 50% 50%, 50% 50%, 50% 50%;
  background-repeat: no-repeat;
  background-image: linear-gradient(white, white),
    linear-gradient(30deg, #cdcdcd 100%, lightgrey 30%),
    linear-gradient(120deg, #37bf8a 100%, lightgrey 30%),
    linear-gradient(300deg, #cdcdcd 100%, lightgrey 30%),
    linear-gradient(210deg, #cdcdcd 100%, lightgrey 90%);
  background-position: center center, left top, right top, left bottom,
    right bottom;
  background-origin: content-box, border-box, border-box, border-box, border-box;
  background-clip: content-box, border-box, border-box, border-box, border-box;
}
.steps_dot2::after {
  content: "";
  background: url("../../arrow.png");
  width: 30px;
  height: 25px;
  position: absolute;
  background-repeat: no-repeat;
  right: -33px;
  background-size: 100%;
  top: 16px;
}
.upi_text .FirstLatter {
  font-size: 40px;
  color: #37bf8a;
  float: left;
  padding-right: 8px;
}

/* pledge css */
.pledgeinput input[type="text"] {
  width: 70%;
  text-align: right;
  float: right;
}
.pledgeinput input[type="text"]:focus {
  outline: none;
}
.pledgeinput .order-number-input {
  background: transparent !important;
}
@media (max-width: 600px) {
  .mobile_hidden {
    display: none;
  }

  .mobile-input-qty .order-number-input {
    width: 100% !important;
    background: transparent !important;
  }
}
.pledger_headning {
  background: #3a3b40;
  padding: 10px 0px 10px 30px;
  margin: 15px 0px;
}
.pledgeDataHeight {
  height: calc(100% - 37%);
}

/* Pledge mobile data css */
.pledgemobiledata .custom-control-label::before,
.custom-control-label::after {
  top: 0.8rem;
  width: 1.25rem;
  height: 1.25rem;
}

.pledgemobiledata .activeTab a:hover {
  border-bottom: 3px solid #139c65;
}

.pledgemobiledata .activeTab a.active {
  color: #139c65;
  border-bottom: 3px solid #139c65;
  border-bottom: 3px solid #139c65 !important;
  border: 0px;
  width: 35%;
  text-align: center;
  margin-right: 20px;
}
.pledgemobiledata .MobileRow {
  line-height: 40px;
  color: #000 !important;
}
.mobileTopDropdown #drop-down {
  color: #000 !important;
}
.mobileTopDropdown .dropdown .arrow-icon {
  color: #000 !important;
}
.mobileTopDropdown .dropdown > .dropdown-menu {
  color: #000 !important;
  background: #fff !important;
}
.mobileTopDropdown .dropdown > .dropdown-menu > li > a {
  color: #000 !important;
  background: #fff !important;
}
.pledgemobiledata .nav-tabs .nav-link {
  width: 35%;
  text-align: center;
  color: #000;
  font-weight: bold;
}
.mobile-input-qty .order-number-input {
  /* width: 50% ; */
  background: transparent !important;
}
.mobileTopDropdown .drop-down {
  color: #000;
}
.dialog-show > .mobileErrorBox.modal-dialog > .modal-content {
  background: #fff !important;
  border-radius: 0px;
  color: #000;
}
.mobile-input-qty .order-number-input input {
  color: #000 !important;
  border-radius: 0px !important;
  border-bottom: 1px solid #949394 !important;
  width: 100%;
}
.pledgemobiledata .nav-tabs {
  width: 100%;
}
.pledgemobiledata .nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border: 0px;
  width: 35%;
}
.pledgemobiledata .header {
  background: linear-gradient(to bottom, #139c65 0%, #23c691 100%);
  border: none;
  color: white;
  padding: 9px 9px 5px 9px;
}
.mobileTab .error-holder {
  color: #000;
  padding: 50px 0px;
  /* font-weight: bold; */
}
.pledgemobiledata .btnColor {
  background-color: #0053ab;
  border-radius: 39px;
  color: #fff;
  margin-top: 30px;
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 80%;
  right: 0px;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 20px;
}

/* =================pledge mobile css====================== */
.pledgemobiledata .tabbar-content-holder {
  width: 100%;
  background: #fff;
  border: 1px solid #fff;
}
.pledgemobiledata .dp-tab-content {
  background: #fff;
  overflow: scroll;
}
.pledgemobiledata .tab-bar.highlight-above > .active > a {
  background-color: #fff !important;
  color: #000;
  border-top: 0px !important;
  border-bottom: 2px solid #1ebf8a;
}
.pledgemobiledata .tab-bar.highlight-above li a {
  color: #000;
}
.pledgemobiledata .widget-title {
  display: none;
}
.pledgemobiledata .tabbar-content-body.contentpanel {
  height: 600px;
}
.drop-down:hover {
  color: #eeeeee;
}
.mobileTopDropdown .drop-down:hover {
  color: #000;
}
.unselectMobile {
  background: #0053ab !important;
  margin: 0px;
  margin-top: 20px;
  border-radius: 50px;
}
.tradingInput .trading-textbox {
  background-color: transparent;
  height: 30px;
  border-radius: 0 !important;
  border: none;
  border-bottom: 2px solid #c0baba;
}
