/* ===================================================== */
/* My Orders */
.orderbookbase-content-holder {
    border:1px solid #3a3a3a;
    padding: 0px 10px 10px;
    background: #131314;        
    overflow-y: hidden;
}

.orderbookbase-content-body {
    height: calc(100% - 52px);
    overflow-y: hidden;
    overflow-x: hidden;
}

.myorders-header {
    color:#aaa;
    font-size: 10px;
    /* font-weight: lighter; */
    /* margin-bottom: 20px;     */
}


.myorders-header>div {
    display: flex;
    justify-content: flex-end;
}

.myorders-header>div:first-child{
    display: flex;
    justify-content: flex-start !important;
}

.myorder-header-top{
    margin-bottom: 5px !important;
}
.myorders-row {
    color:#fff;
    font-size: 12px;
    padding: 12px 15px 12px 0px !important;
}

.myorder-btn.modify-btn ,.myorder-btn.modify-btn:disabled{
    background: #0053AB;
    border: none;
    color: #E4E4E5;
}

.myorder-btn.cancel-btn,.myorder-btn.cancel-btn:disabled{
    background: #16A076;
    border: none;
    color: #E4E4E5;
}

.myorder-btn.exit-btn,.myorder-btn.exit-btn:disabled {
    background: #D47A22;
    border: none;
    color: #E4E4E5;
}

.myorder-btn.history-btn{
    background: #4A4A4A;
    border: none;
}

.myorder-btn {
    background-color: #232428;
    border: solid 1px #fff;
    color: #fff;
    font-size: 10px;
    padding: 4px 0px!important;    
    border-radius: 0px;
    height: auto;
}

.myorder-btn:disabled {
    background-color: #232428;
    border: solid 1px #fff;
    color: #fff;
    opacity: 0.4;
}

.orderbook-accordion {
    background-color: #42454A;
    border-radius: 0 !important;
    padding-left: 15px !important;
    margin: 0 15px 30px 15px;
}

.orderbook-arrow{
    padding-left: 5px;
    font-size: 18px;
    transform: rotate( 0deg );            
    -moz-transform:    rotate( 0deg );            
    -ms-transform:     rotate( 0deg );            
    -o-transform:      rotate( 0deg );            
    -webkit-transform: rotate( 0deg );   
    transition:  transform 300ms ease;
    -moz-transition:    -moz-transform 300ms ease;
    -ms-transition:     -ms-transform 300ms ease;
    -o-transition:      -o-transform 300ms ease;
    -webkit-transition: -webkit-transform 300ms ease;
}

.myorders-arrow {
    color: #eee;
    margin-top: 8px;
    margin-left: 0 !important;
    padding-left: 0 !important;
}

.myorders-sym {
    margin-left: 10px;
}

.myorders-exc {
    font-size: 10px;
    margin-left: 5px;
    color: #AAAAAA;
}

.myorders-tab-content{
    height: calc(100% - 40px);
    overflow-y: auto;
    overflow-x: hidden;
}

.myorder-column{
    padding: 0px 15px 0px 0px !important; 
}

.myorder-prd{
    padding: 0px 5px 0px 0px !important; 
    color: #AAAAAA;
    font-size: 12px;
}

.myorders-tab-content .hdivider{
    margin: 0px;
}
/* ===================================================== */
/* My Orders History Popup */
.history-popover-rgt{
right:10% !important;
left:auto !important;
}
.history-popover-rgt-small{
    right:1% !important;
    left:auto !important;
    }
.history-popover {
    background-color:#42454A !important;
    max-width: none;
    min-width: 400px;
    padding: 0px;
    border-radius: 0;
}
.history-popover-rgt.bottom>.arrow{
    right:10% !important;
    left:auto !important;
     
}
.history-popover-rgt.top>.arrow{
    right:10% !important;
    left:auto !important;
     
}
.history-popover-rgt-small.bottom>.arrow{
    right:2% !important;
    left:auto !important;
     
}
.history-popover-rgt-small.top>.arrow{
    right:2% !important;
    left:auto !important;
     
}

.history-popover.bottom>.arrow:after{
    border-bottom-color: #42454A !important;
}

.history-popover.top>.arrow:after{
    border-top-color: #42454A !important;
}

.history-popover.popover.top {
    margin-top: -101px !important;    
}

.history-hdivider {
    border-bottom: 1px solid #aaaaaa;
}

/* .myorder-btn.history-btn:active, .myorder-btn.history-btn:focus {
    background-color: #1EBF8A;
} */

.history-header {
    font-size: 10px;
}

.history-content {
    font-size: 13px;
}

.history-date {
    font-size: 11px;
}

.history-bold {
    font-weight: bold;
}

.status-margin {
    margin-left: 10px;
}

.symbol-margin {
    margin-left: 2px;
}

.history-padding {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}

.mytrades-date-parent {   
    display: flex;
    align-items: center;
    align-self: center;
    padding: 0px 0px 5px 15px !important;
    cursor: pointer;
}

.orders-list {
    line-height: 45px;
    max-height: 195px;
    overflow-y: auto;
}

.connecting-border {
    border-left: 1px solid #eee;
    margin: -12px 0 -12px 5px !important;
    padding-bottom: 5px !important;
}

.circle-first {
    color: #1EBF8A;
}

/* ===================================================== */
/* My Trades */
.mytrades .hot-news-accordion{
    margin: 0px 15px 0px 5px;
    padding: 10px;
    width: 99%;
}

.mytrades .accordion-header{
    /* padding: 0px 15px; */
}

.mytrades-header-top{
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
}

.mytrades-bg {
    background-color:rgb(46, 50, 58) !important;     
}

.mytrades.hdivider {
    margin-bottom:0 !important;
}

.mytrades-row{
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
}

.mytrades-arrow{
    position: absolute;
    right: 0;
    padding: 0px;
    padding-right: 15px;
}

.mytrades-arrow .hot-news-arrow.up{
    padding: 0px;
}

.mytrades-search{
    margin-bottom:15px;
    padding: 0px;
}

.mytrades-column{
    padding: 12px 10px 12px 10px !important;
}

.mytrades-time {
    padding: 12px 35px 12px 0px !important;
}

.mytrades-column.symbol{
    padding: 12px 0px 12px 10px !important;
}

.mytrades-column.exc{
    color: #AAAAAA;
}

/* ==========================new changes=========================== */
.order-log-modal{
    max-width: 1050px;
    min-width: 800px;
    top: 25%;
    width: 90%;
    height: auto;
    min-height: 420px;
    font-size: 14px;
    background: #262A30 !important;
    border: 1px solid #3a3a3a;
    -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);    
}

.connecting-circle{
    padding-left: 20px;
}

.right-connecting-border { 
    border-right: 1px solid #6D6E70;
    height: 34px;
    margin-top: -17px;
    margin-left: -5px;
    margin-bottom: -17px;
}

.order-log-buttons{
    border: none;
    background-color: #0053AB;
    width: 70px;
    height: 30px;
    color: #E4E4E5;
}

.myorders-accordion { 
    background-color: #262930;
    border-radius: 0 !important;
    padding-right: 40px !important;
    margin: 0 5px 5px 0px;
}

.accordian-orderheader {
    font-size: 10px;
    color : #86878A;
}

.accordian-orderdata{
    font-size: 12px;
    color : #E4E4E5;
}

.expand-row {
    max-height: 0;
    overflow: hidden;
    transition:max-height .3s ease-out;
    /* display: inline; */
}

.expand-inner-row {
    max-height: 0;
    overflow: hidden;
    transition:max-height .1s ease-out;
}
.order-details {
    justify-content: center;
}

.myorders-panel-body{
    margin: 0px 5px 0px 0px;
    padding:0px 10px 0px 0px;
}

.my-accordion-padding{
    padding-top: 15px;
    padding-bottom: 15px;
}

.myorders-row > .click { 
    cursor: pointer;
}

.order-log-modal .mtf-body{
    min-height: 300px;
}
.advance-orders-accordion { 
    background-color: #262930;
    border-radius: 0 !important;
}

.inner-expand {
    background-color: #2F333C;
    margin-left: 15px;
    padding-right: 0px;
}
