.info-tab-content {    
    background-color: rgb(33, 38, 44);
    padding:0px !important;
    height: 100%;
    overflow: hidden;
}

.info-tab-scroll{
    height: calc(100% - 115px);
    overflow-y: auto;
    overflow-x: hidden;
}

.info-tab-scroll.normal{
    height: calc(100% - 80px);
}

#info-tab .tabbar-content-body{
    height: calc(100% - 50px)
}

.exc-header-pad{
    padding: 15px 0px 0px 20px;
}

/*======Bhavcopy=======*/
.bhavcopy-topbar{
    margin-left:1px;
    margin-right:1px;
    background: rgb(33, 38, 44);
    height: 50px;
    margin-bottom: 7px;
  }
  .bhavcopy-toptext{
      padding-top: 15px;
      padding-left: 10px;
      padding-right: 10px;
  }
  .bhavcopy-tabcontent{
      padding-top: 5px;  
      height: 100%;
  }
  .bhavcopy-subtab{
      padding-left:20px;
  }
  .bhavcopy-row {
      color:#fff;
      font-size: 14px; 
      border-bottom: 1px solid #3a3a3a; 
      display: flex;
      flex-wrap: wrap;
  }
  
  .bhavcopy-evenrow{
      color: white;
      opacity: .4;
      background-color: rgb(46, 50, 58);            
      padding-top: 10px;
      padding-bottom: 10px;  
      padding-right: 5px;
      padding-left: 5px;
  }
  .bhavcopy-header{
      color: #aaa;
      font-size: 11px;
    border-bottom: 1px solid #3a3a3a;
  }
  .bhavcopy-column{      
    padding-top: 10px;
    padding-bottom: 10px;  
    padding-right: 5px;
    padding-left: 5px;
  }
.bhav-tab-content {
    background-color: rgb(33, 38, 44);
    padding:0px !important;
    height: calc(100% - 35px);
}
.bhav-data-holder {
    height: calc(100% - 50px);
    overflow-y: auto;
}
/*-----Exchange Message-------*/
.excMsg-row {
    color:#fff;
    font-size: 14px;
}

#investor-popover.popover.right>.arrow:after{
    border-right-color: #42454A;
  }
  
  #investor-popover.popover.right>.arrow {
    border-right-color: #42454A;
  }

  #investor-popover{
      z-index: 2000 !important;
  }

  #investor-popover .popover-content{
      overflow-y: auto;
      max-height: 170px; 
  }