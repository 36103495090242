/* ===================================================== */

/* order entry popup */

.orderentry-modal {
    z-index: 1045;
}

.modal-dialog.orderentry {
    min-width: 750px;
    max-width: 900px;
    width: 90%;
    min-height: 480px;
    max-height: 600px;
    height: auto;
    top: 1%;
    font-size: 12px;
    background: rgb(43, 44, 49);
    border: 1px solid #3a3a3a;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.order-header-row {
    padding: 10px
}

.order-content {
    visibility: visible;
    opacity: 1;
}

.order-content.disable {
    visibility: hidden !important;
    opacity: 0 !important;
}

.amo_checkbox {
    padding-left: 5px;
}

.amo-label {
    color: #FFFFFF;
    font-size: 15px;
    padding-left: 14px;
}

.amo-label.disabled {
    opacity: 0.2;
    cursor: default;
}

.order-row {
    padding: 7px 20px 5px 15px;
}

.order-toggle-btns {
    /* margin-left: 5px; */
    padding-top: 10px;
}

.order-toggle-hide {
    opacity: 0;
}

.order-search {
    float: right;
}

.order-search .search-parent {
    padding: 0px !important;
}

.order-header {
    font-size: 18px;
}

.ltp-plain {
    /* animation: pln-ltp;
    animation-duration: 0.3s;
    animation-delay: 0.1s;
    animation-timing-function:linear;
    animation-fill-mode:both;
    animation-iteration-count: 1;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-timing-function:linear;
    -webkit-animation-fill-mode: both; */
}

.ltp-anim-pos {
    animation: neg-pos-ltp;
    animation-duration: 0.3s;
    animation-delay: 0.1s;
    animation-timing-function: linear;
    animation-fill-mode: both;
    animation-iteration-count: 1;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-timing-function: linear;
    -webkit-animation-fill-mode: both;
}

.ltp-anim-neg {
    animation: pos-neg-ltp;
    animation-duration: 0.3s;
    animation-delay: 0.1s;
    animation-timing-function: linear;
    animation-fill-mode: both;
    animation-iteration-count: 1;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-timing-function: linear;
    -webkit-animation-fill-mode: both;
}

/* The animation code */

@keyframes pln-ltp {
    0% {
        background-color: transparent;
    }
    80% {
        background-color: rgb(78, 82, 80);
    }
    100% {
        background-color: transparent;
    }
}

@keyframes neg-pos-ltp {
    0% {
        background-color: transparent;
    }
    80% {
        background-color: #1EBF8A;
    }
    100% {
        background-color: #1EBF8A;
    }
}

@keyframes pos-neg-ltp {
    0% {
        background-color: transparent;
    }
    80% {
        background-color: #E5283F;
    }
    100% {
        background-color: #E5283F;
    }
}

.change-scrip {
    color: #1ebf8a !important;
    margin-left: 20px;
}

.order-btn {
    background-color: #AAB0AD;
    background: rgba(0, 0, 0, 0.2);
    border: 1px solid #fff;
    ;
    color: #EEEEEE;
    height: 30px;
    font-size: 12px;
    min-width: 140px;
}

.order-btn:disabled {
    opacity: 0.3;
}

.order-btn:active, .order-btn:focus {
    outline: 0 none;
    border: 2px solid #1EBF8A !important;
}

.order-cancel {
    background: transparent;
    outline: none;
}

.cancel-dialog {
    min-height: 380px;
    height: auto;
    background: #262A30;
}

.cancel-dialog>.modal-header {
    height: 80px;
    background-color: #222427;
    color: #FFFFFF;
}

.cancel-dialog>.modal-header>.cancel-dialog-header {
    padding-top: 15px;
    font-size: 18px;
    font-weight: 400;
}

.modal-dialog.cancel-dialog {
    width: 40%;
    height: auto;
    min-height: 420px;
    min-width: 420px;
    top: 10%;
    font-size: 14px;
    background: rgb(43, 44, 49);
    border: 1px solid #3a3a3a;
    -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 3px !important;
}

.cancel-dialog .mtf-footer {
    position: absolute;
    width: 100%;
    bottom: 20px;
}

.order-place {
    background: #1ebf8a;
    margin-right: 20px;
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 5px;
    padding-bottom: 4px;
    outline: none;
}

.order-place:focus {
    /* background: #1ebf8a; */
    box-shadow: 0 0 5px rgba(81, 203, 238, 1);
}

.order-place:disabled {
    opacity: 0.1;
}

.order-radio {
    padding-left: 0px;
}

.order-radio-label {
    margin-left: 20px;
    margin-bottom: 10px;
}

.order-modify {
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 5px;
    padding-bottom: 4px;
}

.order-divider {
    border-bottom: 2px solid #3a3a3a;
}

.input-title {
    margin-bottom: 5px;
}

.order-calendar {
    color: #1ebf8a;
}

.order-exchange-holder {
    align-items: center;
    padding: 5px;
    /* background: #292A2E; */
    margin-left: 5px;
}

.orderinput-progress-holder {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    color: #1EBF8A;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    background-color: #0000005d;
    z-index: 1095 !important;
}

.expiry-date-text {
    margin-top: 12px;
}

.spread-exp-static {
    margin-top: 8px !important;
}

.spread-action {
    font-size: 20px;
    margin-top: 30px;
}

.spread-validity {
    padding-left: 35px;
}

.spread-validity-static {
    margin-top: 15px !important;
    color: #fff;
    font-weight: bold;
}

.spread-center {
    text-align: center;
}

.order-change-scrip>a {
    color: #1EBF8A !important;
    text-decoration: underline;
    font-size: 12px;
    cursor: pointer;
}

.order-change-scrip {
    margin-top: 5px
}

/* ===================================================== */

/* buy or sell toggle switch */

.order-button {
    font-size: 12px;
    color: #fff;
    background: rgb(19, 23, 48);
    padding: 8px;
    min-width: 70px;
    outline: none;
}

.order-button.active-buy {
    color: #eee;
    background: #176dbe;
}

.order-button.active-sell {
    color: #eee;
    background: #D4403F;
}

.order-place.active-buy {
    color: #eee;
    background: #1B9771;
}

.order-place.active-sell {
    color: #eee;
    background: #D4403F;
}

/* ===================================================== */

/* order confirmation */

.order-confirmation-body {
    max-height: 500px;
    overflow-y: auto;
}

.order-confirmation-row {
    margin-top: 10px;
    margin-bottom: 10px;
    justify-content: 'center';
}


.order-confirmation-charges-row {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 12px;
    justify-content: 'center';
}

.order-confirm-button {
    font-size: 14px;
    color: #fff;
    background: rgb(19, 23, 48);
    padding-left: 12px;
    padding-right: 12px;
}

.order-confirm-button.active-buy {
    color: #eee;
    background: #176dbe;
}

.order-confirm-button.active-sell {
    color: #eee;
    background: #E5283F;
}

.order-confirmation-intradayfooter {
    background: #3a3a3a;
    padding: 5px;
    font-size: 10px;
    text-align: center;
}

/* ===================================================== */

/* order result */

.modal-dialog.orderresult {
    width: 500px;
    height: auto;
    max-height: 750px;
    top: 1%;
    font-size: 14px;
    background: rgb(43, 44, 49);
    border: 1px solid #3a3a3a;
}

.orderresult-img {
    width: 250px;
    height: 200px;
    margin: 10px;
    margin-top: 30px;
}

.orderresult-symbol {
    font-size: 16px;
}

.orderresult-headline {
    font-size: 14px;
    margin-top: 10px;
}

.orderresult-message {
    font-size: 12px;
}

/* ===================================================== */

/* holdings / positions */

.holdings-popover {
    max-width: none;
    background: rgb(33, 38, 44) !important;
    width: 400px;
    padding: 0px;
}

.holdings-popover.bottom>.arrow:after {
    border-bottom-color: rgb(33, 38, 44) !important;
}

.holdpos-header {
    border-bottom: 1px solid rgb(43, 46, 51);
    padding: 5px;
}

.holdpos-holder {
    margin-top: 5px;
    /* margin-bottom: 10px; */
}

.holdpos-header-row {
    /* background: rgb(28, 30, 34); */
    color: #AAAAAA;
    font-size: 12px;
    padding: 0px 5px 0px 5px;
}

.holdpos-row-title {
    font-size: 14px;
}

.holdpos-row {
    padding: 1px 3px 1px 3px;
    font-size: 12px;
    color: #FFFFFF;
}

/* ===================================================== */

/* market depth */

.marketdepth-popover {
    max-width: none;
    background: rgb(33, 38, 44);
    border-radius: 0px;
    border: 1px solid #1EBF8A;
    width: 400px;
}

.marketdepth-popover.bottom>.arrow:after {
    border-bottom-color: rgb(33, 38, 44);
}

.marketdepth-popover.bottom>.arrow {
    border-bottom-color: #1EBF8A;
}

.marketdepth-header {
    border-bottom: 1px solid rgb(43, 46, 51);
    padding: 5px;
}

.marketdepth-footer {
    border-bottom: 1px solid #42454A;
    padding-top: 6px;
    padding-bottom: 6px;
    text-align: center;
    font-size: 12px;
}

.marketdepth-footer.buy {
    color: #176dbe;
}

.marketdepth-footer.sell {
    color: #E5283F;
}

.marketdepth-row {
    border-bottom: 1px solid #42454A;
    padding-top: 4px;
    padding-bottom: 4px;
    text-align: center;
    font-size: 12px;
}

.marketdepth-data {
    min-height: 130px;
}

.marketdepth-row.buy {
    color: #176dbe;
}

.marketdepth-row.sell {
    color: #E5283F;
}

.marketdepth-container {
    margin-top: 5px;
    /* padding-left: 15px;
    padding-right: 15px; */
}

.marketdepth-container>.market-depth {
    padding-left: 15px;
    padding-right: 15px;
}

.marketdepth-container .marketdepth-row.buy, .marketdepth-container .marketdepth-data .buy-data {
    border-right: 1px solid #42454A;
}

/* ===================================================== */

/* advanced order type */

.advanced-order {
    background: #2c323a !important;
    display: none;
}

.advanced-order-tabs {
    padding-left: 0px;
}

/* ===================================================== */

/* order toggle switch */

.order-toggle {
    padding-right: 0px !important;
}

.order-toggle.orderinput {
    padding: 2px;
    padding-right: 4px !important;
    background-color: #42454A;
}

.order-toggle .toggle-button {
    font-size: 12px;
    color: #AAAAAA !important;
    background: #292A2E !important;
}

.order-toggle .toggle-button.active {
    color: #FFFFFF !important;
    background: #42454A !important;
}

.sell-toggle {
    margin-top: 5px;
}

/* ===================================================== */

.mtf-info-icon img {
    width: 15px;
    cursor: pointer;
}

.mtf-info-icon {
    padding-left: 10px !important;
    top: 5px;
}

/* ==================== Advanced Order ================================= */

.header-text {
    font-family: 'Roboto' !important;
    font-size: 14px !important;
    padding-top: 15px;
    color: white;
}

.orderheader-divider-buy {
    border-bottom: 2px solid #1EBF8A !important;
    padding-top: 5px;
    margin-left: 10px;
    margin-right: 10px;
}

.orderheader-divider-sell {
    border-bottom: 2px solid #D4403F !important;
    padding-top: 5px;
    margin-left: 10px;
    margin-right: 10px;
}

.divider-right {
    border-right: 1px solid #42454A !important;
}

.divider-right>.order-properties-divider {
    border-bottom: 2px solid #42454A !important;
}

.order-properties-divider {
    border-bottom: 1px solid #42454A !important;
}

.order-properties-name {
    white-space: nowrap;
    color: #AAAAAA;
}

.order-number-input {
    border-radius: 0;
    background: #292a2e;
    -webkit-box-shadow: none;
    box-shadow: none
}

.order-number-input input {
    border: none;
    outline: none;
    -webkit-box-shadow: none!important;
    box-shadow: none!important;
    background: transparent;
    text-align: center;
    color: #eee;
    border-bottom: 1px solid #42454A;
}

.order-number-input input:focus {
    border: none !important;
    border-bottom: 1px solid #42454A !important;
}

.quantity-label {
    padding-top: 10px;
    justify-content: flex-start;
    align-items: center;
    display: flex;
}

.order-inputs-scroll {
    min-height: 300px;
    max-height: 330px;
    overflow-x: hidden;
    overflow-y: scroll;
    padding-bottom: 20px;
}

.market-holdings {
    padding-bottom: 5px;
    padding-top: 10px;
}

.market-holdings .headers {
    background-color: #42454A !important;
    color: #FFFFFF !important;
}

.ohlc-row {
    color: #AAAAAA;
    margin-top: 5px;
    margin-bottom: 5px;
}

.ohlc-row span {
    color: #FFFFFF;
}

.order-footer {
    border-top: 1px solid #42454A !important;
    margin-left: 10px;
    margin-right: 10px;
}

.marketdepth-container .marketdepth-row {
    line-height: 22px;
}

.best-buy-sell {
    padding-top: 5px;
    bottom: 10px;
    position: absolute;
}

.validity-type {
    padding-left: 10px !important;
}

.mtf-info-icon img {
    width: 15px;
    cursor: pointer;
}

.mtf-info-icon {
    top: 5px;
}

.changeper {
    padding-right: 18px !important;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.daily-price-range {
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
    margin-top: -5px;
    font-size: 11px;
    color: #AAAAAA;
}

.trigger-price-range {
    display: flex;
    justify-content: flex-start;
    padding-left: 60px;
    margin-top: 5px;
    font-size: 11px;
    color: #AAAAAA;
}

.flex-space-evenly.ohlc-row {
    flex-wrap: wrap;
}

.myinner-accordion-padding {
    padding-top: 15px;
}
/* spread my  css  */
.spread-buySell{
    font-size: 14px;
    padding-top: 15px;
}
.spread-buySell.buy{
    color:#176dbe;
}
.spread-colum p{
    margin-bottom: 5px;
}
.spread-colum p span{
    font-size: 14px;
}
.spread-colum .btn{
    font-size: 14px;
    padding: 1px 5px;
}
.spread-colum .form-control{
    font-size: 14px;
    padding: 0px;
    height: 32px;
}
.spread-colum .input-group{
    height: 32px;
}
.spread-buySell.sell{
    color: #E5283F;
}
.spread-colum .sellQnty{
    background: #42454a;
    text-align: center;
    line-height: 34px;
}