/* -------------------------------*/
/* Scrip Details */

.scrip-padding {
    padding: 15px 15px 5px 15px !important;
}

.scrip-details{
    margin: 0px -15px 0px -15px;
    overflow: hidden;
}

.scrip-details.chartpadding{
    padding: 0px 15px;
}


/* -------------------------------*/
/* Scrip Header */

.scrip-header{
    padding: 0px 15px 15px 15px;
    margin: 0px !important;
    min-height: 110px;
}

.scrip-header-row{
    padding: 0px 10px 0px 0px;
    min-height: 100px;
}

.scrip-value {
    font-size: 14px;
    min-height: 25px;
}

.tc-scrip-value {
    font-size: 12px;
}

.scrip-head-name { 
    font-size: 12px;
    margin: 5px 0 10px 0; 
    color: #AAAAAA;
    min-height: 25px;
}

.scrip-btn {
    background: #292A2E;
    border: 1px solid #1EBF8A;
    min-width: 60px;
    color: #1EBF8A;
    /* margin-left: 10px; */
    height: 26px;
    border-radius: 0px;
    padding: 1px 6px;
    font-size: 12px;
}

.scrip-btn:hover, .scrip-btn:focus{
    color: #1EBF8A;
}

.scrip-btn.popup {
    float: right;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 5px;
}

.scrip-small-font { 
    font-size: 10px;
    margin-top: 5px;
    color: #AAAAAA;
}

.scrip-security-title {
    color: #1EBF8A;
    font-size: 12px;
    margin-top: 15px;
}

.scrip-security-info {
    margin-left: 5px;
    font-weight: bold;
}

.scrip-buttons {
    margin-bottom: 4px;
}

.scrip-custom-btn {
    background: #292A2E !important;
    color: #fff !important;
}

.scrip-custom-btn:active, .scrip-custom-btn:focus {
    outline: 0 none;
    /* border: 1px solid #1EBF8A !important; */
  }

.scrip-custom-btn:disabled {
    opacity: 0.5;
}

.scrip-btn-icon {
    margin: 0 2px 0 1px;
    color: #1EBF8A;
    width: 10px;
}

.scrip-data {
    border-left: 2px solid #3a3a3a;
    font-size: 10px;
}

.scrip-fno-inputsholder {
    border: 1px solid #3a3a3a;
   min-height: 50px;
}

.scrip-fno-block {    
    float: left;
    min-width: 150px;
    padding:5px;
    margin-left: 10px;
}

.scrip-fno-block-popup {
    padding: 5px;
}

.scrip-fno-block-dropdown {
    background-color: #42454A;
    border: 1px solid #ccc;
}

.scrip-fno-block-dropdown #drop-down{
    padding: 5px 5px 5px 10px !important;
}

.scrip-fno-block-dropdown .dropdown-menu{
    max-height: 200px;
    overflow-y: auto;
}


.scrip-fno-block-btn {
    margin-top: 20px;
}

.scrip-exchange {
    float: left;
    border: 3px solid #292A2E ;
    margin-right: 10px;
}

/* -------------------------------*/
/* Scrip Content */

.scrip-content{
    padding: 10px 15px;
    height: calc(100% - 142px);
    overflow-y: auto;
}

.scrip-content.news{
    height: calc(100% - 65px);
}
.scrib-tab-parent{
    padding: 0px 15px;
    background: #42454A;
}


/* -------------------------------*/
/* Overview Scrip */
.scrip-market-depth {
    min-height: 185px;
    margin-bottom: -8px;
}

.scrip-md-content-holder {
    border:1px solid #3a3a3a;
    padding: 0px 10px 10px;
    background: #131314;        
    overflow-y: hidden;
}

.scrip-technicals {
    min-height: 170px;
    margin-top: 20px;
}

.scrip-technicals .dash-content-scroller{
    overflow-x: hidden;
}

.scrip-tech-header {
    color:#aaa;
    font-size: 11px;
    /* font-weight: lighter; */
    margin-bottom: 8px !important;
}

.scrip-technicals-row {
    margin-top: 10px !important;
    padding-bottom: 5px;
}

/* -------------------------------*/
/* Financial Scrip */

.financial-tab-parent{
    height: calc(100% - 29px);
    background: #262A30;
    margin: -2px 0 0 0px;
    /* overflow-y: auto; */
}

.financial-content {
    height: calc(100% - 35px);
    overflow-y: auto;
}

.financial-content.shp {
    height: 100% !important;
    overflow-y: auto;
}

.scrip-details .tabbar-content-body{
    height: 100%;
}

.financial-header{
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    color: #AAAAAA;
    border-left: 1px solid #262A30;
    border-right: 1px solid #262A30;
}

.financial-row{
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    border-left: 1px solid #262A30;
    border-right: 1px solid #262A30;
}

.financial-row:nth-child(even){
    background: #131314;
}

.financial-row::nth-child(odd){
    
}

.financial-split-cell {
    float: left;
}

.financial-split-cell.results {    
    width: 20%;
}
.financial-split-cell.ratios {    
    width: 20%;
}
.financial-split-cell.shp {    
    width: 20%;
}
.financial-split-cell.bs {    
    width: 20%;
}
/* -------------------------------*/
/* Peers Scrip */

.peers-header{
    font-size: 14px;
    padding: 10px 15px !important;
    border-left: 1px solid #262A30;
    border-right: 1px solid #262A30;
}

.peers-row{
    padding: 8px 15px !important;
    border-left: 1px solid #262A30;
    border-right: 1px solid #262A30;
}

.peers-row:nth-child(even){
    background: #131314;
}

.peers-row.alternate{
    /* background: #27282C; */
}

/* -------------------------------*/
/* Option chain Scrip */

.optchain-parent {
    border:1px solid #3a3a3a;
    padding: 0px 5px 10px 5px;
    background: #131314
}

.optchain-header-right{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0px !important;
}

.optchain-header-right .expiry{
    padding-right: 5px;
}

.optchain-header-right .expiry-date .btn{
    background: #262930 !important;
    border: 1px solid #EEEEEE;
    padding: 4px 5px !important;
}

.optchain-header-right .expand{
    padding-left: 20px;
}

.optchain-call-header{
    border-bottom: 2px solid rgb(22, 109, 190);
}

.optchain-put-header{
    border-bottom: 2px solid #E5283F;
}

.optchain-header-row{
    display: flex;
    padding: 3px 5px !important;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
}

.optchain-header-row.reverse{
    flex-direction: row-reverse;
    padding: 3px 5px 3px 0px !important;
}

.optchain-header-row.alternate{
    background: #15161A;
}

.optchain-header-row.header{
    font-size: 11px;
    color: #AAAAAA;
}

.optchain-watch-parent{
    display: flex;
    flex-direction: row;
    padding: 0px !important; 
    align-items: center;
    flex-wrap: wrap;
}

.optchain-watch-parent.reverse{
    flex-direction: row-reverse;
}

.optchain-watch{   
    color: #EEEEEE;
    background-color: #262930;
    border: 0px;
    padding:3px 3px !important;   
}

.optchain-watch.buy-btn, .optchain-watch.sell-btn {
    width: 100%;
}

.opt-btn-reverse{
    display: flex;
    flex-direction: row;
}
.optchain-content-parent{
    height: calc(100% - 50px);
}

.optchain-content{
    height: calc(100% - 20px);
    overflow-y: auto;
}

.optchain-row-pad{
    padding: 5px 0px 5px 5px !important; 
}

.optchain-row-strike{
    padding: 11px 5px;
    background: #262930;
    height: 100%;
    display: block;
}

.optchain-row-strike:after{
    content: '';
    position: absolute;
    bottom: 0;
    left: 10%;
    transform: translateX(-5%);
    width: 90%;
    height: 1px;
    background: #42454A;
}

/* -------------------------------*/
