
.login_mainholder {
    text-align: center;
    /* margin: 20px 30px 10px 30px; */
}

.login_form {
    width: 80%;
    text-align: center;
    padding:25px;
    min-width: 250px;
}

.login_formcontrol {
     margin:15px 5px;
    width: 100%;
    height: 38px;
    border:5px #ccc;
    box-shadow: 0px;
    border-radius: 0px; 
    text-indent: 32px;
}
.login_formcontrol:active ,.login_formcontrol:focus{
    box-shadow:  1px 1px 1px 1px #ccc;
} 
.outln_red{
    border-radius: 0px; 
    border:0.1px solid rgb(255, 5, 5);
    text-indent: 32px;
    
}   
.login_tab{
    margin-top: 20px;
    display: flex;
    justify-content: center;
}
.login_btn{
    border-color: #1EBF8A;
    color:#1EBF8A;
    font-size: 16px;
    height: 38px;
    margin-top: 7px;
    border-radius: 0px; 
}
.login_link{
    margin-top: 12px;
    display: flex;
    justify-content: space-between
}
.icon_style{
    position: relative;
    color: #aaa;
    font-size: 18px;
}
.login_icon{
    position: absolute;
    top: 4px;
    left: 10px;
    width: 28px;
}

.tab-bar >.active >.login_tab_text{
    color:#1EBF8A !important;
}

.tab-bar .login_tab_text{
    color: #565A63 !important;
}

.tabbar-disabled {
    opacity: 0.2;
}

.login_btn:active ,.login_btn:focus{
    background: #1EBF8A !important;
    color: #fff;
    border-color: #1EBF8A;
}
.input-square{
    margin: 15px 5px;
    width: 40px;
    height: 38px;
    border: 5px #ccc;
    border-radius: 0px;
    background: #ccc;
    text-align: center;
    color: rgb(10, 9, 9);
}
.input-square:active ,.input-square:focus{
    background: rgb(162, 240, 214) !important;
}
.trouble_btn{
    border-color: #1EBF8A;
    color:#1EBF8A;
    font-size: 16px;
    height: 38px;
    border-radius: 0px; 
}
.trouble_btn:active ,.trouble_btn:focus{
    background: #1EBF8A !important;
    color: #fff;
    border-color: #1EBF8A;
}
.anchor_btn{
    background: transparent !important;
    border: none;
    text-decoration: normal;  
    
}