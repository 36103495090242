.survpop{
    padding: 24px;
}
.surv-title{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    /* or 120% */

    display: flex;
    align-items: center;
    text-align: center;
}
ol{
    padding-left: 26px;
}
.surv-msg{
    /*background: #F7F7F7;*/
    border: 1px solid #d9d9d9;
    padding: 10px 5px 0 0;
    display: flex;
    margin-top: 20px;
    font-family: 'Roboto';
    font-style: normal;
    /*font-weight: 700;*/
    font-size: 16px;
    line-height: 19px;
}
.surv-msg img{
    margin-right: 10px;
}
.surv-btngrp{
    margin-top: 20px;
    display: flex;
    justify-content: center;

}
.surv-btngrp button{
   margin: 0 10px;
    width: 150px;
    background-color: transparent;
    height: 44px;
    border: 1px solid  #d9d9d9;
}
.surv-btn-blue{
    background-color: #2a6ce4 !important;
    border: none !important;
}
