.ciq-night .chart-area{
    background: #050A11 !important;
    padding: 0px 0px 5px 0px !important;
}

.chart-content-parent{
    width: 100%;
    height: 100%;
    position: relative;
}


.ciq-night .chartContainer{
    background: #050A11 !important;
}

.ciq-footer{
    border-top: none !important;
}

.ciq-nav{
    border-bottom: none;
    border: none !important;
}
.chart-iframe nav{
    padding: 0px;
}

.chart-iframe div.left{
    float: left;
    display: flex;
    align-items: center;
    height: 100%;
    padding-left: 15px !important;
}
.stx_grid {
    color: rgb(53, 53, 53) !important;
}

.stx_grid_dark {
    color: rgb(53, 53, 53) !important;
}

.chart-iframe .ciq-nav button,.chart-iframe button:active,.chart-iframe button:focus{
    vertical-align: middle;
	border:solid 1px transparent;
}

.ciq-night .stx_line_chart{
    color: rgb(22, 109, 190);
}

.ciq-night .stx_candle_down,
.ciq-night .stx_hollow_candle_down{
    color: #E5283F;
}

.ciq-night .stx_candle_up,
.ciq-night .stx_hollow_candle_up{
    color:rgb(22, 109, 190);
}

.chart-iframe menu-select-options{
    background: #383B42;
    color: #EEEEEE
}

.chart-iframe menu-select-options  menu-option{
    color: #EEEEEE
}

.chart-iframe menu-select-options menu-option:hover{
    color: #EEEEEE;
    background: #42464f;
}

.chart-iframe .stx-panel-title.chart-title{
    display: none;
}
/*------chart search -----*/

.chart-search{
    position: relative;
    height: 45px;
    background: #0F141A;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.chart-search .search-symbol{
    margin-top: 4px;
    font-size: 14px;
    cursor: pointer;
}

.chart-search .search-exc{
    font-size: 11px;
    cursor: pointer;
    padding-left: 5px;
    font-family: "Roboto-Light"
}

.chart-search .toggle-search{
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.chart-search .toggle-close{
    width: 15px;
    height: 25px;
    cursor: pointer;
}

.chart-search .search-right-icon{
    display: none;
}

.chart-search .search-parent{
    border-bottom: none !important;
}

.chart-search-padding{
    top: 90px !important;
}
.chart-search .search-btn:first-child{
    margin-right: 20px;
}

.chart-search .search-btn{
    width: 100px;
    padding: 2px 0px;
    border: none;
}
#chart-modal{
    /* bottom: 55px; */
}

.chart-dialog{
    width: 100%;
    height:100%;
    /* top: 35%; */
    background: transparent !important;
    max-width: 100%;
}

.chart-dialog .modal-content{
    height: calc(100% - 115px);
}

.ch-modal-content{
    height: calc(100% - 115px);
    /* background-color: #131314 !important; */
    border-radius: 0;
}

.chart-dialog .modal-body{
    height: 100%;
    background-color: #131314;
}

.candle-icon{
    background-position: -350px -50px;
    background-image: url("chartiq/css/img/stx-sprite-ui.svg");
}

.study-icon{
    background-position: -300px -50px;
    background-image: url("chartiq/css/img/stx-sprite-ui.svg");
}

.chart-icon{
    width: 25px;
    height: 25px;
    opacity: 0.6;
}

.chart-header-padding > *:not(:first-child){
    padding-left: 40px;
}

.drawing-toolbar-btn::after{
    content: "";
    position: relative;
    top: -2px;
    right: 0px;
    margin-left: 23px;
    display: inline-block;
    width: 5px;
    height: 5px;
    border-right: solid thin #e2e4e6;
    border-bottom: solid thin #e2e4e6;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    zoom: 1;
    color: #e2e4e6 !important;
    opacity: 1 !important;
}

/* -------HeadsUp----------*/

.hu-label{
    width: 13px;
    color: #aaa;
    font-size: 11px;
    display: inline-block;
    line-height: 11px;
}

.hu-field{
    width: 60px;
    color: #aaa;
    font-size: 11px;
    display: inline-block;
    line-height: 11px;
}

.range.quick-link.active{
    /* content: '';
    height: 2px;
    background: #1EBF8A;
    display: block;
    width: 60%;
    -ms-transform: translateX(-50%);
    transform: translateX(33%); */
    margin-top: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    background: #444;
    color: #FFF;
}

.stx-float-date {
    width: auto !important;
    min-width: 70px;
}

.added-studies .header {
    color: #1EBF8A !important;
    background: #262A30;
    font-weight: 500;
    font-size: 12px;
    height: 21px;
}

.added-studies .footer {
    padding-top: 4px;
    margin-bottom: 5px !important;
}

.studies-clear {
    background : #1EBF8A !important;
}

.added-studies {
    text-align: center;
}

.studies-list {
    display: flex;
    flex-direction: column;
}

.study-names {
    margin-left: 5px;
    border-bottom: 2px solid #42454A !important;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-between;
    padding-bottom: px;
    margin-right: 5px;
}

.study-names span:first-child {
    padding: 5px 5px 5px 15px;
}

.disabled-clear, .disabled-clear:hover{
    opacity: 0.5 !important;
}

#chartTypeSelect menu-select-options{
    margin-top : 12px;
}

#toolSelect menu-select-options{
    margin-top : 10px;
}

.toolbar menu-select:after{
    position: initial;
    margin-top: 5px;
}