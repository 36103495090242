.config-error-holder {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.config-progress-holder {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.config-err-title {
    color: rgb(97, 100, 104);
    font-size: 18px;
}

.config-err-ref {
    width: 100%;
    color: #131314;
    font-size: 12px;
}


/* ===================================================== */
/* header */
#pageHeader {
    /* display: flex;
    flex-direction: row;
    min-width: 950px;
    height: 60px;
    position: relative; */
    background: #42454A;
}

.pageheader-componentholder.outer{
    border:1px solid #2b2b2b;    
}

.pageheader-componentholder {
    display: flex;
    flex-direction: row;
    min-height: 60px;
    margin:0px;
    padding:0px;      
}

.pageheader-component {
    width: 100%;
}

/* ===================================================== */
/* footer */

#pageFooter {   
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    min-width: 950px;
    height: 55px;
    background-color:#262A30;
    padding:7px 20px 8px 20px;
}

#pageFooter:first-child{
    margin-left: -25px;
}

.footer-buttonholder {    
    margin:0px !important;
}

.btn-footer a{
    color: #EEEEEE;
    outline: none;
    text-decoration: none;
}

.btn-footer {
    background-color:#4b4e53;
    border:none;
    color:#EEEEEE;
    height: 30px;
    margin-left: 20px;
    font-size: 12px;
}

.btn-footer-left{
    width: 120px;
}

.btn-footer-right{
    width: 125px;
}

.btn-footer-right.nav {
    padding-left:20px;
    padding-right: 20px;
    padding-top: 8px;
    padding-bottom: 8px;
}

button.btn-footer-left:active, button.btn-footer-left:focus{
    outline: 0 none;
    background-color:#1EBF8A;

  /*  border: 1px solid #1EBF8A;
    background-color: #AAB0AD;
    background: rgba(0,0,0,0.2); */
}

button.btn-footer-right:active, button.btn-footer-right:focus{
    outline: 0 none;
    background-color:#1EBF8A;
}

/* ===================================================== */
/* navbar */
.navbar{
    min-height: 0px;
    margin-bottom: 0px;
    height: 36px;
    min-width: 950px;
}
.secondaryMenu {
    flex-direction: row;
}

.navbar-custom {
    background-color: #262A30;
    border-radius: 0 !important;
    -moz-border-radius: 0 !important;
}

.navbar-nav .btn {
    padding: 0px !important;
}

.navbar-nav .nav-main-li:first-child {
    margin-left: -5px !important;
}

.nav-main-li .nav-main-link:hover .nav-main-li .dropdown-menu{
   display: block !important;
   opacity: 1;
}

.nav-main-li .dropdown-menu{
    margin: 0px !important;
     /* padding: 4px; */
}

/*#383B42*/
.nav-submenu .dropdown-menu{
    top: 0px !important;
    /* left:  calc(100% + 2px); */
}

.nav-submenu .dropdown-submenu .dropdown-menu{
    left:  calc(100% + 1px);
    background: #565A63;
}
/* 
@-moz-document url-prefix() {
    .dropdown-submenu > a::after {
        margin-top: -15px;
    }
} */

.navbar-nav .nav-main-li {
    height: 100%;
}

.nav-main-li:hover .btn,
.nav-main-li:focus .btn{
    background: #2F3239 !important;
}

.nav-main-li .drop-down:focus .nav-main-link{
    /*outline:5px auto -webkit-focus-ring-color;*/
    outline-offset:-2px;
}

.nav-main-li .dropdown.show #nav-main-dropdown-menu{
    display: block !important;
}

.nav-main-li .nav-main-link {
    padding: 11px 0px 8px 0px;
    display: inline-block;
    position: relative;
    color: #AAAAAA !important;
    margin-right: 20px;
    margin-left: 20px;
    background-color: transparent !important;
    line-height: 13px;
    font-size: 12px !important;
    text-transform: uppercase;
    text-decoration: none;
}

.navbar-nav > .active .nav-main-link{
    color: #1EBF8A !important;
    font-size: 12px;
    outline: none !important;
}

.navbar-nav > .active .nav-main-link:after {
    text-decoration: none;
    position: absolute;
    content: '';
    border-radius:0px;
    border-bottom:2px solid #1EBF8A;
    width: 20px;
    transform: translateX(-50%);
    bottom: 0px;
    left: 50%;
}

.mt-fund-submenu{
    min-width: 180px !important;
}

.research-submenu{
    min-width: 120px !important;
}

.nav-main-li.info{
    position: absolute;
    right: 0;
}

.nav-main-li.info .nav-main-link{
   color:#FFFFFF !important;
}

.nav-main-li.info .nav-main-link:after {
    border: none !important;
}

.nav-main-li.info .dropdown-menu{
    height: 300px;
    overflow-y: auto;
    border: none;
    right: 0px;
}
.navdisabled {
    opacity: 0.5;
    cursor: default;

}

/* ===================================================== */
/* dashboard */
.home-logo-parent{
    display:flex;
    align-items:flex-end;  
    width: 80%;   
    padding-left: 15px;
}

.home-logo {       
    /* height: 30px; */
    /* width: 100px; */
    width: 100%;
    margin-bottom: 7px;
}


/* ===================================================== */
/* dashboard */
.dashboard-body {
    padding:16px;
    overflow-y: auto;
}

.dashboard-row-holder {
    margin-left: 0px;
    margin-right: 0px;
}

.dashboard-row-holder:last-child{
    /* margin-bottom: 15px; */
}

.dashboard-row-content{
    /* padding: 0px 10px 0px 10px; */
    padding: 4px 4px 4px 4px;
    margin: 0px !important;
}

.dashboard-row-content2{
    padding: 4px 4px 4px 4px;
    margin: 0px !important;
    width: 100%;
}

.dashboard-componentholder {
    min-width: 340px;
    /* margin-top: 4px; */
}

.dashboard-allholders {
    border:1px solid #3a3a3a;
    padding: 0px 10px 10px;
    background: #131314
}

.dashboard-allholders2 {
    border:1px solid #3a3a3a;
    padding: 5px;
    background: #131314
}

/* for dash board scroll  */
.dashboard-parent-holder{
    border:1px solid #3a3a3a;
    padding: 10px;
    background: #131314
}

.dash-content-scroller{
    padding: 5px 5px 5px 10px !important;
    margin-left: -10px !important;  
    margin-right: -10px !important;
    /* overflow-y: auto; */
    height: calc(100% - 50px);
}

.dash-content-scroller.scrolly {
    overflow-y: auto;
}

/* ===================================================== */
/* watchlist */
.watchlist-body {
    height:100%;
    padding: 0px;
}

.watchlist_small_widget {
    height: calc(100%);   
    overflow-x: hidden; 
}

.watchlist-title-holder {
    display: flex;
    align-items: center !important;
}

.watchlist-title-icon {
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
}

.watchlist-title-icon.select {
    color: #1EBF8A;
}

.watchlistoptions {
    float: right !important;
}


.watchlist_row {
    position: relative;  
    background-color: #131314;  
    height: 30px !important;
    padding-top: 0px !important;
    border: 4px solid #131314;
}

.watchlist_row:nth-child(odd) {
    background-color: rgba(58,58,58, 138);
}

.watchlist_row:nth-child(even) {
    background-color: #131314; 
}

.watchlist_row.draggable {
    cursor: move;
}


.watchlist_row2 {
    position: relative;       
    height: 35px !important;
}

.watchlist_row_blank {
    position: "absolute";
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    background-color: "#2d3436";
    border:'1px solid #ccc';
}

.nobackgroundbutton {
    background: transparent;
    border:0px;
}

.nobackgroundbutton:disabled {
    opacity: 0.1;
}

.hide {
    display: none;
}

.watchlist-content-holder {
    height: calc(100% - 55px);
   
}

.watchlist-container.big {
    overflow-y: auto;
    /* min-width: 1300px; */
}

.watchlist-container {
    overflow-y: auto;
    height: calc(100% - 25px);
    margin-top: 5px;
}

.watchlist-container2 {
    overflow-y: auto;
    height: calc(100% - 25px);
    margin-top: 5px;
    width: 100%;
}

.watchlist-container2.filter {
    overflow-y: auto;
    height: calc(100% - 54px);
    margin-top: 5px;
}

#dropdown-menu {
    position: absolute !important;
    transform: translate3d(-15px, 11px, 0px) !important;
    top: 0px !important;
    left: 10px !important;
    margin-top: 15px;
    /* position: relative; */
}

.watchlist-dropdown-menu {
    min-width: 155px !important;
    background-color: #5b5e63 !important;
    padding-right: 0 !important;
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
}

.watchlist-dropdown-menu > ul {
    padding-left: 10px !important;
}

.watchlist-dropdown-menu .tab-bar > li {
    margin-right: 10px;
}

.watchlist-dropdown-disabled {
    opacity: 0.3;
}

.mobile-group {
    float: right;
}

.mobile-group-separator {
    background: #95969b !important;
    height: 2px !important;
}

.dynamic-columns {
    overflow-x: scroll;
    white-space: nowrap;
    border-left: 3px solid #050a11;
    height: auto;
}

.dynamic-column-margin {
    margin-bottom: 30px !important;
}


/* ===================================================== */
/* BaseWidget */

.small-widget {
    height:240px;
    overflow: hidden;
}

.hotnews-small-widget {
    height:225px;
    overflow: hidden;
}

.large-widget {
    height: 100%;
    min-height: 400px;
    overflow-y: auto;    
}

.widget-title {
    color: #AAAAAA;
    padding: 10px 0 10px 0px !important;
    font-size: 14px !important;
    /* font-weight: lighter; */
    font-family: 'Roboto' !important;
}

.widget-title-holder{
    display: flex;
    align-items: center;
}

.widget-title-component {
    float: left;
}

.widget-title-component.right {
    float: right !important;
}

.widget-title-component.marginleft {
    margin-left: 20px;
}

.widget-title-component > .toggle-switch {
    background: #42454A;
    padding: 3px;
}

.widget-title-component.order-toggle .toggle-button {
    color:#eee !important;
    background: #42454A !important;
}
  
.widget-title-component.order-toggle .toggle-button.active {
    color:#1EBF8A !important;
    background: #292A2E !important;
}

.expander-icon {
    cursor: pointer;
}

.widget_scroll {
    overflow-y: hidden;
}

.widget_no_scroll {
    overflow-y: auto;
}

.hdivider {
    border-bottom: 1px solid #3a3a3a;
    height: 1px;
    margin-right: -7px;
    margin-left: -7px;
    margin-bottom: 10px;    
    padding:0px;
}

.contentpanel {
    margin: 0px;
    padding:0px;
}

.widget-progress-holder { 
    text-align: center;
    width: 100%;
    font-size:25px;
    color: "#1EBF8A";
}

.widget-progress-holder2 { 
    
    width: 100%;
    height: 100%;
    color: "#1EBF8A";
    display: flex;
    align-items: center;
    justify-content: center;
}

.widget-error-holder {    
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100%;
    font-size:12px;
}

.sw-display-sym {
    width: 100%;
    white-space:'nowrap' !important;
    overflow:'hidden' !important; 
    text-overflow: 'ellipsis';
}

.sw-display-sym:hover {

}

/* ===================================================== */
/* TopGainersLosers */
.topgainerslosers-header {
    color:#aaa;
    font-size: 11px;
    /* font-weight: lighter; */
    margin-bottom: 20px;
}

.gainIndicesDrop .dropdown-menu{
    max-height: 250px;
    overflow-y: auto;
    overflow-x: hidden;
}
.topgain-head-pad{
    padding: 0px !important;
    padding-left: 9px !important;
}

.topgainerslosers-row {
    color:#fff;
    font-size: 14px;
    margin-bottom: 8px;
}

.topgainerslosers-row2 {
    color:#fff;
    font-size: 14px;
    margin-bottom: 5px;
}

.topgainerslosers-row2:nth-child(odd) {
    background-color: rgba(58,58,58, 138);
}

.topgainerslosers-row2:nth-child(even) {
    background-color: #131314; 
}

.topgainerslosers-row2-cell {
  padding-top: 5px;
}

.tgl-ltp {
   margin-top: 1px;
    /* border-left: 1px solid #131314;
    border-right: 1px solid #131314; */
}



.topgainerslosers-btn-holder {
    display: flex;
    margin:0px;
    padding: 0px;
}

.topgainerslosers-btn {
    width:100%;    
    color: #111111;
    font-size: 12px !important;
    background-color: #aaaaaa;
    border: 0px;
    padding:5px 0px;    
    margin-right: 7px; 
}

.topgainerslosers-btn.chart {
    /* background-color: rgb(48, 46, 46); */
    background-color: transparent;
    height: 27px;
    border: 1px solid rgb(91, 94, 99);
    padding: 3px;
}

.topgainerslosers-btn.watch2 {
    /* background-color: rgb(39, 151, 114); */
    /* background-color: #1EBF8A; */
    background-color: transparent;
    color: #eeeeee;
    font-size: 14px !important;
    /* padding: 3px 0px !important; */
    /* padding-top: 3px !important;
    padding-bottom: 13px !important; */
    height: 27px;
    border: 1px solid rgb(91, 94, 99);
    padding: 3px;
    padding-bottom: 8px;
}

.topgainerslosers-btn.watch2-symbolsearch {
    background-color: transparent;
    color: #eeeeee;
    font-size: 14px !important;
    height: 27px;
    border: 1px solid rgb(91, 94, 99);
    padding: 3px;
    padding-top: 2px;
    padding-bottom: 10px;
}

.topgainerslosers-btn.buy {
    background-color: rgb(22, 109, 190);;
    color: #eeeeee;
}

.topgainerslosers-btn.sell {
    background-color: #E5283F;
    color: #eeeeee;
}

.topgainerslosers-btn:disabled {
    opacity: 0.3;
}

.indicesDropdown_minwidth {
    width: 120px;
}

@-moz-document url-prefix() {
    .indicesDropdown_minwidth li {
        min-width: 160px;
    }
}


/* ===================================================== */
/* Pending Orders */

.pendingorders-header {
    color:#aaa;
    font-size: 11px;
    /* font-weight: lighter; */
    margin-bottom: 20px;
}

.pendingorders-row {
    color:#fff;
    font-size: 14px;
    margin-bottom: 8px;
}

.pendingorders-head-pad{
    padding: 0px !important;
    padding-left: 10px !important;
}

.exchange {
    color: #999999 !important;
    font-weight: lighter !important;
    font-size: 12px;
}

.pending-orders-btn-holder {
    display: flex;
    margin:0px;
    padding: 0px;
}

.pending-orders-btn {
    width:100%;    
    color: #EEEEEE;
    font-size: 12px !important;
    background-color: #1EBF8A;
    border: 0px;
    padding:0px;   
    margin:1px; 
}

.pending-orders-btn:focus {
    background-color: #1EBF8A;
}

/* ===================================================== */
/* WorldMarketSummary */
.cardBody {
    background-color: #262A30;
}

.cardHeader {
    background-color: #42454A;
    padding: 4px;
}
.card {
    border: none;
    margin-bottom: 7px;
    cursor: pointer;
}

.wms_accordion_header {
    background: #42454A;
    padding:5px !important;
    margin:0px;
    height:auto;
    font-size: 14px;
    cursor: pointer !important;
}

#accordion>.panel{
    border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    outline: none;
    border: none !important;
    background-color: #262A30;
}

.accordion-small{
    height: 83%;
    overflow-y: auto;
}

.accordion-large{
    min-height: 340px;
}

.worldmarketsummary-header {
    color:#aaa;
    font-size: 11px;
    /* font-weight: lighter; */
    padding-top: 5px;
    padding-bottom: 15px;
}

.worldmarketsummary-header > *:not(:last-child){
    padding-right: 5px !important;
}

.worldmarketsummary-row {
    color:#fff;
    font-size: 14px;
    margin-bottom: 20px;
}

.worldmarket-accordion{
    height: 100%;
    overflow-y: auto;
}

.worldmarketsummary-row > *:not(:last-child){
    padding-right: 5px !important;
}


.panel-group{
    margin-bottom: 0px;
}

.panel-group.scrolly {
    height: 100%;
    overflow-y: auto;
}

/* ===================================================== */
/* HotNews */

.news-parent{
    padding: 10px 0px 0px;
    height: 100%;
    overflow-y: auto;
}

.hotnews-parent{
    height: 100%;
    overflow-y: auto;
}

.hotnews-row {
    margin-bottom: 8px;
    padding-right: 15px;
}

.news-headline {
    color: #EEEEEE;
    font-size: 12px;
}

.hotnews-li{
    display: block;
}

.hot-news-panel{
    display: inline-block;
    color: #EEEEEE;
    font-size: 14px;
}

.hot-news-panel.small{
    font-size: 12px;
    height: 12px;
    line-height: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    text-decoration: none;
}

.hot-news-panel:hover{
    color: #EEEEEE;
}

.hot-news-panel.small:before{
    font-size: 12px;
}

.hot-news-panel.red:before{
    color: #E5283F;
}

.hot-news-panel.green:before{
    color: #1EBF8A;
}

.hot-news-panel:before{
    content: " \25CF";
    font-size: 14px;
    padding-right: 7px;
}

.hot-news-panel:focus {
    color: #EEEEEE !important;
    text-decoration: none;
}

.hot-news-accordion {
    background-color: #42454A;
    border-radius: 0 !important;
    padding-left: 10px;
    margin: 0 15px 5px 15px;
}

.hot-news-accordion > p {
    font-size: 14px;
}

.hot-news-arrow{
    padding-left: 5px;
    font-size: 18px;
    transform: rotate( 0deg );            
    -moz-transform:    rotate( 0deg );            
    -ms-transform:     rotate( 0deg );            
    -o-transform:      rotate( 0deg );            
    -webkit-transform: rotate( 0deg );   
    transition:  transform 300ms ease;
    -moz-transition:    -moz-transform 300ms ease;
    -ms-transition:     -ms-transform 300ms ease;
    -o-transition:      -o-transform 300ms ease;
    -webkit-transition: -webkit-transform 300ms ease;
}

.hot-news-arrow.up{
    padding-right: 5px;
    font-size: 18px;
    transform: rotate( 180deg );            
    -moz-transform:    rotate( 180deg );            
    -ms-transform:     rotate( 180deg );            
    -o-transform:      rotate( 180deg );            
    -webkit-transform: rotate( 180deg );    
    transition:  transform 300ms ease;
    -moz-transition:    -moz-transform 300ms ease;
    -ms-transition:     -ms-transform 300ms ease;
    -o-transition:      -o-transform 300ms ease;
    -webkit-transition: -webkit-transform 300ms ease;
} 

.hot-news-discussion{
    padding-left: 14px;
}

/* ===================================================== */
/* InvestmentOverview */
.investmentoverview-container {
    /* padding-top: 10px; */
}

.investmentoverview-header {
    color: #EEEEEE;
    font-size: 14px;
    /* font-weight: lighter; */
}

.investmentoverview-value {
    color: #EEEEEE;
    font-size: 18px;
    margin-left: 10px;
}

.investmentoverview-todays-pl{
    margin-left: 30px;
}

/* ===================================================== */
/* Ideas */
.ideas-sub-header {
    color:#aaa;
    font-size: 11px;
    /* font-weight: lighter; */
    margin-bottom: 5px;
    margin-top: 20px;
}

.ideas-sub-header.small{
    margin-top: 5px;
}

.ideas-row {
    color:#fff;
    font-size: 14px;
    margin-bottom: 8px;
}

.ideas-col {
    padding-top: 5px;
}

.ideas-date {
    color: #EEEEEE;
    font-size: 10px;
    font-weight: lighter;
}

.ideas-description {
    color: #1EBF8A;
    font-size: 12px;
    cursor: pointer;
}

.ideas-tab-content {
    padding: 10px;
}

.ideas-btn {
    width:80%; 
    height: 19px; 
    color: #111111;
    font-size: 10px !important;
    background-color: #aaaaaa;
    border: 0px;
    padding:0px; 
}

.ideas-btn.buy {
    background-color: rgb(22, 109, 190);;
    color: #eeeeee;
}

.ideas-btn.sell {
    background-color: #E5283F;
    color: #eeeeee;
}

.ideas-small-col {
    padding-top: 2px;
}

.tab-content.expanded {
    background-color: #262A30 !important;
    border-bottom: none;
    padding-top: 15px;
    margin: -1px 0 0 0 ;
}

.ideas-tab-bar {
    padding-left: 0 !important;
}

.ideas-radio-label {
    color:#FFFFFF;
    font-size: 14px;    
    padding-left: 27px;
}

.ideas [type="radio"]:checked ~ .ideas-radio-label {
    color: #1EBF8A; 
}

.ideas [type="radio"]:disabled ~ .ideas-radio-label {
   opacity: 0.2;
    cursor: default;
}

/* ===== */
/*login form */
.login-radio-label {
    color: #111111;
    font-size: 14px;
    padding-left: 27px;
}

.ideas [type="radio"]:checked ~ .ideas-radio ,
.ideas [type="radio"]:not(:checked) ~ .ideas-radio 
{
    cursor: pointer;
}

.ideas [type="radio"]:checked ~ .ideas-radio:before,
.ideas [type="radio"]:not(:checked) ~ .ideas-radio:before {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    /* border: 1px solid #42454A; */
    border: 1px solid #ccc;
    border-radius: 100%;
}


.ideas [type="radio"]:disabled ~ .ideas-radio {
    opacity: 0.3;
    cursor: default !important;
}


.ideas [type="radio"]:checked ~ .ideas-radio:after,
.ideas [type="radio"]:not(:checked) ~ .ideas-radio:after {
    content: '';
    width: 12px;
    height: 12px;
    background: #1EBF8A !important;
    position: absolute;
    margin: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.ideas [type="radio"]:not(:checked) ~ .ideas-radio:after {
    -webkit-transform: scale(0);
    transform: scale(0);
}

.ideas [type="radio"]:checked ~ .ideas-radio:after {
    -webkit-transform: scale(1);
    transform: scale(1);
}


.ideas [type="radio"] {
    opacity: 0;
}

.ideas-sort-drop-down {
    width: 120px;
}

.ideas-filter-drop-down {
    width: 120px;
    float: right;
    margin-right: 40px;
}

#ideas-popover.popover.right>.arrow:after{
    border-right-color: #42454A;
}

#ideas-popover.popover.right>.arrow {
    border-right-color: #42454A;
  }

/*=====About US=====*/

.aboutus-base {
    height: 100%;
    overflow-y: auto;
}

.aboutus-content {
    height: calc(100% - 40px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.aboutus-bottom{
    font-size: 10px;
    height: 40px;
    width: 100%;
    display: flex;
    align-items: flex-end;
}

.aboutus-first-title{
   color: #1EBF8A;
   font-size: 20px;
   font-weight: bold;
   font-style: italic;
   text-align: center;
}

.aboutus-first-title>img{
    width: 14%;
    /* height: 73px; */
}

 .aboutus-knowmore{
    color: #1EBF8A;
    font-size: 16px;   
    text-decoration: underline;  
    text-decoration-color: #1EBF8A;   
    -webkit-text-decoration-color: #1EBF8A;         
 }
 .aboutus-text-width{
     width: 230px;  
 }

 .aboutus-link{ 
    background: transparent !important;
    border: none;
    color: #1EBF8A !important;       
    text-decoration: normal;  
    text-decoration-color: #1EBF8A;   
    -webkit-text-decoration-color: #1EBF8A;     
 } 

 /* ============contact-us===================== */

 .term-condition-heading {
     padding: 30px;
     font-size: 14px;
     color: #fff;
     line-height: 1.8;
     border-bottom: 1px solid #3e3c3c;
 }

 .term-condition-holder {
     width: 100%;
     margin: 0 auto;
     background-color: #363636;

 }
 .term-condition-content {
    background-color: #42454a;
    width: 95%;
    margin: 0 auto;
    padding: 1px;
 }
 
 .contactus-content-holder{
    background: rgb(54, 54, 54);
    margin-left: 20%;
    margin-right: 20%;
    width: 60%; 
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
 }
 .contactus-data-holder{              
     margin-left: 50px;
     margin-right: 50px;     
 }
 .contactus-top-title{    
    margin-bottom: 5px;
    margin-left: 50px;
    margin-right: 50px;
    padding-top: 20px;
    font-size: 14px;   
    line-height: 1.7;
 }
 .contactus-title{
     color:white;
     font-size: 16px;     
 }
 .contactus-content-body{
    font-size:14px;
    line-height: 1.8;
 }
 /*===Terms and Condition===*/ 
 .tac-column{   
    margin-left: 30px;  
}
/*===Important Links===*/ 
.importantlink-item{        
    text-decoration: normal;  
    font-size: 16px;   
    color:white;
    text-decoration-color: white;  
    -webkit-text-decoration-color:white;
}
.importantlink-column{
    margin-top: 20px;
}
/*===========Corporate Action=======*/
.cp-content-holder{
    padding-bottom: 1pc;
    padding-left: 10px;
    padding-top: 10px;  
    background: #262A30;
    height: 100%;
}

.cp-header {
    color:rgba(255, 255, 255, 0.336);    
    font-size: 11px !important; 
}

.cp-content-holder .hdivider{
    margin-bottom: 0px !important;
}

.cp-action-header{
    margin: 0px 0px 10px 0px;  
    display: flex;
    align-items: center;
}

.cp-search-btn {
    width:100%;    
    color: #EEEEEE;
    font-size: 12px !important;
    background-color: #1EBF8A;
    border: 0px;
    padding:0px;   
    margin:1px; 
    height:30px;
}

.cp-search-btn:focus{
    background-color: #1EBF8A;
}

.cp-table-content{    
    overflow-y: auto;          
    margin-top: 5px;
    white-space: nowrap;
    height: calc(100% - 80px);
}

.cp-header-content{         
    margin-top: 5px;
    white-space: nowrap;
}

.cp-table-column{    
    overflow: auto; 
    white-space: nowrap;    
    padding-left: 50px;
    padding-right: 50px;
}

.cp-dynamic-columns {
    overflow: auto;
    border-left: 7px solid #262A30;
    height: calc(100% - 5px);
    margin: 0px;
    padding: 0px;
}

.cp-row {
    font-size: 13px;
    color: #eee;
    height: 50px;
}

.cp-header-row{
    display: flex;
    align-items: center;
}

.cp-header-row .dropdown{
    background: #42454A; 
    padding: 2px 5px !important;
}

.cp-header-row .btn-group{
    background: #42454A; 
}

.cp-header-row .search-input{
    border-bottom: none !important;
    padding-top: 0px;
    padding-bottom: 0px;
}

.cp-header-span{
    padding-right: 10px;
}

.cp-left-pane{
    padding: 13px 0px;
    height: 100%;
}

.cp-custom-col {
    display: inline-block;
    width: 120px;
    vertical-align: middle;
    height: 100%;
    padding: 13px 5px 13px 5px;
}

.cp-addwatch-img {
    padding: 0px;
    width: 100%;
    cursor: pointer;
    height: 27px;
}

.cp-light-grey{
    background: rgb(46, 50, 58);
}

.cp-left-container {
    height: calc(100% - 5px);
    overflow-y: auto;
    overflow-x: hidden;
    /* tohide scrollbar */
    margin: 0px;
    padding: 0px;
    /* for ie it should be 18 */
    margin-right: -3px !important; 
    padding-right: -18px !important;
}

/* ===================================================== */

.checking {
    background: red;        
}
/*===Login Alert===*/ 
.loginAlert{
    width: 60%;
    padding: 15px;
    margin: auto;
    text-align: center;
    font-size: 16px; 
}
.first-div{
    background: rgb(43, 41, 41);
    color: 'white';
    font-weight: bold;
}
.second-div{
    color: rgb(171, 171, 173); 
    font-size: 16px;
}

.third-div{
    width: 60%;
    margin: auto;
    text-align: center;
    font-size: 16px;
    overflow-y: auto;
    height: calc(100% - 185px);
    background: rgb(43, 41, 41);
}
.content{
 text-align: left;
 font-size: 16px;
 padding: 10px;
 color: rgb(171, 171, 173);
 line-height:1.8; 
}
.heading{
font-size: 20px;
text-align: center;
color: 'white';
font-weight: bold;
padding-top: 13px;
}
/*===Market Timings===*/ 
.mt-np-tab-content-parent{
    background-color: #262A30;
    padding: 0px !important;
    height:calc(100% - 40px);
    overflow-y: hidden;
}
.mt-np-header-row {
    height: auto;
    border-bottom: 1px solid rgba(128, 128, 128, 0.459);
}
.headings{
    font-weight: bold;
    color: 'white;'
}
.anchor-link{
    color:#1EBF8A;
}
.mt-evenrow{
    background-color:rgb(46, 50, 58);
    height: 100%;  
    color: #ccc;
}
.mt-agripopup-header{  
    padding-left: 20px;
    padding-right: 10px;
    padding-top: 15px;
    padding-bottom: 15px;      
}
.mt-agripopup-list-header{
    background: rgb(53, 51, 51);
    font-size: 14px;
    color:green;    
    padding-top: 15px;
    padding-bottom: 15px; 
}
.mt-agripopup-footer{
    padding-left: 20px;
    padding-right: 10px;
    padding-top: 15px;
    padding-bottom: 15px; 
    text-align: left;        
}
.mt-agripopup-column{
    padding-bottom: 10px;
    padding-top: 5px;
}
.mt-row-header{
    padding: 10px 10px 10px 3px !important;
    height: 30px;
}
.mt-header-row {
    padding: 15px,15px, 15px, 15px;
    border-bottom: 1px solid rgba(128, 128, 128, 0.459);
}
.mt-head{
    display: flex;
    flex-direction: column;
    height: calc(100% - 100px);
    justify-content: space-between;
}

.mt-head.mt-curr{
    height: calc(100% - 60px);
}
.mt-head2{
    display: flex;
    flex-direction: column;
    height: calc(100% - 30px);
    justify-content: space-between;
    overflow-y: auto;
    overflow-x: hidden;
}
/*PreLogin*/
.login-tc-header{
    color: rgb(150, 147, 147);
    font-size:12px;
    padding-top: 5px;
    padding-bottom: 10px;
    padding-left:20px;
    padding-right:15px; 
    text-align: left;
}
.login-tc-content{
    color:white;
    font-size:12px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left:20px;
    padding-right:15px; 
    text-align: justify;
    
}
.rdd-btn-continue{
    padding-left: 15px;
    padding-right: 15px;    
    padding-top: 5px;
    padding-bottom: 5px;
    background: gray;
    border:none;
    font-size: 12px;
    color:white;
    font-weight: bold;
    margin-right: 5px;
}
.rdd-btn-continue:active ,.rdd-btn-continue:focus{   
    color: #fff;
    border: 1px solid rgb(27, 158, 55);
}
.risk-disclosure-content{
   background: rgb(114, 111, 111);
   padding-left: 15px;
   padding-right: 15px;  
   line-height: 1.8;
   font-size: 14px;
   color: white; 
}
/*Passwordg Guidelines*/
.pg-header{
    text-align: left;
    padding: 5px;
    margin: 3px;
}
.pg-content{
    margin:10px;
   text-align: left;
}
/*Adhar verification*/
.adhar-tc-header{
    color:rgb(241, 78, 78);
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 10px;
    background: #3a3939;
}
.adhar-content{
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;        
}
.adhar-column{
  padding-top: 10px;
  padding-bottom: 10px;
}
.adhar-no-holder{
    padding-top: 5px;
    padding-bottom: 5px;
    background: gray!important;
    border: none;
    color:white;    
}
.adhar-tc-box{
    border: 1px solid gray;
    min-height: 150px;   
}
.adhar-check-label{
    font-size: 14px;    
    padding-left: 15px;
    padding-top: 3px;
    color: rgba(255, 255, 255, 0.336);
}
.adhar-tc-content{
    padding:5px 5px 5px 5px;
    line-height: 1.8;
    font-size: 12px; 
}
.ecn-footer{   
    padding-top:9px;
    padding-bottom: 5px;
    float: right !important;    
}
.ecn-text{
    font-size:12px;
}
.otp-verify-holder{
    background: rgb(66, 65, 65);
    min-height: 70px;
}
.email-verify-holder{
    background: rgb(77, 75, 75);
    min-height: 70px;
}
.otp-column{
    padding-top: 10px;
}

 input[type=number]::-webkit-inner-spin-button,
 input[type=number]::-webkit-outer-spin-button{
     -webkit-appearance: none;
     margin: 0;
 }

 /*=== Security Info Popover ===*/ 
.securityinfo-header {
    font-size: 14px;
    border-bottom: 1px solid #3a3a3a;
    padding-top: 5px;
    padding-bottom: 5px;
}

.secinfo-content {
    border-bottom: 1px solid #3a3a3a;
    padding: 10px 0 10px 0;
    width: 48%;
}

.secinfo-content-full {
    border-bottom: 1px solid #3a3a3a;
    padding: 10px 0 10px 0;
}

.secinfo-key {
    font-size: 11px;
    text-align: left;
    color: rgba(255, 255, 255, 0.336);
}

.secinfo-value {
    font-size: 11px;
    text-align: right;
}

.securityinfo-header-key {
    color: rgba(255, 255, 255, 0.336);
}

.secinfo-content-right {
    float: right;
}

/*=== Notifications ===*/ 
.notify{
    font-size: 14px;
    color: #fff;
    margin-left: 20px;
}
.ord-notify{
    font-size: 14px;
    color: #fff;
    margin-left: 20px;
    display: flex;
    align-items: baseline;
    overflow-y: auto;
}
.time-data{
    font-size: 12px;
    color: rgb(171, 171, 173);
    margin-left: 20px;
     /* padding-left: 30;  */
    line-height: 3rem;
 }
.icon-clk{
    width: 12px;
    margin-right: 3px;
}
.alrt-clr{
    color: rgb(171, 171, 173);
}
.edit-alrt-btn{
    color: #1EBF8A ;
    background: rgb(66, 65, 65);
    border: 1px solid #1EBF8A ;
    height: 20px;
    width: 45%;
}
.delete-alrt-btn{
    color:#fff ;
    background: rgb(66, 65, 65);
    border: 1px solid #fff ;
    height: 20px;
    width: 45%;
}
.alrt-btn-container{
display: flex;
justify-content: space-around;
}
.buy-bar{
    width:3px;
    background:blue; 
    height:12px;
    margin-right:10px;
}
.sell-bar{
    width:3px;
    background:rgb(17, 228, 27); 
    height:12px;
    margin-right:10px;
}
/*----AlertPopUp----*/
.modal-dialog.alertPop {
    width: 850px;
    height: auto;
    min-height: 500px;
    top: 5%;
    font-size: 14px;
    background: rgb(43, 44, 49);
    border: 2px solid #585757;
    }
    .modal-dialog.alertMsg {
        width: 600px;
        height: auto;
        min-height: 600px;
        top: 5%;
        font-size: 14px;
        background: rgb(43, 44, 49);
        border: 2px solid #585757;
        text-align: center;
        }
     .delete-back{
            border: 1px solid #1EBF8A;
            width: 150px;
            background: #656469;
            
        }
    .delete-okay{
            border: 1px solid red;
            width: 150px;
            background: #656469;
            
        }
    .alertDropdown{
        background: #656469;
        height: 30px;
        padding: 3px;
        margin-top: 10px;
    }
    .edit-alrt-pop-btn{
        color: #1EBF8A ;
        background: rgb(66, 65, 65);
        border: 1px solid #1EBF8A ;
        height: 25px;
        width: 32%;
    }
    .delete-alrt-pop-btn{
        color:#fff ;
        background: rgb(66, 65, 65);
        border: 1px solid #fff ;
        height: 25px;
        width: 32%;
    }
 /* ===================================================== */
/*=== Charts ===*/ 
.chart-header {
    background-color: #262A30 !important;
}

.chart-expander {
    padding: 10px 15px 0px 0px !important;
}

.chart-content-holder {
    height: 100%;
}

.charts-allholders {
    border: 1px solid #3a3a3a;
    padding: 5px;
    background: #131314;
}

.chart-iframe {
    width: 100%;
    height: 100%;
    background: transparent;
}

/* ===================================================== */
/*=== Products - IPO ===*/ 
.products-header {
    color:#aaa;
    font-size: 11px;
    /* font-weight: lighter; */
    margin-bottom: 20px; 
    margin-top: 20px;
}

.products-white-content {
    color: #eee !important;
}

.products-row {
    color: #fff;
    font-size: 13px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.products-ipo-row {
    color: #fff;
    font-size: 13px;
}

.info-icon {
    margin-right: 15px;
    cursor: pointer;
}

.info-icon.important-links {
    width: 15px;
    margin-left: 5px;
    margin-top: -5px;
}

.ipo-link > a {
    color: #1EBF8A !important;
    text-decoration: underline;
    cursor: pointer;
}

.products-popover {
    background-color:#42454A !important;
    min-width: 400px;
    padding: 0px;
    border-radius: 0;
}

.products-popover.bottom>.arrow:after{
    border-bottom-color: #42454A;
  }

.products-popover.bottom>.arrow {
    border-bottom-color: #42454A;
}
  

.products-popup-divider {
    margin-top: 20px;
    margin-bottom: 20px;
}

 /* ===================================================== */
/*=== Products - SEP ===*/ 
 .sep-toggle {
    margin-top: 10px;   
}

.sep-header {
    height: 50px;
    margin-bottom: 10px;
}

.sep-content {
    background: #262A30;
    min-height: 500px;    
}

.sep-dropdown {
    background: #42454A;
    padding: 5px;
    height: 35px;
    margin-top: 5px;
    border: 1px solid #eee;
}

.sep-row {
    margin-top: 30px;
    font-size: 14px;
    color: #aaa;
}

.sep-datepicker {
    margin-top: 30px;
}

.sep-notes {
    background: #2e323a;
    padding: 15px 15px 0px 30px;
    min-height: 450px;
}

.sep-noteblock {
    font-size: 14px;
    margin-top: 20px;
    padding-left: 30px;
}

.sep-notes > ul {
    padding: 0 !important;    
    margin-top: 15px;
}

.sep-notes > ul > li {
    margin-bottom: 15px;
}

.sep-cancel {
    margin-right: 20px;
    padding: 5px 15px 5px 15px;
    width: 120px;
    border: 1px solid #fff;
}

.sep-cancel:active, .sep-cancel:focus {
    outline: 0 none;
    border: 2px solid #1EBF8A !important;
}

.sep-btns {
    margin-top: 45px;
    margin-left: 0px;
}

.sep-small-font {
    font-size: 11px;
    margin-top: 5px;
}

.sep-reg-no {
    text-align: center;
    margin-top: 20px;
    font-size: 16px;
}

.sep-search-btn {
    margin-top: 20px;
    height: 30px;
    min-width: 70px;
}

.sep-delete-btn {
    background: transparent;
    color: #1EBF8A;
    border: 1px solid #1EBF8A;
    margin-left: 20px;
    height: 30px;
}

.sep-modal {
    position: absolute;
    top: 24% !important;
    right: 0;
    left: 0;
}

.sep-popup-opacity {
    opacity: 0.1;
}

.sep-exe-row {
    color: #fff;
    font-size: 14px;
    padding: 12px 0px 12px 15px !important;
}

/* ===================================================== */
/*-----Products OFS popup------------*/
.prod-headings{
    color:rgb(171, 171, 173);
}
.prod-value{
    margin-top: 10px;
    font-size: 18px;
}
.prod-footer{
    display: flex;
    justify-content: center;
    margin-bottom: 10%;
}
.prod-receive-popup{
    width: 550px;
    min-height: 250px;
    top: 10%;
    background: rgb(43, 44, 49);
    border: 2px solid #585757;
   
}
.arrow-btn{
    border: 0;
     background: transparent;
}
.arrow-btn-disabled{
    background: transparent;
    border: 0;
    opacity: 0.1
}
.prod-blue-btn{
    background: rgb(22, 109, 190);
    width: 100px;
}

.div-ellipse{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.chartimg{
    padding: 0px;
    width: 100%;
    cursor: pointer;
    height: 20px;
}

.toggle-switch [type="button"]:disabled ~ .order-button{
    opacity:0.7;
}

.padding-left-30{
    padding-left: 30px;
}