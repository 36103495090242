.np-header-row {
    padding: 15px;
    border-bottom: 1px solid rgba(128, 128, 128, 0.459);
}

.equity-pt-drop-down {
    /* float: left;
    width: 120px;
    margin-left: 40px; */
}

.transfer-log-parent {
    padding: 10px 0px;
}

.transfer-log-content {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100% - 85px);
}

.transfer-log-header {
    display: flex;
}

.transfer-log-hd-row {
    padding: 15px 0px 5px 0px;
    color: #AAAAAA;
    font-size: 11px;
}

.transfer-log-header .transfer-log-drdw {
    margin-right: auto;
}

.tranfer-log {
    float: right;
    height: auto !important;
    padding: 4px 2px;
    line-height: 12px;
    outline: none;
}

.tranfer-log.active {
    border: none;
    background: #1EBF8A;
}

.tranfer-log.active:after {
    text-decoration: none;
    position: absolute;
    content: '';
    bottom: -10px;
    width: 10px;
    margin-right: 20px !important;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #21262C;
    transform: translateX(-50%);
    right: 40px;
}

.np-accordion {
    background-color: #42454A;
    border-radius: 0 !important;
    padding: 0px !important;
    margin: 0px;
    font-size: 12px;
}

.np-acc-row-parent {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding-right: 8px;
}

.np-acc-row {
    padding: 5px 0px !important;
    display: inline-block;
}

.np-acc-row.head {
    color: #AAAAAA;
    font-family: "Roboto-Medium";
    font-weight: 600;
}

.np-acc-row.lite {
    color: #AAAAAA;
    font-family: "Roboto-Light"
}

.np-btn.np-accordion-btn {
    width: 65px !important;
}

.np-btn-parent {
    margin: 0px;
    padding: 0px 0px 0px 8px !important;
    justify-content: center;
}

.np-btn {
    height: auto;
    padding: 4px 3px !important;
    outline: none;
    border: none;
    font-size: 11px;
}

.np-btn.disabled {
    opacity: .3;
}

.np-tab-content-parent {
    background-color: #262A30;
    padding: 0px !important;
    height: calc(100% - 40px);
}

.np-tab-content {
    height: 100%;
}

.np-row-header {
    padding: 0px 0px 0px 10px !important;
}

.np-row-symbol {
    padding: 0px 0px 0px 20px !important;
}

.np-row-header.lite {
    color: #AAAAAA;
    font-size: 11px;
}

.np-row {
    color: #fff;
    font-size: 14px;
    padding: 0px 5px !important;
}

.np-row.exc {
    color: #AAAAAA;
    padding-left: 15px !important;
}

.np-row-content {
    padding: 15px 0px 13px 0px !important;
}

.np-row-btn {
    display: flex;
    justify-content: flex-end;
}

.expand-btn-parent {
    display: flex;
    justify-content: flex-end;
    padding-right: 30px;
}

/* ---------------------------------*/

/*fno */

.fno-drop-down {
    display: flex;
    justify-content: flex-end;
}

.np-fno-toggle .toggle-switch .toggle-button:first-child {
    padding: 0px 25px !important;
}

.np-fno-toggle .toggle-switch .toggle-button:last-child {
    padding: 0px 50px !important;
}

/*-----------------------------*/

/*transfer popover */

.transfer-popover {
    width: 500px !important;
    max-width: 500px;
    border: none;
    border-radius: 0px;
    background: #42454A;
    z-index: 1500 !important;
}

.transfer-sym {
    display: block;
    font-size: 14px;
    padding: 0px 0px 5px 0px;
    font-family: "Roboto-Medium"
}

/* .transfer-sym-row:first-child{
    padding: 5px 10px 5px 0px;
} */

.transfer-sym-row:last-child {
    padding: 5px 0px 5px 10px;
}

.transfer-sym-row.head {
    color: #AAAAAA;
}

.transfer-type {
    display: inline-block;
    width: 100%;
    padding: 10px;
    background: #4B4E53;
    text-align: center;
}

.transfer-sym-row .np-btn {
    padding: 10px !important;
}

.transfer-img {
    width: 100px;
    height: 100px;
    margin: auto;
    margin-top: 15px;
    margin-bottom: 15px;
}

.transfer-ok {
    width: 100px;
    margin: auto;
    font-family: "Roboto-Medium";
    font-size: 14px;
    margin-top: 15px;
}

.transfer-popover.bottom>.arrow:after {
    border-bottom-color: #42454A;
}

.transfer-popover.top>.arrow:after {
    border-top-color: #42454A;
}

.transfer-popover.left>.arrow:after {
    border-left-color: #42454A;
}

.header-np {
    font-size: 10px;
    color: #86878A;
}

.equity-pt-drop-down .dropdown-menu {
    left: auto !important;
    right: 0;
}

.equity-pt-drop-down .dropdown {
    font-size: 10px !important;
}

.np-tab-content .toggle-switch {
    width: 70px;
}

.np-tab-content .toggle-switch>.toggle-button {
    font-size: 10px;
}

.np-tab-content #drop-down {
    font-size: 10px
}

.np-buy {
    background: #1B9771;
    width: 100%;
}

.np-sell {
    background: #D0021B;
    width: 100%;
}

.buysell {
    width: 80%;
}

.np-btn-parent>div {
    padding: 0px 10px 0px 0px;
}

.squareoff-btn {
    background-color: #D47A22;
    width: 80%;
}

.data-np {
    font-size: 12px;
    color: #FFFFFF;
    padding: 10px 5px 5px 5px;
}

.np-day-btn {
    background-color: #AAB0AD;
    background: rgba(0, 0, 0, 0.2);
    border: 1px solid #fff;
    color: #EEEEEE;
    height: 23px;
    font-size: 12px;
    display: block;
    width: 100%;
}

.np-day-btn.selected {
    outline: 0 none;
    border: 2px solid #1EBF8A !important;
}

.np-day-btn.disabled {
    color: #4B4E53;
    border-color: #4B4E53;
}

.position-holder .hdivider {
    padding-top: 5px;
}

.np-arrow .dropdown-menu {
    position: relative;
    background: #383B42;
}

.np-arrow .dropdown-menu:after, .np-arrow .dropdown-menu:before {
    bottom: 100%;
    left: 100%;
    border: solid transparent;
    content: " ";
    width: 0;
    position: absolute;
    pointer-events: none;
}

.np-arrow .dropdown-menu:after {
    border-color: rgba(136, 183, 213, 0);
    border-bottom-color: #383B42;
    border-width: 10px;
    margin-left: -10px;
    border-right: 30px;
}

.positions-details {
    background-color: #40464F;
    height: auto;
    min-height: 130px;
}

.np-transfer.symbol-data {
    padding: 20px;
}

.position-np {
    font-size: 11px;
    color: #86878A;
}

.positions-details>div:first-child {
    padding: 10px 15px 0px 15px;
}

.transfer-part{
    padding-top: 10px
}

.np-modal{
    width: 50%;
    height: auto;
    min-height: 420px;
    min-width: 510px;
    top: 30%;
    font-size: 12px;
    background: rgb(43, 44, 49);
    border: 1px solid #3a3a3a;
    -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.np-success{
    width: 35%;
    height: auto;
    min-height: 350px;
    min-width: 300px;
    top: 30%;
    font-size: 12px;
    background: rgb(43, 44, 49);
    border: 1px solid #3a3a3a;
    -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.equity-pt-drop-down.np-arrow .dropdown-menu {
    right: 75% !important;
}