.errorMsgNoData{
  border: 1px;
  width: 500px;
  font-weight: 600;
  background-color: antiquewhite;
  text-align: center;
  position: absolute;
  top: 40%;
  left: 50%;
  -ms-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
}

#title {
      text-align: center;
      font-family: arial, sans-serif;
}

#summaryid {
      font-size: 12px;
      text-align: center;
      font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
      border-collapse: collapse;
      width: 53%;
}
#detailsid {
      font-size: 12px;
      text-align: center;
      font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
      border-collapse: collapse;
      width: 100%;
}

#summaryid td, #summaryid th {
  border: 1px solid #ddd;
      padding-top: 8px;
      padding-bottom: 8px;
      white-space:nowrap;
}

#detailsid td, #detailsid th {
  border: 1px solid #ddd;
      padding-top: 6px;
      padding-bottom: 6px;
}

#summaryid tr:nth-child(even){background-color: #f2f2f2;}

#summaryid tr:hover {background-color: #ddd;}

#detailsid tr:nth-child(even){background-color: #f2f2f2;}

#detailsid tr:hover {background-color: #ddd;}

#detailsid th {
  border: 1px solid #ddd;
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: center;
  background-color: grey;
  color: white;
}

#summaryid th {
  border: 1px solid #ddd;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  background-color: grey;
  color: white;
}

.config-err-title {
  color :rgb(97, 100, 104);
  font-size: 18px;
}

.config-err-ref {
  width: 100%;
  color:#131314;
  font-size: 12px;
}



h1 {
  margin: 0;
  padding: 0;
}

html, body, .app {
 
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
  height: auto;
}
.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0,0,0, 0.5);
}
.popup_inner {
  position: absolute;
  left: 25%;
  right: 25%;
  top: 25%;
  bottom: 25%;
  margin: auto;
  background: white;
}


#data-main .icon-display {
  display: inline;
}

#data-main:hover .icon-display {
  display: none;
}