
.unsupported_div {
    position: fixed;
    width : 33%;
    top:30%;
    left:33%;
    padding: 20px;
    background: #e96565;
    color: #d9d9d9;
    font-size: 18px;  
    text-align: center;
    font-weight: lighter;
  }

  