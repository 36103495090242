
.ofs-tab-content {     
  background-color: #262A30;
  height: calc(100% - 45px);
  overflow-y: auto;
}

.ipo-tab-content{
  background-color: #262A30;
  overflow-x: hidden;
  height: calc(100% - 30px);
  overflow-y: hidden;
  padding: 10px;
}

.ipo-tab-content .ipo-scroll{
  height: calc(100% - 70px);
  overflow-y: auto;
  overflow-x: hidden;
}




/* =================================================== */

.basket-btn{
    background: rgb(56, 55, 55);
    border: 1px solid green;
    font-size: 12px;
    font-weight: bold;
    padding: 2px;
}
.basket-log-btn{
    background:transparent;
    border: 1px solid rgb(201, 198, 198);
    font-size: 12px;
    font-weight: bold;
    padding: 2px;
}
.basket-delete-btn{
    background-color: #232428;
    border: 1px solid rgb(136, 39, 39);
    font-size: 12px;    
    padding: 2px 25px 2px 25px;
    color:rgb(136, 39, 39);
}
.product-modal-header{
    border-bottom: 1px solid rgb(80, 80, 80);
    font-weight: lighter !important; 
    font-size: 14px;
    padding: 10px 10px 10px;
    vertical-align: bottom;
    text-align: center;
  }
  .create-basket-btn{
    background-color:#00B772;
    border: none;
    font-weight: bold;
    font-size: 14px;    
    padding: 5px 55px 5px 55px;
    color:white;
  }
  .create-basket-row{
    color: #fff;
    font-size: 13px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .execute-basket-header {
    color:#aaa;
    font-size: 11px;
    /* font-weight: lighter; */
    padding-top: 10px;
    padding-bottom: 10px;
}
.execute-basket-hdivider {
    border-bottom: 1px solid #575252;
    height: 1px;
    margin-right: -7px;
    margin-left: -7px;
    margin-bottom: 10px;    
    padding:0px;
}
.execute-basket-btn{
    background-color:#004F89;
    border: none;
    font-weight: bold;
    font-size: 14px;    
    padding: 5px 55px 5px 55px;
    color:white;
  }
  .basket-footer-btn{
    background-color:#737478 !important;
    background: rgba(0,0,0,0.2);
    border:none;
    color:#EEEEEE; 
    font-size: 14px;
    font-weight: bold;
    width: 85%;
    padding: 5px;  
  }
  .basket-execution-hide{
    display: none;
  }
  .basket-execution-show{
    display: block;
    opacity: 1;
  }