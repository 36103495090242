
/* ===================================================== */
/* css related to quote colors */

.positive-change {
    color: #1EBF8A !important;
}

.negative-change {
    color: #E5283F !important;
}

.positive-change-blue {
    color: rgb(22, 109, 190);
}

.positive-change-bg {
    /* background-color: #142c1f; */
    /* background-color: rgba(22, 112, 190, 0.404); */
    /* background-color: #1EBF8A; */
    animation-name: neg-pos-bg;
    animation-duration: 0.5s;
    animation-fill-mode:both;
    padding: 3px;
}

.negative-change-bg {
    /* background-color: #3d131c; */
    /* background-color: rgba(229, 40, 65, 0.404); */
    /* background-color: #E5283F; */
    animation-name: pos-neg-bg;
    animation-duration: 0.5s;
    animation-fill-mode:both;
    padding: 3px;
}


.no-change-bg {
    background-color: transparent;
    padding: 3px;
}

/* The animation code */
@keyframes pos-neg-bg {
    from {background-color: transparent;}
    to {background-color: #E5283F;}
}

@keyframes neg-pos-bg {
    from {background-color: transparent;}
    to {background-color: #1EBF8A;}
}


.positive-change-bar {
    background-color: #142c1f;
}

.negative-change-bar {
    background-color: #3d131c;
}

.volume-bar {
    background-color: rgb(22, 109, 190);
}

/* ===================================================== */