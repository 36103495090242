
.margin-vert-tab-holder {
    /* border-right: 1px solid rgba(128, 128, 128, 0.068); */
    padding-left: 0px;
    padding-right: 0px;
}

.margin-vert-tab {
    list-style-type: none;
    margin:0px !important;
    padding: 0px;
    height: 100%;
}

.margin-vert-tab a {
    padding: 15px;
    border-bottom: 2px solid #35363A;    
}

.margin-vert-tab>a.active {
    border-right: transparent;
}

.margin-vert-tab a {
    display: block;
    color:#fff; 
    text-decoration: none;    
}
a#DERIVATIVE-MARGIN-DETAILS, 
a#EQUTIY-MARGIN-DETAILS {
    background-color:#21262c;
    cursor: pointer;
}

.margin-vert-tab> li.active>a {
    color:#1EBF8A !important; 
    background-color: #21262c;
    border: none;
    border-bottom: 2px solid #35363a;
}

.margin-tab-content {    
    background-color: rgb(33, 38, 44);
    padding:0px !important;
    height: 560px;
    overflow-y: auto;
}

.margin-tab {    
    background-color: rgb(33, 38, 44);
    padding:0px !important;
    height: calc(100% - 35px);
    overflow-y: auto;
}

.margin-content {
    overflow-y: hidden;
}

.margin-header-row {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    border-bottom: 1pt solid rgba(128, 128, 128, 0.068);
}

.margin-row {
    color:#fff;
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 10px;
    height: 60px;
    border: none;
    border-bottom: 1pt solid rgba(128, 128, 128, 0.192);
}

.margin-column{
    margin-top: 20px;
}
.margin-border{
    border:1px solid #3a3a3a;
    padding: 15px 10px 10px;
    overflow-y: hidden;
}
.margin-fno-header-row {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    font-size: 14px;
    color: rgb(124, 124, 124);
}
.derivative-tabs-header{
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    font-size: 14px;
    color:#1EBF8A; 
    font-weight: bold;
}
.derivative-total-divider{
    padding-top: 20px !important;
}
.margin-fno-footer-row {
    background-color: rgba(128, 128, 128, 0.192);
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    font-size: 14px;
    color: #fff;
}

.margin-equity-row {
    color:#fff;
    font-size: 12px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.margin-fno-row {
    font-size: 12px;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    color: rgb(124, 124, 124);
}

.margin-util-header-row {
    font-size: 12px;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    background-color: rgba(255, 255, 255, 0.1);
}

.margin-util-row {
    font-size: 12px;
    padding-top: 10px !important;
    padding-bottom: 10px !important;    
}

.margin-util-row-key {
    color:rgba(255, 255, 255, 0.336);
}

.margin-info {
    width: 13px;
    height: 13px;
}