
.calculators-tab-content {
    height: calc(100% - 50px);
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 0px !important;
}

.calc-header {
    margin-left: 10px !important;
    margin-top: 20px !important;
    margin-right: 10px !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    border-bottom: 1px solid #3a3a3a;
    font-size: 14px;
}

.result_holder {
    display: flex;
    align-items: center;
    background: #2b2b2b;
    height: 100%;
}

.opt-result-holder{
    background: #2b2b2b;
    height: 100%;
    padding-top: 60px !important;
}

.result_holder.span {
    padding-bottom: 15px;
}

.calc-result {
    color: #1EBF8A;
    font-size: 30px;
    width: 100%;
    text-align: right;
    padding-right: 15px;
    font-weight: bold;
}

.calc-result-fade {
    color: rgba(30, 191, 137, 0.2);
    font-size: 30px;
    width: 100%;
    text-align: right;
    padding-right: 15px;
    font-weight: bold;
}

.calc-result-tag {
    width: 100%;
    text-align: right;
    padding-right: 15px;
    font-size: 12px;
    color: #fff;
}

.calc-main {
    margin-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
}

.calc-input-holder {
   
    padding-right: 15px !important;
}

.calc-label {
    font-size: 12px;
    /* color: rgba(255, 255, 255, 0.658); */
    color: #aaa;
}

.calc-label.disabled {
    opacity: 0.2;
}

.calc-opt-label {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.658);
}

.calc-opt-res {
    font-size: 22px;
    margin-top: 0px;
}

.calc-row {
    margin-bottom: 20px !important;
}

.calc-row-2 {
    margin-top: 40px !important;
}


.calc-res-col {
    text-align: center;
    margin-bottom: 10px;
    padding: 10px;
}

.calc-input {
    font-size: 14px;
    color: #fff;
    background: rgba(0, 0, 0, 0.3);
    border: 1px solid rgba(0, 0, 0, 0.3);
    padding:4px;
    margin-top: 5px;
    border-radius: 0px;
}

.span-calc-drdn{
    background: #42454A;
    padding: 4px 8px !important;
    margin-top: 5px !important;
}

.span-header{
    padding: 10px 10px;
}

.calc-input.date {
    width: 100%;
}

.calc-btn {
    background: #1EBF8A;
    border:none;
    color:#EEEEEE;
    height: 30px;
    font-size: 12px;
    width: 90%;
}

.calc-btn:focus {
    background: #1EBF8A;
    border:none;
    color:#EEEEEE;
    height: 30px;
    font-size: 12px;
    width: 90%;
}

.calc-btn:disabled {
    opacity: 0.2;
}

.calc-progress-holder {
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100%;
    font-size: 25px;
}

.calc-contract-row-header {
    border-top: 1px solid #3a3a3a;
    border-bottom: 1px solid #3a3a3a;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.658);
}

.calc-contract-row {
    border-bottom: 1px solid #3a3a3a;
    font-size: 12px;
    color: #fff;
    padding-top: 10px;
}

.calc-contract-col-highlight {
    background: #2b2b2b;
    height: auto;
}

.calc-span-main {
    height: 100%;
    overflow-y: auto;
}

.calc-span-row {
    margin: 8px  0px !important;
}

.calc-span-first-row{
    margin: 40px 0px 8px 0px !important;
}

.calc-span-label {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.658);
    padding: 10px 5px !important;
}

.calc-span-result-holder {
    padding: 10px 5px !important;
    background: #404241;
    min-height: 20px;
}

.span-calc-flex{
    display: flex;
    flex-wrap: wrap;
    height: auto;
}

.span-calc-flex.no-wrap{
    flex-wrap: nowrap !important;
}

.span-check{
    font-weight: normal;
    padding-bottom: 0px !important;
    margin-bottom: 0px !important;
}

.span-option-parent.disabled{
    opacity: 0.4;
}

.span-minus-plus{
    margin-top: 5px;
}
.span-minus-plus .btn{
    height: 31px;
    padding-top: 3px;
}
.span-minus-plus .input-number{
    height: 31px;
}