.btn-btnbar{
    flex : 1 1 !important;
}

.modal-dialog {
    pointer-events: visible !important;
}

.tab-bar{
    position : relative;
}

.tab-top {
    padding-top: 4px;
}

.popper-inline{
    transform: none !important;
    top: 100% !important;
    will-change: transform;
}

.common-dropdown-divider.divider{
    height: 1px;
    margin: 9px 0;
    overflow: hidden;
    background-color: #e5e5e5;
}

.dashboard-padding{
    padding-left: 4px !important;
    padding-right: 4px !important;
}

.navbar-nav .dropdown-menu{
    position: absolute;
}

.nav>li>a{
    display:block;
}

.panel-body{
    padding: 15px;
}

.header-popper{
    transform: none !important;
    top: 100% !important;
    will-change: transform;
}

.card-header:first-child{
    border-radius: 0px;
}

.popover.bottom{
    margin-top : 10px;
}

.popover.bottom>.arrow {
    top: -11px;
    left: 50%;
    margin-left: -11px;
    border-top-width: 0;
    /* border-bottom-color: #999;
    border-bottom-color: rgba(0,0,0,.25); */
}
.popover.right>.arrow {
    margin-left: -12px;
    margin-left: -10px;
    margin-top: -10px;
    border-left-width: 0;
    border-right-color: #09ad7e;

}

.dropdown-menu>li>a{
    display: block;
    white-space: nowrap;
}

.dropdown-menu{
    min-width:160px ;
    position: absolute !important;
}

.list-group-item.active{
    border-color: rgba(128, 128, 128, 0.192) !important;
    color: #1EBF8A !important;
}

.popover-content{
    padding : 9px 14px;
}

.popover{
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif !important;
}
.popover>.arrow:after {
    content: "";
    border-width: 10px;
}

.popover>.arrow, .popover>.arrow:after {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
}

.popover.right>.arrow:after  {
   border-right-color: #2c323a;
   top: -10px;
   margin-left: -9px;
}

.popover>.arrow {
    border-width: 11px;
}

.popover.bottom>.arrow:after {
    top: 1px;
    margin-left: -10px;
    content: " ";
    border-top-width: 0;
    /* border-bottom-color: #fff; */
}


.dropdown-menu .divider {
    height: 1px;
    margin: 9px 0;
    overflow: hidden;
    background-color: #e5e5e5;
}

.checkbox-inline, .radio-inline {
    position: relative;
    display: inline-block;
    /* padding-left: 20px; */
    margin-bottom: 0;
    font-weight: 400;
    vertical-align: middle;
    cursor: pointer;
}

.row-margin{
    margin-left: -15px !important;
    margin-right: -15px !important;
}

.panel-group .panel+.panel {
    margin-top: 5px;
}

.pull_right>.dropdown-menu {
    right: 0;
    left:auto !important;
}

.black-text{
    color: #495057 !important;
}

.dropdown-divider{
    border-top: 1px solid #636363  !important;
}

.popover.right{
    margin-left: 10px;
}

.flex-space-evenly{
    display: flex;
    justify-content: space-evenly;
}

.flex-space-between{
    display: flex;
    justify-content: space-between;
}

.flex-space-start{
    display: flex;
    justify-content: flex-start;
}

.display-flex-center{
    display: flex;
    justify-content: center;
}