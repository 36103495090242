.mobile-app{
    background-color: white;
    height: 100%;
}

.logo-mobile{
    display: block;
    margin: auto;
    width: 40%;
}

.mobile-main-container {
    padding: 16px;
    display: flex;
    flex-direction: column;
}

.mobile_login_formcontrol{
    margin:15px 0px;
    width: 100%;
    height: 38px;
    border:5px #ccc;
    box-shadow: 0px;
    border-radius: 0px; 
    text-indent: 32px;
    color: #495057;
}

.mobile_login_formcontrol:active ,.mobile_login_formcontrol:focus{
    box-shadow:  1px 1px 1px 1px #ccc;
    color: #495057;
} 

.mobile-app .otp-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mobile-app .mobile-btn,
.mobile-app .mobile-input{
    width: 50%;
}

.mobile-app .small{
    width: auto!important;
}

.mobile-app .login_icon{
    top:20px!important;
}